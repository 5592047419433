import { AddButtons, EditButtons, FormWrapper } from "../../../../components/form";
import { Steps, Icon, Row, Col, Divider, Card, Form, Input, Button } from "antd";
import React, { useContext, useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "react-apollo";
import logo from "../../../../assets/img/unitifi.svg";
import { Redirect } from "react-router-dom";

const FormItem = Form.Item;

function SignupForm(props) {
  const { setClientData, state, loading } = props;
  const { getFieldError, getFieldProps, getFieldDecorator } = props.form;
  const emailText = new URLSearchParams(props.location.search).get("email");

  const [checkExistingClient, { loading: checkLoading, error, data }] = useLazyQuery(
    gql`
      query($email: String!, $practiceId: String) {
        checkExistingClient(email: $email, practiceId: $practiceId)
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.checkExistingClient) {
          props.setActivePage("fullSignup");
        } else {
          alert("Account already exist");
          // props.setState("");
        }
      },
    }
  );
  const [checkClientHandler, { loading: clientLoading, error: clientError, data: clientData }] = useLazyQuery(
    gql`
      query($email: String!, $practiceId: String) {
        getClientDetail(email: $email, practiceId: $practiceId) {
          firstName
          lastName
          email
          id
          address
          city
          zipCode
          state
          country
          phoneNumber
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  useEffect(() => {
    if (props.practiceId) checkClientHandler({ variables: { email: emailText, practiceId: props.practiceId } });
  }, [props]);

  useEffect(() => {
    if (!clientData) return;
    setClientData(clientData.getClientDetail);
  }, [clientData]);

  function handleSubmit(event) {
    event.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        const { email } = values;
        props.setState({ ...state, email: email });
        checkExistingClient({
          variables: {
            email,
            practiceId: props.practiceId,
          },
        });
      }
    });
  }
  //console.log('props=>>',props);
  return (
    <Row>
      <Col span={24}>
        <div className={"logo-signup"}>
          {!loading && state && state.client && (state.client.companylogo ? <img src={state.client.companylogo} style={{ width: "100px", height: "100px", objectFit: "contain" }} /> : <img src={logo} />)}
          {/* <img src={logo} /> */}
        </div>
      </Col>
      <Col span={24} style={{ marginTop: 30 }}>
        <FormWrapper>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <FormItem label="Email" style={{ marginBottom: 0 }}>
              {getFieldDecorator("email", {
                initialValue: emailText,
                rules: [
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                  {
                    required: true,
                    message: "Please enter an email",
                  },
                ],
              })(<Input type={"email"} placeholder="Enter your email" size={"large"} />)}
            </FormItem>
            <Button type="primary" className="login-form-button" size={"large"} style={{ width: "100%" }} onClick={handleSubmit}>
              Continue
            </Button>
          </Form>
        </FormWrapper>
        <Divider />
      </Col>
      <Col span={24}>
        <div className={"logo-signup"}>
          <Button type="link" href={"https://unitifi.com/"} block>
            Learn more about Unitifi
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default Form.create()(SignupForm);
