import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { AutoComplete, Badge, Form, Input, Select as Selectd, Row, Col } from "antd";
import { AddButtons, EditButtons, FormWrapper } from "../../../components/form";
import StateList from "../../../helpers/statesDataList";
import { useQuery, graphql } from "react-apollo";
import _, { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Select from "react-select";
import { AccountContext } from "../../../context/AccountContext";
import PromptModal from "../../../components/promptModal";
import { phoneMsg, phoneNumberCheck } from "../../../helpers/formatters";

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Selectd;
const AutoCompleteOption = AutoComplete.Option;

// export default Form.create()(AdvisorsForm);

function AdvisorsForm(props) {
  const accountContext = useContext(AccountContext);
  const { account } = accountContext;
  const {
    cityListQuery,
    stateListQuery,
    loading,
    data: { getCountrylist },
    me,
  } = props;

  const { getFieldError, getFieldProps, getFieldDecorator, setFields } = props.form;
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [practiceList, setPracticeList] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("+1");

  let portalId = _.get(accountContext, "profile.portals[0].id", null);

  const { error: countryError, data: countryData } = useQuery(
    gql`
      query {
        getCountry {
          countries {
            id
            name
            dial_code
          }
        }
      }
    `
  );
  const { data: practicedata } = useQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $search: String, $portalId: Int, $practiceId: Int, $roleCode: String, $accountType: Int) {
        getAdminPractices(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, search: $search, portalId: $portalId, practiceId: $practiceId, roleCode: $roleCode, accountType: $accountType) {
          practices {
            id
            name
            practiceId
            portalId
            company
            cslug
            primaryContactAddress
            status
            primaryContactEmail
          }
          pagination {
            offset
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        page: 1,
        offset: 0,
        limit: 20,
        order: "ASC",
        orderColumn: "",
        search: "",
        portalId: portalId,
        practiceId: me.practiceid,
        accountType: me.accountType,
        roleCode: me.rolecode,
      },
      fetchPolicy: "network-only",
    }
  );

  const { error, data, refetch } = useQuery(
    gql`
      query($limit: Int, $offset: Int, $slug: String, $search: String) {
        getPracticeSupport(limit: $limit, offset: $offset, slug: $slug, search: $search) {
          users {
            id
            city
            email
            firstName
            lastName
            phoneNumber
            practice
            role
            state
            status
          }
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        limit: 20,
        slug: props.currentPractice,
        search: "",
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (!getCountrylist) return;
    if (!getCountrylist.countrylist) return;
    const country = getCountrylist.countrylist.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
    setCountryList(country);
  }, [getCountrylist]);
  //start for practices
  useEffect(() => {
    const practice = _.get(practicedata, "getAdminPractices.practices", []).map((practice) => {
      return {
        label: practice.name,
        value: practice.id,
      };
    });
    setPracticeList(practice);
  }, [practicedata]);
  //end for practices

  useEffect(() => {
    if (practiceList.length == 0) return;
    let result = practiceList.filter((data) => data.label == props.advisor.practiceId);
    if (result.length > 0) {
      result = result[0];
      setFields({
        practice: {
          value: { label: result.label, value: result.value },
        },
      });
    }
  }, [practiceList]);

  useEffect(() => {
    if (!countryData) return;
    let result = countryData.getCountry.countries;

    let countArr = [];
    for (let res of result) {
      if (res.name != "Canada") {
        let obj = {
          label: res.dial_code,
          value: res.dial_code,
        };
        countArr.push(obj);
      }
    }
    setCountries(countArr);
  }, [countryData]);

  useEffect(() => {
    if (countryList.length == 0) return;

    if (props.advisor) {
      let cntry = countryList.filter((data) => data.label == props.advisor.country);

      if (cntry.length > 0) {
        cntry = cntry[0];
        setFields({
          country: {
            value: { label: cntry.label, value: cntry.value },
          },
        });

        stateHandler({ value: cntry.value });
      } else {
        setFields({
          country: {
            value: { label: "United States", value: 233 },
          },
        });
        stateHandler({ value: "233" });
      }
    }
  }, [countryList]);

  useEffect(() => {
    if (stateList.length == 0) return;

    if (props.advisor) {
      let ctry = stateList.filter((data) => data.label == props.advisor.state);
      if (ctry.length > 0) {
        ctry = ctry[0];
        setFields({
          state: {
            value: { label: ctry.label, value: ctry.value },
          },
        });
        cityHandler({ value: ctry.value });
      }
    }
  }, [stateList]);

  useEffect(() => {
    if (cityList.length == 0) return;

    if (props.advisor) {
      let ctry = cityList.filter((data) => data.label == props.advisor.city);
      if (ctry.length > 0) {
        ctry = ctry[0];
        setFields({
          city: {
            value: { label: ctry.label, value: ctry.value },
          },
        });
      }
    }
  }, [cityList]);

  useEffect(() => {
    if (Countries.length == 0) return;
    if (props.advisor && props.advisor.phone_code) setCountryCode(props.advisor.phone_code);
    let ctry = Countries.filter((data) => data.label == props.advisor.phone_code);
    if (ctry.length > 0) {
      ctry = ctry[0];
      setFields({
        phoneNumber: {
          value: { label: ctry.label, value: ctry.value },
        },
      });
    }
  }, [Countries]);

  function handleSubmit(event) {
    event.preventDefault();

    props.form.validateFields((err, values) => {
      if (values.phoneNumber && typeof values.phoneNumber != "object") {
        // if (values.p1 == undefined || values.p2 == undefined || values.p3 == undefined || values.p1.length !== 3 || values.p2.length !== 3 || values.p3.length !== 4) {
        if (values.p1 == undefined || values.p1.length !== 10) {
          props.form.setFields({
            phoneNumber: {
              value: values.phoneNumber,
              errors: [new Error("Please enter valid phone number.")],
            },
          });
          return;
        }
        // let ph = values.p1 + values.p2 + values.p3;
        // if (phoneNumberCheck(ph)) {
        //   props.form.setFields({
        //     phoneNumber: {
        //       value: values.phoneNumber,
        //       errors: [new Error(phoneMsg)],
        //     },
        //   });
        //   return;
        // }
      }

      if (!err) {
        let payload = _.clone(values);
        if (payload.city) payload.city = payload.city.label;
        if (payload.country) payload.country = payload.country.label;
        if (payload.state) payload.state = payload.state.label;
        payload.roleId = 6;
        payload.phone_code = countryCode;
        if (payload.practice) payload.practice = payload.practice.value;

        if (payload.p1 && payload.p2 && payload.p3) {
          payload.phoneNumber = `${payload.p1}${payload.p2}${payload.p3}`;
        } else payload.phoneNumber = payload.p1;

        props.onSubmit(payload);
      }
    });
  }

  const cityHandler = (input) => {
    cityListQuery
      .refetch({ state_id: input.value + "" })
      .then(({ data }) => {
        let state = data.getCities.cities;
        const cityData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        setCityList(cityData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const stateHandler = (input) => {
    setFields({
      state: {
        value: "",
      },
    });
    setFields({
      city: {
        value: "",
      },
    });
    stateListQuery.refetch({ country_id: input.value + "" }).then(({ data }) => {
      let state = data.getStates.states;
      const stateData = state.map((s) => {
        return {
          label: s.name,
          value: s.id,
        };
      });
      setStateList(stateData);
    });
  };

  const phondeCodeHandler = (val) => {
    setCountryCode(val.value);
  };

  const autoFocusPhone = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");

    if (e.key == "Shift" || e.key == "Tab") {
      document.getElementById(c).focus();
      return;
    }
    if (length == maxLength) {
      if (n != "") {
        document.getElementById(n).focus();
      }
    }
  };

  return (
    <FormWrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        {/* <FormItem label="Support">
          {getFieldDecorator("supportId", {
            initialValue: props.advisor ? props.advisor.supports : undefined,
            rules: [
              {
                message: "A user role is required.",
                required: false,
              },
            ],
          })(
            <Select
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              // onChange={roleId => onRoleChange(roleId)}
              mode="multiple"
              optionFilterProp="children"
              placeholder="Support"
              showSearch>
              {supports.map((sup) => (
                <Option key={sup.id} value={sup.id}>{`${sup.firstName} ${sup.lastName}`}</Option>
              ))}
            </Select>
          )}
        </FormItem> */}
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Practice Affiliation">
              {getFieldDecorator("practice", {
                initialValue: props.advisor.practiceId ? props.advisor.practiceId : null,
                //initialValue: 28
              })(<Select options={practiceList} className="unitifi-select-container" classNamePrefix="unitifi-react-select" isDisabled={props.advisor.code == "PRCTO" || props.advisor.code == "CMPYO" ? true : false} />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem className="" label="Advisor First Name">
              {getFieldDecorator("firstName", {
                initialValue: props.advisor ? props.advisor.firstName : null,
                rules: [
                  {
                    message: "An advisor first name is required.",
                    required: true,
                  },
                ],
              })(<Input placeholder="First Name" />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem className="" label="Advisor Last Name">
              {getFieldDecorator("lastName", {
                initialValue: props.advisor ? props.advisor.lastName : null,
                rules: [
                  {
                    message: "An advisor last name is required.",
                    required: true,
                  },
                ],
              })(<Input placeholder="Last Name" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Advisor Email">
              {getFieldDecorator("email", {
                initialValue: props.advisor ? props.advisor.email : null,
                rules: [
                  {
                    message: "An advisor email is required.",
                    required: true,
                  },
                ],
              })(<Input placeholder="Advisor Email" />)}
            </FormItem>
          </Col>
        </Row>

        {/* <Row gutter={16}>
          <Col span={12}>
            {props.advisor ? (
              <FormItem className="" label="Status">
                {getFieldDecorator("status", {
                  initialValue: props.advisor.status,
                  rules: [
                    {
                      message: "An advisor status is required.",
                      required: false,
                    },
                  ],
                })(
                  <Selectd placeholder="Status">
                    <Option value="Active">
                      <Badge status="success" text="Active" />
                    </Option>
                    <Option value="Inactive">
                      <Badge status="error" text="Inactive" />
                    </Option>
                  </Selectd>
                )}
              </FormItem>
            ) : null}
          </Col>
        </Row> */}

        <Row gutter={16}>
          <Col span={12}>
            {props.advisor ? (
              <FormItem className="" label="Send Client Results Snapshot via Email">
                {getFieldDecorator("isClientResultSentEmail", {
                  initialValue: props.advisor.isClientResultSentEmail,
                  rules: [
                    {
                      message: "Send Client Results Snapshot is required.",
                      required: true,
                    },
                  ],
                })(
                  <Selectd placeholder="Send Client Results Snapshot via Email">
                    <Option value={true}>
                      <Badge status="success" text="Yes" />
                    </Option>
                    <Option value={false}>
                      <Badge status="error" text="No" />
                    </Option>
                  </Selectd>
                )}
              </FormItem>
            ) : null}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            {props.advisor ? (
              <FormItem className="" label="Assessment Completed Notification">
                {getFieldDecorator("assessmentNotification", {
                  initialValue: props.advisor.assessmentNotification || false,
                })(
                  <Selectd placeholder="Notification">
                    <Option value={true}>
                      <Badge status="success" text="Yes, send me notification" />
                    </Option>
                    <Option value={false}>
                      <Badge status="error" text="No, don't send me notification" />
                    </Option>
                  </Selectd>
                )}
              </FormItem>
            ) : null}
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} sm={16} xl={12}>
            <FormItem className="input-phone__wrapper" label="Phone Number">
              <InputGroup compact className="unitifi-detail-pages-number-inputbox-wrapper UnitifiCustomerNumberInputbox">
                <Select
                  options={Countries}
                  defaultValue={props.advisor && props.advisor.phone_code ? { label: props.advisor.phone_code, value: props.advisor.phone_code } : { label: "+1", value: "+1" }}
                  onKeyDown={(event) => {
                    if (
                      event.key == "Backspace" ||
                      event.key == "Delete" ||
                      event.key == "ArrowLeft" ||
                      event.key == "ArrowRight" ||
                      event.key == "Meta" ||
                      event.key == "ArrowUp" ||
                      event.key == "ArrowDown" ||
                      event.key == "Enter" ||
                      event.key == "Tab"
                    ) {
                    } else if (!/[0-9+]/.test(event.key)) event.preventDefault();
                  }}
                  onChange={(val) => phondeCodeHandler(val)}
                  className="unitifi-select-container phoneInputBox"
                  classNamePrefix="unitifi-react-select"
                />
                {getFieldDecorator("phoneNumber", {
                  rules: [
                    {
                      required: false,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <div className="input-phone__input">
                    {getFieldDecorator("p1", {
                      initialValue: props.advisor && props.advisor.phoneNumber && !props.advisor.phoneNumber.includes("null") ? props.advisor.phoneNumber : null,

                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                      ],
                    })(
                      <Input
                        size={"large"}
                        maxLength={10}
                        placeholder=""
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        // onKeyUp={(event) => {
                        //   autoFocusPhone(event, "", "p1", "p2");
                        // }}
                      />
                    )}

                    {/* {getFieldDecorator("p2", {
                      initialValue: props.advisor && props.advisor.phoneNumber && !props.advisor.phoneNumber.includes("null") ? props.advisor.phoneNumber.substring(3, 6) : null,

                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                      ],
                    })(
                      <Input
                        size={"large"}
                        maxLength={3}
                        placeholder="000"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onKeyUp={(event) => {
                          autoFocusPhone(event, "p1", "p2", "p3");
                        }}
                      />
                    )} */}

                    {/* {getFieldDecorator("p3", {
                      initialValue: props.advisor && props.advisor.phoneNumber && !props.advisor.phoneNumber.includes("null") ? props.advisor.phoneNumber.substring(6, 10) : null,

                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                      ],
                    })(
                      <Input
                        size={"large"}
                        maxLength={4}
                        placeholder="0000"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onKeyUp={(event) => {
                          autoFocusPhone(event, "p2", "p3", "p3");
                        }}
                      />
                    )} */}
                  </div>
                )}
              </InputGroup>
            </FormItem>
          </Col>
        </Row>
        {/* <FormItem
              className=""
              label="Practice Admin"
            >
              {getFieldDecorator('practiceAdmin', {
                initialValue: props.advisor.roleId === 3? false : true,
                rules: [{
                  message: 'An advisor status is required.',
                  required: true,
                }],
              })(
                <Select
                  placeholder="Status"
                >
                  <Option value={false}>
                    <Badge status="error" text="Advisor" />
                  </Option>
                  <Option value={true}>
                    <Badge status="success" text="Practice Administrator" />
                  </Option>
                </Select>)}
            </FormItem> */}
        <Row gutter={16}>
          <Col span={12}>
            <FormItem className="" label="Address">
              {getFieldDecorator("address", {
                initialValue: props.advisor ? props.advisor.address : null,
              })(<Input placeholder="Address" />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Country">
              {getFieldDecorator("country", {
                //initialValue: this.props.practice ? this.props.practice.primaryContactCountry : "United States",
                // initialValue: null,
                rules: [
                  {
                    message: "Country is required.",
                    required: false,
                  },
                ],
              })(
                <Select
                  options={countryList}
                  onChange={(e) => stateHandler(e)}
                  className="unitifi-select-container"
                  classNamePrefix="unitifi-react-select"
                  filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem className="" label="Postal Code/ZIP Code">
              {getFieldDecorator("zipCode", {
                initialValue: props.advisor ? props.advisor.zipCode : null,
              })(<Input placeholder="Postal Code/ZIP Code" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="State/Province/Region">
              {getFieldDecorator("state", {
                // initialValue: null,
                // initialValue: this.props.practice ? this.props.practice.primaryContactState : null,
                rules: [
                  {
                    message: "State/Province/Region is required.",
                    required: false,
                  },
                ],
              })(
                <Select
                  options={stateList}
                  onChange={(e) => {
                    cityHandler(e);
                    setFields({
                      city: {
                        value: "",
                      },
                    });
                  }}
                  className="unitifi-select-container"
                  classNamePrefix="unitifi-react-select"
                  filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                />
                // <AutoComplete placeholder="Select a State" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                //   {(StateList || []).map((state) => (
                //     <AutoCompleteOption key={state.abbr} value={state.abbr}>
                //       {state.name}
                //     </AutoCompleteOption>
                //   ))}
                // </AutoComplete>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="City/Town">
              {getFieldDecorator("city", {
                // initialValue: this.props.practice ? this.props.practice.primaryContactCity : null,
                // initialValue: null,
                rules: [
                  {
                    message: "City/Town is required.",
                    required: false,
                  },
                ],
              })(<Select options={cityList} className="unitifi-select-container" classNamePrefix="unitifi-react-select" filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0} />)}
            </FormItem>
          </Col>
        </Row>

        {/* <Row gutter={16}>
          <Col span={13}>
            <FormItem className="" label="State">
              {getFieldDecorator("state", {
                initialValue: props.advisor && props.advisor.state ? props.advisor.state : undefined,
              })(
                <AutoComplete placeholder="Select a State" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {(StateList || []).map((state) => (
                    <AutoCompleteOption key={state.abbr} value={state.abbr}>
                      {state.name}
                    </AutoCompleteOption>
                  ))}
                </AutoComplete>
              )}
            </FormItem>
          </Col>
        </Row> */}

        {/* <FormItem className="input-phone__wrapper" label="Phone Number">
          <InputGroup>
            {getFieldDecorator("primaryContactPhoneNumber", {
              initialValue: props.advisor ? props.advisor.phoneNumber : null,
            })(
              <div className="input-phone__input">
                {getFieldDecorator("p1", {
                  initialValue: props.advisor && props.advisor.phoneNumber ? props.advisor.phoneNumber.substring(0, 3) : null,
                })(<Input maxLength={3} placeholder="867" />)}
                <span>&#8211;</span>
                {getFieldDecorator("p2", {
                  initialValue: props.advisor && props.advisor.phoneNumber ? props.advisor.phoneNumber.substring(3, 6) : null,
                })(<Input maxLength={3} placeholder="530" />)}
                <span>&#8211;</span>
                {getFieldDecorator("p3", {
                  initialValue: props.advisor && props.advisor.phoneNumber ? props.advisor.phoneNumber.substring(6, 10) : null,
                })(<Input maxLength={4} placeholder="9999" />)}
              </div>
            )}
          </InputGroup>
        </FormItem> */}

        <div className="form-toolbar">
          {(props.advisor && account && account.permissions.includes("editadviser")) || me.id == props.advisor.id ? (
            <EditButtons deleteRights={"deleteAdviser"} cancelUrl={props.cancelUrl} disabled={loading} loading={loading} onDelete={props.onDelete} onSubmit={handleSubmit} rights={["superAdmin", "editadviser"]} />
          ) : (
            ""
          )
          // <AddButtons cancelUrl={props.cancelUrl} createAnother={props.createAnother} loading={props.loading} onCreateAnother={props.onCreateAnother} onSubmit={handleSubmit} rights={["superAdmin", "createAdviser"]} />
          }
        </div>
      </Form>
      {!props.loading && <PromptModal isBlocked={props.form.isFieldsTouched()} title="Leave Page?" content="You have unsaved changes. Are you sure you want to leave this page?" {...props} submitAction={handleSubmit} loading={props.loading} />}
    </FormWrapper>
  );
}

const countryQuery = gql`
  query {
    getCountrylist {
      countrylist {
        id
        name
        iso2
        iso3
      }
    }
  }
`;

const stateQuety = gql`
  query($country_id: String!) {
    getStates(country_id: $country_id) {
      states {
        id
        name
        state_code
      }
    }
  }
`;

const cityQuery = gql`
  query($state_id: String!) {
    getCities(state_id: $state_id) {
      cities {
        id
        name
      }
    }
  }
`;

export default compose(
  graphql(countryQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
    }),
  }),
  graphql(stateQuety, {
    name: "stateListQuery",
    options: (props) => ({
      variables: {
        country_id: props.country_id,
      },
    }),
  }),
  graphql(cityQuery, {
    name: "cityListQuery",
    options: (props) => ({
      variables: {
        state_id: props.state_id,
      },
    }),
  })
)(Form.create()(AdvisorsForm));

// export default compose(graphql(getPracticeSupport, {
//     options: props => ({
//         fetchPolicy: 'network-only',
//         variables: {
//             limit:20,
//             slug: props.currentPractice,
//             search:""
//         },
//     }),
// }))(Form.create()(AdvisorForm));
