import { Icon, Result, message, notification } from "antd";
import gql from "graphql-tag";
import { Button, Row, Col, Badge } from "antd";
import React, { useEffect, useState } from "react";
import { Titlebar } from "../../components";
import { EditButtons, FormWrapper } from "../../components/form";
import { useQuery } from "react-apollo";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";

import _, { flowRight as compose } from "lodash";
import UserViewWrapper from "../users/view/index.style";
import Info from "../../assets/img/info-icon.svg";
import GenerateBadge from "../../helpers/generateBadge";
import moment from "moment";

const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

const AccountForm = (props) => {
  const { portalData } = props;
  return (
    <FormWrapper className="unitifi-my-account-layout-wrapper">
      <UserViewWrapper>
        <div className="row-wrapper">
          <div className="row-label">Admin Name</div>
          <div className="text-grey">
            {portalData &&
              portalData.getPortal.user &&
              (portalData.getPortal.user ? portalData.getPortal.user.firstName + " " + portalData.getPortal.user.lastName : portalData.getPortal.primaryContactFirstName + " " + portalData.getPortal.primaryContactLastName)}
            {/* {portalData &&
              (portalData.getPortal.primaryContactFirstName ? portalData.getPortal.primaryContactFirstName + " " + portalData.getPortal.primaryContactLastName : portalData.getPortal.user.firstName + " " + portalData.getPortal.user.lastName)} */}
          </div>
        </div>

        <div className="row-wrapper">
          <div className="row-label">Email</div>
          <div className="text-grey">{portalData && portalData.getPortal.user && (portalData.getPortal.user ? portalData.getPortal.user.email : portalData.getPortal.primaryContactEmail)}</div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Address</div>
          <div className="text-grey">{portalData && portalData.getPortal.primaryContactAddress ? `${portalData.getPortal.primaryContactSuite}, ` : ''} 
          {portalData && portalData.getPortal.primaryContactSuite ? `${portalData.getPortal.primaryContactSuite}, ` :'' }
          {portalData && portalData.getPortal.primaryContactCity ? `${portalData.getPortal.primaryContactCity}, ` :'' }
          {portalData && portalData.getPortal.primaryContactState ? `${portalData.getPortal.primaryContactState}, ` :'' }
          {portalData && portalData.getPortal.primaryContactZipCode ? `${portalData.getPortal.primaryContactZipCode}, ` :'' }
          {portalData && portalData.getPortal.primaryContactCountry ? `${portalData.getPortal.primaryContactCountry}` :'' }</div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Status</div>
          <div className="text-grey"> {portalData && <Badge status={GenerateBadge(portalData.getPortal.status)} text={portalData.getPortal.status} />}</div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Receive Assessment Notifications</div>
          <div className="text-grey">{portalData && <Badge status={GenerateBadge(portalData.getPortal.assessmentNotification == true ? "ACTIVE" : "error")} text={portalData.getPortal.assessmentNotification == true ? "Active" : "Inactive"} />}</div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Last Login</div>
          <div className="text-grey">{portalData && portalData.getPortal.user && portalData.getPortal.user.lastLogin ? moment(portalData.getPortal.user.lastLogin).format("MMM DD YYYY HH:mm A") : ""}</div>
        </div>
      </UserViewWrapper>
    </FormWrapper>
  );
};

const CompanyDetail = (props) => {
  const { currentPortalId, history } = props;

  const { loading: countryLoading, error: countryError, data: portalData } = useQuery(
    gql`
      query($id: Int) {
        getPortal(id: $id) {
          id
          name
          email
          slug
          description
          status
          siteLogo
          address
          siteLogoS3Token
          primaryContactAddress
          primaryContactZipCode
          primaryContactCity
          primaryContactCountry
          primaryContactState
          primaryContactSuite
          primaryContactEmail
          primaryContactFirstName
          primaryContactLastName
          total_clients
          total_practice
          team_size
          assessmentNotification
          user {
            id
            firstName
            lastName
            email
            lastLogin
          }
        }
      }
    `,
    { fetchPolicy: "network-only", variables: { id: currentPortalId } }
  );

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <Titlebar displayText="My Account" />
      </div>
      <div className="unitifi-customer-content-wrapper">
        <Row className="unitifi-customer-info-box-wrapper mb-3 mb-lg-0">
          <Col className="gutter-row unitifi-customer-info-col" md={8}>
            <div className="unitifi-customer-info-block">
              <h6>Practices</h6>
              <p className="mb-0">{portalData && portalData.getPortal.total_practice}</p>
            </div>
          </Col>
          <Col className="gutter-row unitifi-customer-info-col" md={8}>
            <div className="unitifi-customer-info-block">
              <h6>Team Size</h6>
              <p className="mb-0">{portalData && portalData.getPortal.team_size}</p>
            </div>
          </Col>
          <Col className="gutter-row unitifi-customer-info-col" md={8}>
            <div className="unitifi-customer-info-block">
              <h6>Clients</h6>
              <p className="mb-0">{portalData && portalData.getPortal.total_clients}</p>
            </div>
          </Col>
        </Row>
        <div className="d-flex flex-column ms-2">
          <Button className="btnDelete" icon="edit" onClick={() => history.push("/company/edit")}>
            Edit Account
          </Button>
        </div>
      </div>

      <LayoutContentWrapper>
        <LayoutContent>
          <AccountForm {...props} portalData={portalData} />
        </LayoutContent>
      </LayoutContentWrapper>
    </div>
  );
  // return
};

export default CompanyDetail;
