import styled from 'styled-components';

const TitlebarWrapper = styled.div`
  .title-bar-wrapper {
    background: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(238,242,244,1);
    padding: 16px 24px 16px 24px;
    transition: all .2s;
    @media only screen and (max-width: 767px) {
      padding: 16px 12px 16px 12px;
    }
    .title-wrapper {
      &.for-print {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .sub-title-text {
        color: #181818;
        font-size: 12px;
        .capitalize {
          text-transform: capitalize;
        }
      }
      .title-text {
        color: #181818;
        font-size: 20px;
        font-weight: 600
      }
    }
  }
`;

export default TitlebarWrapper;
