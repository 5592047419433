import { AddButtons, EditButtons, FormWrapper } from "../../../../components/form";
import StateList from "../../../../helpers/statesDataList";
import { Divider, Row, Col, AutoComplete, Select as Selectd, Form, Input, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useLazyQuery, useQuery } from "react-apollo";
import gql from "graphql-tag";
import { notification } from "antd";
import Select from "react-select";
import { phoneMsg, phoneNumberCheck } from "../../../../helpers/formatters";
const FormItem = Form.Item;
const InputGroup = Input.Group;
const AutoCompleteOption = AutoComplete.Option;

function FullSignupForm(props) {
  const { state, form, setState, pages, practiceId, userId, clientData } = props;
  const { getFieldDecorator, setFields } = form;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("+1");

  const { error: countrysError, data: countryData } = useQuery(
    gql`
      query {
        getCountry {
          countries {
            id
            name
            dial_code
          }
        }
      }
    `
  );

  const { error: countryError, data: getCountrylist } = useQuery(
    gql`
      query {
        getCountrylist {
          countrylist {
            id
            name
            iso2
            iso3
          }
        }
      }
    `
  );

  const [stateHandlerQuery, { loading: stateLoading, data: stateData }] = useLazyQuery(
    gql`
      query($country_id: String!) {
        getStates(country_id: $country_id) {
          states {
            id
            name
            state_code
          }
        }
      }
    `,
    {
      variables: {},
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        let state = data.getStates.states;
        const stateData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        setStateList(stateData);
      },
    }
  );

  const [cityHandlerQuery, { loading: cityLoading, data: cityData }] = useLazyQuery(
    gql`
      query($state_id: String!) {
        getCities(state_id: $state_id) {
          cities {
            id
            name
          }
        }
      }
    `,
    {
      variables: {},
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        let state = data.getCities.cities;
        const cityData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        setCityList(cityData);
      },
    }
  );

  const [addClient, { data, loading: loadingMutate }] = useMutation(
    gql`
      mutation createClient(
        $address: String
        $country: String
        $advisorId: Int
        $city: String
        $email: String!
        $firstName: String
        $lastName: String
        $phoneNumber: String
        $practiceId: String
        $state: String
        $zipCode: String
        $addClientToTeam: Boolean
        $phone_code: String
      ) {
        createClient(
          address: $address
          advisorId: $advisorId
          city: $city
          email: $email
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phoneNumber
          practiceId: $practiceId
          state: $state
          country: $country
          zipCode: $zipCode
          addClientToTeam: $addClientToTeam
          phone_code: $phone_code
        ) {
          ok
          practice
          client {
            id
            firstName
            lastName
            assessmentToken
            clientAssessmentBody
            practiceName
            advisorfirstname
            advisorlastname
            companylogo
          }
          errors {
            message
            path
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.createClient.ok && !data.createClient.errors) {
          const { createClient } = data;
          const { client, assessment, advisorClient, practice } = createClient;
          setState({
            ...state,
            client: {
              ...client,
            },
            practice: { ...practice },
          });

          var thisPage = pages;
          thisPage[0].status = true;
          props.setPages(thisPage);
          props.setActivePage("assessment");
        } else {
          handleError(data.createClient.errors);
        }
      },
    }
  );

  useEffect(() => {
    if (!getCountrylist) return;
    if (!getCountrylist.getCountrylist) return;
    const country = getCountrylist.getCountrylist.countrylist.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });

    setCountryList(country);
  }, [getCountrylist]);

  useEffect(() => {
    if (!countryData) return;
    let result = countryData.getCountry.countries;

    let countArr = [];
    for (let res of result) {
      if (res.name != "Canada") {
        let obj = {
          label: res.dial_code,
          value: res.dial_code,
        };
        countArr.push(obj);
      }
    }
    setCountries(countArr);
  }, [countryData]);

  const handleError = (errors) => {
    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  };

  const cityHandler = (input) => {
    cityHandlerQuery({ variables: { state_id: input.value + "" } });
  };

  const stateHandler = (input) => {
    setFields({
      state: {
        value: "",
      },
    });
    setFields({
      city: {
        value: "",
      },
    });
    stateHandlerQuery({ variables: { country_id: input.value + "" } });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.form.validateFields((err, values) => {
      if (values.phoneNumber && typeof values.phoneNumber != "object") {
        if (values.p1 == undefined || values.p1.length !== 10) {
          props.form.setFields({
            phoneNumber: {
              value: values.phoneNumber,
              errors: [new Error("Please enter valid phone number.")],
            },
          });
          return;
        }

        // let ph = values.p1 + values.p2 + values.p3;
        // if (phoneNumberCheck(ph)) {
        //   props.form.setFields({
        //     phoneNumber: {
        //       value: values.phoneNumber,
        //       errors: [new Error(phoneMsg)],
        //     },
        //   });
        //   return;
        // }
      }

      if (!err) {
        let variables = {
          firstName: values.firstName,
          lastName: values.lastName,
          practiceId,
          addClientToTeam: false,
          advisorId: parseInt(userId),
          email: state.email,
        };

        if (values.p1) {
          variables.phoneNumber = `${values.p1}`;
          variables.phone_code = countryCode;
        }
        variables.city = values.city ? values.city.label : "";
        variables.state = values.state ? values.state.label : "";
        variables.country = values.country ? values.country.label : "";

        if (values.address) variables.address = values.address;
        // if (values.state) variables.state = values.state;
        // if (values.city) variables.city = values.city;
        if (values.zipCode) variables.zipCode = values.zipCode;

        addClient({
          variables,
        });
        // props.onSubmit(values);
      }
    });
  };

  const back = () => {
    props.setActivePage("signup");
    props.setState({ ...state, email: "" });
  };

  const autoFocusPhone = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");

    if (e.key == "Shift" || e.key == "Tab") {
      document.getElementById(c).focus();
      return;
    }
    if (length == maxLength) {
      if (n != "") {
        document.getElementById(n).focus();
      }
    }
  };
  const phondeCodeHandler = (val) => {
    setCountryCode(val.value);
  };

  return (
    <Row>
      <Col span={24}>
        <FormWrapper className="">
          <Form layout="vertical">
            <Row>
              <Col span={24}>
                <FormItem label="First Name" style={{ marginBottom: 0 }}>
                  {getFieldDecorator("firstName", {
                    initialValue: clientData ? clientData.firstName : undefined,
                    rules: [
                      {
                        message: "First Name is required.",
                        required: true,
                      },
                    ],
                  })(<Input size={"large"} placeholder="First Name" />)}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="Last Name" style={{ marginBottom: 0 }}>
                  {getFieldDecorator("lastName", {
                    initialValue: clientData ? clientData.lastName : undefined,
                    rules: [
                      {
                        message: "Last Name is required.",
                        required: true,
                      },
                    ],
                  })(<Input size={"large"} placeholder="Last Name" />)}
                </FormItem>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <FormItem label="Address" style={{ marginBottom: 0 }}>
                  {getFieldDecorator("address", {
                    initialValue: clientData ? clientData.address : undefined,
                    rules: [
                      {
                        message: "Address is required.",
                        required: false,
                      },
                    ],
                  })(<Input size={"large"} placeholder="Address" />)}
                </FormItem>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }} gutter={16}>
              <Col span={12}>
                <FormItem label="Country" style={{ marginBottom: 0 }}>
                  {getFieldDecorator("country", {
                    rules: [
                      {
                        message: "Country is required.",
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      options={countryList}
                      onChange={(e) => stateHandler(e)}
                      className="unitifi-select-container cmnBoxWrapper"
                      classNamePrefix="unitifi-react-select"
                      filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                    />
                  )}
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="State/Province/Region" style={{ marginBottom: 0 }}>
                  {getFieldDecorator("state", {
                    rules: [
                      {
                        message: "State/Province/Region is required.",
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      options={stateList}
                      onChange={(e) => {
                        console.log("re", e);
                        cityHandler(e);
                        setFields({
                          city: {
                            value: "",
                          },
                        });
                      }}
                      // onChange={(e) => cityHandler(e)}
                      className="unitifi-select-container cmnBoxWrapper"
                      classNamePrefix="unitifi-react-select"
                      filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }} gutter={16}>
              <Col span={12}>
                <FormItem label="City/Town" style={{ marginBottom: 0 }}>
                  {getFieldDecorator("city", {
                    rules: [
                      {
                        message: "City/Town is required.",
                        required: false,
                      },
                    ],
                  })(<Select options={cityList} className="unitifi-select-container cmnBoxWrapper" classNamePrefix="unitifi-react-select" filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0} />)}
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label="Postal Code/ZIP Code" style={{ marginBottom: 0 }} style={{ marginBottom: 0 }}>
                  {getFieldDecorator("zipCode", {
                    initialValue: clientData ? clientData.zipCode : undefined,
                    rules: [
                      {
                        message: "Postal Code/ZIP Code is required.",
                        required: false,
                      },
                    ],
                  })(<Input size={"large"} placeholder="Postal Code/ZIP Code" className="inputZip" />)}
                </FormItem>
              </Col>
            </Row>

            {/* <div className="flexwrapper-row-main" style={{ marginTop: 10 }}>
              <div className="flexwrapper">
                <FormItem className="input-phone__wrapper" label="Phone Number" style={{ marginBottom: 0 }}>
                  <InputGroup>
                    {getFieldDecorator("phoneNumber", {
                      initialValue: clientData ? clientData.phoneNumber : null,
                    })(
                      <div className="input-phone__input">
                        {getFieldDecorator("p1", {
                          initialValue: clientData && clientData.phoneNumber ? clientData.phoneNumber.substring(0, 3) : null,
                        })(
                          <Input
                            size={"large"}
                            maxLength={3}
                            placeholder="867"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onKeyUp={(event) => {
                              autoFocusPhone(event, "", "p1", "p2");
                            }}
                          />
                        )}
                        <span>&#8211;</span>
                        {getFieldDecorator("p2", {
                          initialValue: clientData && clientData.phoneNumber ? clientData.phoneNumber.substring(3, 6) : null,
                        })(
                          <Input
                            size={"large"}
                            maxLength={3}
                            placeholder="530"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onKeyUp={(event) => {
                              autoFocusPhone(event, "p1", "p2", "p3");
                            }}
                          />
                        )}
                        <span>&#8211;</span>
                        {getFieldDecorator("p3", {
                          initialValue: clientData && clientData.phoneNumber ? clientData.phoneNumber.substring(6, 10) : null,
                        })(
                          <Input
                            size={"large"}
                            maxLength={4}
                            placeholder="9999"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onKeyUp={(event) => {
                              autoFocusPhone(event, "p2", "p3", "p3");
                            }}
                          />
                        )}
                      </div>
                    )}
                  </InputGroup>
                </FormItem>
              </div>
              <div className="flexwrapper"></div>
            </div> */}

            <Row style={{ marginTop: 10 }} gutter={16}>
              <Col span={24} sm={12}>
                <FormItem className="input-phone__wrapper" label="Phone Number" style={{ marginBottom: 0 }}>
                  <InputGroup compact className="unitifi-detail-pages-number-inputbox-wrapper countryCode UnitifiCustomerNumberInputbox">
                    <Select
                      options={Countries}
                      defaultValue={props.client && props.client.phone_code ? { label: props.client.phone_code, value: props.client.phone_code } : { label: "+1", value: "+1" }}
                      onKeyDown={(event) => {
                        if (
                          event.key == "Backspace" ||
                          event.key == "Delete" ||
                          event.key == "ArrowLeft" ||
                          event.key == "ArrowRight" ||
                          event.key == "Meta" ||
                          event.key == "ArrowUp" ||
                          event.key == "ArrowDown" ||
                          event.key == "Enter" ||
                          event.key == "Tab"
                        ) {
                        } else if (!/[0-9+]/.test(event.key)) event.preventDefault();
                      }}
                      onChange={(val) => phondeCodeHandler(val)}
                      className="unitifi-select-container phoneInputBox"
                      classNamePrefix="unitifi-react-select"
                    />
                    {getFieldDecorator("phoneNumber", {
                      initialValue: props.client && props.client.phone_code ? { label: props.client.phone_code, value: props.client.phone_code } : { label: "+1", value: "+1" },
                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                      ],
                    })(
                      <div className="input-phone__input">
                        {getFieldDecorator("p1", {
                          initialValue: props.client && props.client.phoneNumber && !props.client.phoneNumber.includes("null") ? props.client.phoneNumber.substring(0, 3) : null,
                          rules: [
                            {
                              required: false,
                              message: "Phone is required",
                            },
                          ],
                        })(
                          <Input
                            className="phoneInput"
                            size={"large"}
                            maxLength={10}
                            placeholder=""
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            // onKeyUp={(event) => {
                            //   autoFocusPhone(event, "", "p1", "p2");
                            // }}
                          />
                        )}
                        {/* <span>&#8211;</span> */}
                        {/* {getFieldDecorator("p2", {
                          initialValue: props.client && props.client.phoneNumber && !props.client.phoneNumber.includes("null") ? props.client.phoneNumber.substring(3, 6) : null,
                          rules: [
                            {
                              required: false,
                              message: "Phone is required",
                            },
                          ],
                        })(
                          <Input
                            className="phoneInput"
                            size={"large"}
                            maxLength={3}
                            placeholder="000"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onKeyUp={(event) => {
                              autoFocusPhone(event, "p1", "p2", "p3");
                            }}
                          />
                        )} */}
                        {/* <span>&#8211;</span> */}
                        {/* {getFieldDecorator("p3", {
                          initialValue: props.client && props.client.phoneNumber && !props.client.phoneNumber.includes("null") ? props.client.phoneNumber.substring(6, 10) : null,
                          rules: [
                            {
                              required: false,
                              message: "Phone is required",
                            },
                          ],
                        })(
                          <Input
                            className="phoneInput"
                            size={"large"}
                            maxLength={4}
                            placeholder="0000"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onKeyUp={(event) => {
                              autoFocusPhone(event, "p2", "p3", "p3");
                            }}
                          />
                        )} */}
                      </div>
                    )}
                  </InputGroup>
                </FormItem>
              </Col>
            </Row>

            <Divider />
            <Row style={{ marginTop: 10 }} style={{ marginTop: 20 }}>
              <Button type="default" size={"large"} style={{ marginRight: 10 }} onClick={back} disabled={loadingMutate}>
                Back
              </Button>
              <Button type="primary" size={"large"} onClick={handleSubmit} loading={loadingMutate} disabled={loadingMutate}>
                Proceed
              </Button>
            </Row>
          </Form>
        </FormWrapper>
      </Col>
    </Row>
  );
}

export default Form.create()(FullSignupForm);
