import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ClientsAdd, ClientsEdit, ClientsList, ClientsPrint, ClientsView, ClientComparePrint, ClientCompareView } from "../../../containers/clients";

class ClientRoutes extends React.Component {
  render() {
    const { gblUserId, gblUserIsPracticeAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice, gblUserIsPortalAdmin, currentPortalId, location, me } = this.props;
    if (!currentPortalId && !currentPractice && !location.pathname.includes("/print")) return <Redirect to="/settings/account" strict />;

    return (
      <Switch>
        {/* pratice routes - catch trailing slashes & send to right route */}
        <Redirect exact from="/:practiceId/clients/" to="/:practiceId/clients" strict />
        <Redirect exact from="/:practiceId/clients/add/" to="/:practiceId/clients/add" strict />
        <Redirect exact from="/:practiceId/clients/edit/:id/" to="/:practiceId/clients/edit/:id" strict />
        <Redirect exact from="/:practiceId/clients/print/:id/:type/" to="/:practiceId/clients/print/:id/:type" strict />
        <Redirect exact from="/:practiceId/clients/print-compare/:id/:type/:clientId/" to="/:practiceId/clients/print-compare/:id/:type/:clientId" strict />
        <Redirect exact from="/:practiceId/clients/view/:id/" to="/:practiceId/clients/view/:id" strict />
        <Redirect exact from="/:practiceId/clients/view-compare/:id/:clientId/" to="/:practiceId/clients/view-compare/:id/:clientId" strict />
        {/* practice routes */}
        <Route
          exact
          path="/:practiceId/clients"
          render={(props) => (
            <ClientsList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/clients/add"
          render={(props) => (
            <ClientsAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/clients/edit/:id"
          render={(props) => (
            <ClientsEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/clients/print/:id/:type/:assessmentId?"
          render={(props) => (
            <ClientsPrint
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/clients/print-compare/:id/:type/:clientId"
          render={(props) => (
            <ClientComparePrint
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/clients/view/:id/:assessmentId?"
          render={(props) => (
            <ClientsView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/clients/view-compare/:id/:clientId"
          render={(props) => (
            <ClientCompareView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect exact from="/clients/" to="/clients" strict />
        <Redirect exact from="/clients/add/" to="/clients/add" strict />
        <Redirect exact from="/clients/edit/:id/" to="/clients/edit/:id" strict />
        <Redirect exact from="/clients/view/:id/" to="/clients/view/:id" strict />
        {/* admin routes */}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/clients"
            render={(props) => (
              <ClientsList {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPracticeAdmin={gblUserIsPracticeAdmin} gblUserRole={gblUserRole} currentPortalId={currentPortalId} />
            )}
          />
        ) : null}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/clients/add"
            render={(props) => (
              <ClientsAdd {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPracticeAdmin={gblUserIsPracticeAdmin} gblUserRole={gblUserRole} currentPortalId={currentPortalId} />
            )}
          />
        ) : null}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/clients/edit/:id"
            render={(props) => (
              <ClientsEdit {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} currentPortalId={currentPortalId} gblUserIsPracticeAdmin={gblUserIsPracticeAdmin} gblUserRole={gblUserRole} />
            )}
          />
        ) : null}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/clients/view/:id"
            render={(props) => (
              <ClientsView {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} currentPortalId={currentPortalId} gblUserIsPracticeAdmin={gblUserIsPracticeAdmin} gblUserRole={gblUserRole} />
            )}
          />
        ) : null}
      </Switch>
    );
  }
}

export default ClientRoutes;
