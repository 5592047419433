import { Badge, Button, Icon, Menu, Skeleton, Table, Dropdown, Spin } from "antd";
import gql from "graphql-tag";
import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import _ from "lodash";
import { Titlebar } from "../../components";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import GenerateBadge from "../../helpers/generateBadge";
import { Link } from "react-router-dom";
import { FormatPercent, FormatPhoneNumber } from "../../helpers/formatters";
import { AccountContext } from "../../context/AccountContext";
import { advisorPermission, practicePermission } from "../../helpers/permissions";
import moment from "moment";
import CustomButton from "../../components/commons/CustomButton";
import TitleBar from "../../components/titlebar/titlebar";

const Search = (props) => {
  const { history, location, currentPortalId, currentPractice, userPracticeList, me } = props;
  const searchText = new URLSearchParams(location.search).get("search");
  const [state, setState] = useState({ limit: 1000, loading: false, order: "ASC", orderColumn: "", page: 1, search: "", totalCount: null, sortDirection: "" });
  const [functionList, setFunctionList] = useState([]);
  const { account } = useContext(AccountContext);

  useEffect(() => {
    if (!searchText) return;
    practiceHandler({
      variables: {
        page: 1,
        offset: 0,
        limit: 1000,
        order: "ASC",
        orderColumn: "",
        search: searchText,
        portalId: currentPortalId,
        practiceId: props.me.practiceid,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
      },
    });
    advisorHandler({
      variables: {
        page: 1,
        offset: 0,
        limit: 1000,
        order: "ASC",
        orderColumn: "",
        search: searchText,
        portalId: currentPortalId,
        practiceId: currentPractice,
        accountType: me.accountType,
        roleCode: me.rolecode,
        portalId: currentPortalId,
      },
    });
    clientHandler({
      variables: {
        page: 1,
        offset: 0,
        limit: 1000,
        order: "ASC",
        orderColumn: "",
        search: searchText,
        practiceId: me.practiceid,
        accountType: me.accountType,
        roleCode: me.rolecode,
        portalId: currentPortalId,
        advisorType: [],
        advisorId: new URLSearchParams(props.location.search).get("assign_id"),
        practiceAssignId: new URLSearchParams(props.location.search).get("practice_id"),
      },
    });
  }, [searchText]);

  const [practiceHandler, { loading: practiceLoading, data: practiceData, refetch }] = useLazyQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $search: String, $portalId: Int, $practiceId: Int, $roleCode: String, $accountType: Int) {
        getAdminPractices(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, search: $search, portalId: $portalId, practiceId: $practiceId, roleCode: $roleCode, accountType: $accountType) {
          practices {
            id
            name
            practiceId
            portalId
            company
            cslug
            primaryContactAddress
            status
            primaryContactEmail
          }
          pagination {
            offset
            totalCount
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [advisorHandler, { loading: advisorLoading, data: advisorData }] = useLazyQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $practiceId: String, $search: String, $roleId: Int, $roleCode: String, $accountType: Int, $portalId: Int) {
        getAllAdvisors(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, practiceId: $practiceId, search: $search, roleId: $roleId, roleCode: $roleCode, accountType: $accountType, portalId: $portalId) {
          advisors {
            id
            city
            email
            firstName
            lastName
            phoneNumber
            practiceName
            state
            status
            roleId
            assessmentStats {
              completionRate
              completedAssessments
              uncompletedAssessments
              energizerCount
              energizerPercent
              liberatorCount
              liberatorPercent
              observerCount
              observerPercent
              protectorCount
              protectorPercent
              totalClients
              logicalCount
              passionateCount
              balancedCount
              logicalPercent
              passionatePercent
              balancedPercent
            }
          }
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [clientHandler, { loading: clientLoading, data: clientData }] = useLazyQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $practiceId: Int, $search: String, $portalId: Int, $roleCode: String, $accountType: Int, $advisorId: String, $practiceAssignId: String, $advisorType: [Int]) {
        getAllClients(
          limit: $limit
          offset: $offset
          order: $order
          orderColumn: $orderColumn
          practiceId: $practiceId
          search: $search
          portalId: $portalId
          roleCode: $roleCode
          accountType: $accountType
          advisorId: $advisorId
          advisorType: $advisorType
          practiceAssignId: $practiceAssignId
        ) {
          clients {
            id
            city
            email
            firstName
            lastName
            phoneNumber
            practiceName
            dateCollected
            allInsights
            state
            adv_name
          }
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const columnsPractice = [
    {
      dataIndex: "name",
      sorter: false,
      title: "Practice Name",
      render: (text, row) => {
        return <>{text}</>;
      },
    },
    {
      dataIndex: "primaryContactAddress",
      title: "Location",
      sorter: false,
    },
    {
      dataIndex: "status",
      render: (text) => <Badge status={GenerateBadge(text)} text={text} />,
      sortOrder: state.status,
      title: "Status",
      sorter: false,
    },
    {
      dataIndex: "company",
      title: "Company",
      sorter: false,

      render: (text, row) => {
        return <>{text}</>;
      },
    },
    {
      dataIndex: "primaryContactEmail",
      title: "Admin Email",
      sorter: false,

      render: (text, row) => {
        return <>{text}</>;
      },
    },
  ];
  let practices = _.get(practiceData, "getAdminPractices.practices", []);

  const columnsAdvisor = [
    {
      dataIndex: "firstName",
      render: (text, advisor) => (
        <Link className={advisor.status === "Inactive" ? "inactive" : null} to={currentPractice ? `/${currentPractice}/advisors/view/${advisor.id}` : `/advisors/view/${advisor.id}`}>
          {advisor.firstName} {advisor.lastName}
        </Link>
      ),
      fixed: "left",
      sorter: false,
      title: "Advisor",
      width: 200,
    },
    {
      align: "center",
      dataIndex: "assessmentStats.totalClients",
      sorter: false,
      title: "# of Clients",
    },
    {
      title: "Assessment Overview",
      children: [
        {
          align: "center",
          dataIndex: "assessmentStats.completedAssessments",
          sorter: false,
          title: "Completed",
        },
        {
          align: "center",
          dataIndex: "assessmentStats.uncompletedAssessments",
          sorter: false,
          title: "Uncompleted",
        },
        {
          align: "center",
          dataIndex: "assessmentStats.completionRate",
          render: (completionRate) => FormatPercent(completionRate),
          sorter: false,
          title: "Completion Rate",
        },
      ],
    },
  ];
  let advisors = _.get(advisorData, "getAllAdvisors.advisors", []);

  let clients = _.get(clientData, "getAllClients.clients", []);

  const columnsClient = [
    {
      dataIndex: "firstName",
      render: (text, client) => (
        <Link className={client.status === "Inactive" ? "inactive" : null} to={`/${client.practiceName}/clients/view/${client.id}`}>
          {client.firstName} {client.lastName}
        </Link>
      ),
      sorter: false,
      title: "Client",
    },
    {
      dataIndex: "adv_name",
      title: "Advisor",
      sorter: false,
    },
    {
      dataIndex: "dateCollected",
      render: (text, row) => {
        if (!row.dateCollected) return "n/a";
        return moment
          .utc(row.dateCollected)
          .local()
          .format("MM/DD/YYYY");
      },
      sorter: false,
      title: "Last Assessment Date",
    },
    {
      dataIndex: "email",
      sorter: false,
      title: "Email",
    },
    {
      dataIndex: "phoneNumber",
      render: (text) => FormatPhoneNumber(text),
      sorter: false,
      title: "Phone",
    },
    {
      dataindex: "allInsights",
      sorter: false,
      render: (text, row) => {
        const allInsights = row.allInsights;
        const dropdownMenuItems = allInsights.map((insight) => {
          return (
            <Menu.Item key={`'${insight.id}'`}>
              <a rel="noreferrer" href={`/${row.practiceName}/clients/view/${row.id}/${insight.id}`}>
                {moment
                  .utc(insight.createdAt)
                  .local()
                  .format("MM-DD-YYYY")}
              </a>
            </Menu.Item>
          );
        });
        const dropdownMenu = <Menu>{[...dropdownMenuItems]}</Menu>;

        return (
          <Dropdown overlay={dropdownMenu} disabled={allInsights.length < 1}>
            <Button type="primary" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              View Assessments ({allInsights.length})<Icon type="down" />
            </Button>
          </Dropdown>
        );
      },
      title: "Assessment Archive",
    },
  ];

  useEffect(() => {
    if (practiceLoading == false && advisorLoading == false && clientLoading == false) {
      let handlerArr = [];
      if (advisors.length == 0 && practices.length == 0 && clients.length == 0) {
        handlerArr = ["practiceListHandler", "advisorListHandler", "clientListHandler"];
        return;
      }
      handlerArr = ["clientListHandler"];
      if (advisors.length > 0) handlerArr = ["advisorListHandler", ...handlerArr];
      else handlerArr = [...handlerArr, "advisorListHandler"];

      if (practices.length > 0) handlerArr = ["practiceListHandler", ...handlerArr];
      else handlerArr = [...handlerArr, "practiceListHandler"];

      setFunctionList(handlerArr);
    }
  }, [practiceLoading, advisorLoading, clientLoading]);

  const clientListHandler = () => {
    return (
      account &&
      account.permissions.length > 0 &&
      advisorPermission.some((p) => account.permissions.includes(p)) && (
        <div className="unirifi-costomer-layout-wrapper mb-4">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar displayText="Clients" />
          </div>
          <LayoutContentWrapper>
            <LayoutContent>
              <Skeleton loading={clientLoading} active avatar>
                <div className="unitify-table-advisors-wrapper">
                  {clientLoading ? null : <Table columns={columnsClient} loading={clientLoading} dataSource={clients} rowKey={(record) => record.id} scroll={{ x: columnsClient.length * 150 }} pagination={false} />}
                </div>
              </Skeleton>
            </LayoutContent>
          </LayoutContentWrapper>
        </div>
      )
    );
  };

  const practiceListHandler = () => {
    return (
      account &&
      account.permissions.length > 0 &&
      practicePermission.some((p) => account.permissions.includes(p)) && (
        <div className="unirifi-costomer-layout-wrapper mb-4">
          <div className="unitifi-subheader-bar-wrapper">
            <TitleBar title="Practices" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />
          </div>
          <LayoutContentWrapper>
            <LayoutContent>
              <Skeleton loading={practiceLoading} active avatar>
                {practiceLoading ? null : <Table columns={columnsPractice} loading={practiceLoading} dataSource={practices} rowKey={(record) => record.id} scroll={{ x: columnsPractice.length * 150 }} pagination={false} />}
              </Skeleton>
            </LayoutContent>
          </LayoutContentWrapper>
        </div>
      )
    );
  };

  const advisorListHandler = () => {
    return (
      account &&
      account.permissions.length > 0 &&
      advisorPermission.some((p) => account.permissions.includes(p)) && (
        <div className="unirifi-costomer-layout-wrapper mb-4">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar displayText="Advisors" />
          </div>
          <LayoutContentWrapper>
            {/* <div className="display-bar">{display}</div> */}

            <LayoutContent>
              <Skeleton loading={advisorLoading} active avatar>
                <div className="unitify-table-advisors-wrapper">
                  {advisorLoading ? null : <Table columns={columnsAdvisor} loading={advisorLoading} dataSource={advisors} rowKey={(record) => record.id} scroll={{ x: columnsAdvisor.length * 150 }} pagination={false} />}
                </div>
              </Skeleton>
            </LayoutContent>
          </LayoutContentWrapper>
        </div>
      )
    );
  };
  const functionSetter = {
    clientListHandler: clientListHandler,
    practiceListHandler: practiceListHandler,
    advisorListHandler: advisorListHandler,
  };

  if (practiceLoading || advisorLoading || clientLoading) {
    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper"></div>
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      </div>
    );
  }

  return <>{functionList.length > 0 && functionList.map((data) => functionSetter[data]())}</>;
};

export default Search;
