import { Button, Spin, Icon, Modal, notification } from "antd";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useQuery, useApolloClient } from "react-apollo";
import { Link } from "react-router-dom";
import _ from "lodash";
import CustomButton from "../../../components/commons/CustomButton";
import { practiceConfig } from "../../../config";
import { Table } from "../../../elements";
import { FormatPercent } from "../../../helpers/formatters";
const { confirm } = Modal;
function BehaviorList(props) {
  const { permissions, history, currentPractice, location, me, currentPortalId, behaviorMsgHandler } = props;
  const searchText = new URLSearchParams(location.search).get("search");
  const client = useApolloClient();
  const [state, setState] = useState({ limit: practiceConfig.rowsPerPage, loading: false, order: "ASC", orderColumn: "", page: 1, search: "", totalCount: null, sortDirection: "" });

  // useEffect(reCall
  //   , [state.page]);

  useEffect(() => {
    setState({ ...state, page: 1, offset: 0 });
  }, [searchText]);

  useEffect(() => {
    reCall();
  }, [state.page]);

  const { loading, data, refetch } = useQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $practiceId: String, $search: String, $roleId: Int, $portalId: Int, $roleCode: String, $accountType: Int) {
        getAllAdvisors(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, practiceId: $practiceId, search: $search, roleId: $roleId, portalId: $portalId, roleCode: $roleCode, accountType: $accountType) {
          advisors {
            id
            city
            email
            firstName
            lastName
            phoneNumber
            practiceName
            state
            status
            roleId
            code
            name
            assessmentStats {
              completionRate
              completedAssessments
              uncompletedAssessments
              energizerCount
              energizerPercent
              liberatorCount
              liberatorPercent
              observerCount
              observerPercent
              protectorCount
              protectorPercent
              totalClients
              logicalCount
              passionateCount
              balancedCount
              logicalPercent
              passionatePercent
              balancedPercent
            }
          }
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: searchText,
        practiceId: currentPractice !== "advisors" ? currentPractice : null,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
        portalId: props.currentPortalId,
      },
      fetchPolicy: "network-only",
    }
  );

  function reCall() {
    refetch({
      page: state.page,
      offset: state.offset,
      limit: state.limit,
      order: state.order,
      orderColumn: state.orderColumn,
      search: searchText,
      accountType: props.me.accountType,
      roleCode: props.me.rolecode,
      portalId: props.currentPortalId,
    });
  }

  function handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? `DESC` : `ASC`;
    if (sorter.field != state.orderColumn || sorterOrder != state.order) pagination.current = 1;

    let x = (pagination.current - 1) * state.limit;
    setState({
      ...state,
      offset: x,
      limit: pagination.pageSize,
      page: pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
      [sorter.columnKey]: sorter.order,
    });
    reCall();
  }

  // Are you sure you want to delete this team member?”  Then in a separate paragraph below “If you proceed, all clients assigned to ____ ____  *member name* will be assigned to ____ ______ *admin name* (the corresponding Practice Admin) until being assigned to a new Advisor
  const deleteHandler = (id, practiceOwnerId, practiceOwnerName, memberName) => {
    confirm({
      title: "Are you sure you want to delete this team member?",
      content: `If you proceed, all clients assigned to ${memberName} will be assigned to ${practiceOwnerName}  (the corresponding Practice Admin) until being assigned to a new Advisor`,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      icon: "close-circle",

      onOk: () => {
        return new Promise(async (resolve, reject) => {
          const data = await deleteHandlerSubmit(id, practiceOwnerId);
          resolve();
        }).catch(() => console.log("error"));
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const deleteHandlerSubmit = async (id, practiceOwnerId) => {
    let result = await client.mutate({
      mutation: gql`
        mutation deleteAdvisor($id: Int!, $practiceOwnerId: Int) {
          deleteAdvisor(id: $id, practiceOwnerId: $practiceOwnerId) {
            ok
            errors {
              message
              path
            }
          }
        }
      `,
      variables: { id: id, practiceOwnerId: practiceOwnerId },
    });

    if (result && result.data.deleteAdvisor.ok) {
      notification.success({
        message: "Advisor Deleted Successfully",
      });
      reCall();
    }
    return true;
  };
  function reCall() {
    refetch({
      page: state.page,
      offset: state.offset,
      limit: state.limit,
      order: state.order,
      orderColumn: state.orderColumn,
      search: searchText,
      accountType: props.me.accountType,
      roleCode: props.me.rolecode,
      portalId: props.currentPortalId,
    });
  }

  const columns = [
    {
      dataIndex: "firstName",
      render: (text, advisor) => (
        <Link className={advisor.status === "Inactive" ? "inactive" : null} to={currentPractice ? `/${currentPractice}/advisors/view/${advisor.id}` : `/advisors/view/${advisor.id}`}>
          {advisor.firstName} {advisor.lastName}
        </Link>
      ),
      //fixed: "left",
      sorter: true,
      title: "Advisor",
      width: 200,
    },
    {
      dataIndex: "name",
      render: (text, advisor) => (
        <p>
          {advisor.code == "PRCTO" ? "Practice Admin" : ""}
          {advisor.code == "PRCTM" ? "Practice Member" : ""}
          {advisor.code == "ADVSM" ? "Advisor Member" : ""}
          {advisor.code == "ADVSO" ? "Advisory Admin" : ""}
          {advisor.code == "CMPYM" ? "Company Member" : ""}
          {advisor.code == "CMPYO" ? "Company Admin" : ""}
        </p>
      ),
      //fixed: "left",
      sorter: true,
      title: "Account Type",
      width: 200,
    },
    {
      title: "Behavior",
      children: [
        {
          align: "center",
          dataIndex: "assessmentStats.protectorCount",
          sorter: true,
          title: "Protector",
          render: (data) => (data ? data : 0),
        },
        {
          align: "center",
          dataIndex: "assessmentStats.observerCount",
          sorter: true,
          title: "Observer",
          render: (data) => (data ? data : 0),
        },
        {
          align: "center",
          dataIndex: "assessmentStats.liberatorCount",
          render: (completionRate) => completionRate,
          sorter: true,
          title: "Liberator",
          render: (data) => (data ? data : 0),
        },
        {
          align: "center",
          dataIndex: "assessmentStats.energizerCount",
          render: (completionRate) => completionRate,
          sorter: true,
          title: "Energizer",
          render: (data) => (data ? data : 0),
        },
      ],
    },
    {
      title: "Decision Making Style",
      children: [
        {
          align: "center",
          dataIndex: "assessmentStats.logicalCount",
          sorter: true,
          title: "Logical",
          render: (data) => (data ? data : 0),
        },
        {
          align: "center",
          dataIndex: "assessmentStats.passionateCount",
          sorter: true,
          title: "Passionate",
          render: (data) => (data ? data : 0),
        },
        {
          align: "center",
          dataIndex: "assessmentStats.balancedCount",
          render: (completionRate) => completionRate,
          sorter: true,
          title: "Balanced",
          render: (data) => (data ? data : 0),
        },
      ],
    },
    // {
    //   align: "center",
    //   dataIndex: "city",
    //   render: (text, row) => (row.city && row.state ? `${row.city}, ${row.state}` : ""),
    //   sorter: true,
    //   title: "Location",
    // },
    {
      align: "center",
      title: "View",
      className: "action-column viewAction",
      render: (advisor) => {
        if (permissions.includes("viewadviser"))
          return (
            <Button
              type={"link"}
              icon={"eye"}
              onClick={() => {
                history.push(`/${currentPractice}/advisors/view/${advisor.id}`);
              }}
            />
          );
      },
    },
    {
      align: "center",
      className: "action-column",
      width: 10,
      title: "Edit",
      //fixed: "right",
      render: (text, advisor) => {
        //  <Link to={`/${currentPractice}/advisors/edit/${advisor.id}`}>
        //   <Icon type="edit" />
        // </Link>
        if (permissions.includes("editadviser") && (advisor.code == "ADVSM" || advisor.code == "PRCTM" || advisor.code == "CMPYM"))
          return (
            <CustomButton
              rights={["superAdmin", "editadviser"]}
              icon="plus"
              // href={"/users/add"}
              onClick={() => {
                history.push(`/${currentPractice}/advisors/edit/${advisor.id}`);
              }}
              icon={"edit"}
              type={"link"}
            />
          );
        // <Button type={'link'} icon={'edit'} onClick={()=>{this.props.history.push(`/${currentPractice}/advisors/edit/${advisor.id}`)}}/>
      },
    },
    {
      align: "center",
      className: "action-column",
      width: 10,
      title: "Delete",
      //fixed: "right",
      render: (text, advisor) => {
        if (permissions.includes("deleteadviser") && (advisor.code == "ADVSM" || advisor.code == "PRCTM" || advisor.code == "CMPYM"))
          return (
            <CustomButton
              rights={["superAdmin", "deleteadviser"]}
              icon="plus"
              // href={"/users/add"}
              onClick={() => deleteHandler(advisor.id, advisor.practiceOwnerId, advisor.practiceOwnerName, advisor.firstName + " " + advisor.lastName)}
              // onClick={() => {
              // history.push(`/${currentPractice}/advisors/delete/${advisor.id}`);
              //}}
              icon={"delete"}
              type={"link"}
            />
          );
      },
    },
  ];

  let behaviorList = _.get(data, "getAllAdvisors.advisors", []);
  let tableCount = _.get(data, "getAllAdvisors.pagination.totalCount", 0);

  let tablePagination = {
    current: state.page,
    defaultPageSize: practiceConfig.rowsPerPage,
    pageSize: state.limit,
    pageSizeOptions: ["10", "20", "50", "100"],
    showQuickJumper: true,
    showSizeChanger: true,
    total: tableCount,
  };

  const countStart = (state.page - 1) * state.limit + 1;
  const countEnd = state.page * state.limit > tableCount ? tableCount : state.page * state.limit;
  const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : "No Records";

  useEffect(() => {
    behaviorMsgHandler(display);
  }, [display]);

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={behaviorList}
      loading={
        loading && {
          indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
        }
      }
      locale={{ emptyText: "No advisors found." }}
      onChange={handleTableChange}
      pagination={tablePagination ? { ...tablePagination } : false}
      rowKey="id"
      // size="middle"Edit Portal
      scroll={{ x: "max-content" }}
    />
  );
}

export default BehaviorList;
