import React, { useContext, useState } from "react";
import { Layout, Menu, Icon, Button } from "antd";
import { Link } from "react-router-dom";
import MenuWrapper from "./menu.style";
import { Form, FormControl, Modal } from "react-bootstrap";
import Search from "../../../assets/img/search-icon.svg";
import { AccountContext } from "../../../context/AccountContext";
import { advisorPermission, clientPermission, companyPermission, practicePermission } from "../../../helpers/permissions";
import { useApolloClient } from "react-apollo";
import { urlPathName } from "../../../helpers/formatters";

const MenuComp = (props) => {
  const { collapsed, openKeys, onOpenChange, selectedKeys, menuItems, optionsItem, type, gblUserRole, location, currentPractice, currentPortalId, history, menuToggle, screenWidth } = props;
  const { account, setAccount, setProfile, setUserPermission, setLogoUpdate } = useContext(AccountContext);
  const [menuModal, setMenuModal] = useState(false);

  const routeHadnler = () => {
    if (!currentPractice && !currentPortalId) setMenuModal(true);
    if (screenWidth < 9000) menuToggle();
  };

  const profileHandler = () => {
    if (screenWidth < 9000) menuToggle();
  };

  function handleUserLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setAccount(null);
    setProfile(null);
    setLogoUpdate(null);
    setUserPermission([]);
    history.push("/login");
  }

  return (
    <MenuWrapper>
      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} selectedKeys={selectedKeys} theme="light" className="topMenu">
        <Menu.Item className={`${urlPathName().length > 0 && urlPathName()[1] == "news-center" ? "ant-menu-item-selected" : ""}`}>
          <Button>
            <Link to={"/news-center"} className="d-flex align-items-center">
              <i className="overViewIcon">
                <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    d="M8.55305 1.00742C8.26397 0.747687 7.88906 0.604004 7.50043 0.604004C7.1118 0.604004 6.73689 0.747687 6.4478 1.00742L0.672802 6.19652C0.508415 6.34408 0.376907 6.52458 0.286821 6.72628C0.196735 6.92798 0.150085 7.14637 0.149902 7.36727V14.775C0.149902 15.1927 0.315839 15.5933 0.611209 15.8887C0.906579 16.1841 1.30719 16.35 1.7249 16.35H3.8249C4.24262 16.35 4.64323 16.1841 4.9386 15.8887C5.23397 15.5933 5.3999 15.1927 5.3999 14.775V10.575C5.3999 10.4358 5.45521 10.3022 5.55367 10.2038C5.65213 10.1053 5.78566 10.05 5.9249 10.05H9.0749C9.21414 10.05 9.34768 10.1053 9.44613 10.2038C9.54459 10.3022 9.5999 10.4358 9.5999 10.575V14.775C9.5999 15.1927 9.76584 15.5933 10.0612 15.8887C10.3566 16.1841 10.7572 16.35 11.1749 16.35H13.2749C13.6926 16.35 14.0932 16.1841 14.3886 15.8887C14.684 15.5933 14.8499 15.1927 14.8499 14.775V7.36727C14.8499 7.14645 14.8034 6.9281 14.7135 6.72641C14.6236 6.52471 14.4922 6.34418 14.3281 6.19652L8.55305 1.00742Z"
                  />
                </svg>
              </i>
              <span className="news">Overview</span>
            </Link>
          </Button>
        </Menu.Item>
        {/* <span>
          <img src="/img/arrow-navigation.svg" />
        </span> */}
      </Menu>
      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} selectedKeys={selectedKeys} theme="light">
        {/* <Form className="">
          <div className="search-input-wrapper">
            <i>
              <img src={Search} />
            </i>
            <Form.Control type="search" placeholder="Search For Organiztion" className="" aria-label="Search" />
          </div>
        </Form> */}
        <Menu.Item key="label">
          <div className="menu-label-wrapper">
            <span>Menu</span>
          </div>
        </Menu.Item>

        <Menu.Item className={`${location.pathname == "/" ? "ant-menu-item-selected" : ""}`}>
          <Button>
            {/* DASHBOARD  */}
            <Link to={currentPortalId && currentPractice ? "/" : "#"} onClick={() => routeHadnler()}>
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17">
                  <path fill="currentColor" d="M11.73 0h2.821c1.169 0 2.116.955 2.116 2.133V4.98a2.124 2.124 0 0 1-2.116 2.133H11.73A2.124 2.124 0 0 1 9.614 4.98V2.133C9.614.955 10.561 0 11.73 0" />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M2.115 0h2.822c1.169 0 2.116.955 2.116 2.133V4.98a2.124 2.124 0 0 1-2.116 2.133H2.115A2.124 2.124 0 0 1 0 4.98V2.133C0 .955.947 0 2.115 0Zm0 9.555h2.822c1.169 0 2.116.954 2.116 2.133v2.845a2.125 2.125 0 0 1-2.116 2.134H2.115A2.125 2.125 0 0 1 0 14.533v-2.845c0-1.179.947-2.133 2.115-2.133Zm12.436 0H11.73a2.124 2.124 0 0 0-2.116 2.133v2.845c0 1.178.947 2.134 2.116 2.134h2.821a2.125 2.125 0 0 0 2.116-2.134v-2.845a2.124 2.124 0 0 0-2.116-2.133Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </i>
              <span>Dashboard</span>
            </Link>
          </Button>
        </Menu.Item>
        {account && account.permissions.length > 0 && companyPermission.some((p) => account.permissions.includes(p)) && (
          <Menu.Item className={`${urlPathName().length > 0 && urlPathName()[1] == "company" ? "ant-menu-item-selected" : ""}`}>
            <Button>
              <Link to={currentPortalId && currentPractice ? "/company" : "#"} onClick={() => routeHadnler()}>
                <i>
                  <svg fill="currentColor" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22,7H13V2a1,1,0,0,0-1-1H2A1,1,0,0,0,1,2V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM11,13H3V11h8Zm0-5V9H3V7h8ZM3,15h8v2H3ZM11,3V5H3V3ZM3,19h8v2H3Zm18,2H13V9h8Zm-5-5H14V14h2Zm0,4H14V18h2Zm4-4H18V14h2Zm-4-4H14V10h2Zm4,0H18V10h2Zm0,8H18V18h2Z" />
                  </svg>
                </i>
                <span>Company</span>
              </Link>
            </Button>
          </Menu.Item>
        )}
        {account && account.permissions.length > 0 && practicePermission.some((p) => account.permissions.includes(p)) && (
          <Menu.Item className={`${urlPathName().length > 0 && urlPathName()[1] == "practices" ? "ant-menu-item-selected" : ""}`}>
            <Button>
              <Link to={currentPortalId && currentPractice ? "/practices" : "#"} onClick={() => routeHadnler()}>
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 17">
                    <path
                      fill="currentColor"
                      d="M15.75 3.5h-3.5V1.75C12.25.779 11.471 0 10.5 0H7c-.971 0-1.75.779-1.75 1.75V3.5h-3.5C.779 3.5.009 4.279.009 5.25L0 14.875c0 .971.779 1.75 1.75 1.75h14c.971 0 1.75-.779 1.75-1.75V5.25c0-.971-.779-1.75-1.75-1.75Zm-5.25 0H7V1.75h3.5V3.5Z"
                    />
                  </svg>
                </i>
                <span>Practices</span>
              </Link>
            </Button>
          </Menu.Item>
        )}
        {account && account.permissions.length > 0 && advisorPermission.some((p) => account.permissions.includes(p)) && (
          <Menu.Item className={`${urlPathName().length == 2 && urlPathName()[1] == "advisors" ? "ant-menu-item-selected" : urlPathName().length > 0 && urlPathName()[2] == "advisors" ? "ant-menu-item-selected" : ""}`}>
            <Button>
              <Link to={currentPortalId && currentPractice ? "/advisors" : "#"} onClick={() => routeHadnler()}>
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                    <path
                      fill="currentColor"
                      d="M8.75 0C3.92 0 0 3.92 0 8.75s3.92 8.75 8.75 8.75 8.75-3.92 8.75-8.75S13.58 0 8.75 0Zm3.159 5.548c.936 0 1.688.752 1.688 1.688 0 .936-.752 1.689-1.688 1.689a1.684 1.684 0 0 1-1.689-1.689 1.679 1.679 0 0 1 1.689-1.688Zm-5.25-1.383c1.137 0 2.065.928 2.065 2.065a2.069 2.069 0 0 1-2.065 2.065A2.069 2.069 0 0 1 4.594 6.23c0-1.146.918-2.065 2.065-2.065Zm0 7.989v3.281a7.006 7.006 0 0 1-4.498-4.34c.919-.98 3.211-1.479 4.498-1.479.463 0 1.05.07 1.662.193-1.435.761-1.662 1.767-1.662 2.345ZM8.75 15.75c-.236 0-.464-.009-.691-.035v-3.561c0-1.243 2.572-1.864 3.85-1.864.936 0 2.555.341 3.36 1.006-1.024 2.599-3.553 4.454-6.519 4.454Z"
                    />
                  </svg>
                </i>
                <span>Advisors</span>
              </Link>
            </Button>
          </Menu.Item>
        )}
        {account && account.permissions.length > 0 && clientPermission.some((p) => account.permissions.includes(p)) && (
          <Menu.Item className={`${urlPathName().length > 0 && urlPathName()[2] == "clients" ? "ant-menu-item-selected" : ""}`}>
            <Button>
              <Link to={currentPortalId && currentPractice ? `/${currentPractice}/clients` : "#"} onClick={() => routeHadnler()}>
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path
                      fill="currentColor"
                      d="M7 7c1.934 0 3.5-1.566 3.5-3.5S8.934 0 7 0a3.499 3.499 0 0 0-3.5 3.5C3.5 5.434 5.066 7 7 7Zm0 1.75c-2.336 0-7 1.172-7 3.5v.875c0 .481.394.875.875.875h12.25a.878.878 0 0 0 .875-.875v-.875c0-2.328-4.664-3.5-7-3.5Z"
                    />
                  </svg>
                </i>
                <span>Clients</span>
              </Link>
            </Button>
          </Menu.Item>
        )}
        <Menu.Item className={`${urlPathName().length > 0 && urlPathName()[1] == "retirement-vehicle" ? "ant-menu-item-selected" : ""}`}>
          <Button>
            <Link to={currentPortalId && currentPractice ? `/retirement-vehicle/401(k)_at_work` : "#"} onClick={() => routeHadnler()}>
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 17">
                  <path
                    fill="currentColor"
                    d="m15.601 4.813-1.986-1.987c.061-.367.158-.708.28-1.006.096-.227.131-.49.079-.761C13.86.429 13.248-.01 12.6 0a4.38 4.38 0 0 0-3.412 1.75H4.811A4.81 4.81 0 0 0 0 6.563c0 1.968 1.199 6.545 1.82 8.785.21.752.901 1.277 1.689 1.277H5.25c.963 0 1.75-.787 1.75-1.75h1.75c0 .963.787 1.75 1.75 1.75h1.759c.77 0 1.452-.507 1.68-1.251l1.094-3.64 1.872-.63a.875.875 0 0 0 .595-.831V5.688a.878.878 0 0 0-.875-.875h-1.024ZM8.75 6.125H6.125a.878.878 0 0 1-.875-.875c0-.481.394-.875.875-.875H8.75c.481 0 .875.394.875.875a.878.878 0 0 1-.875.875Zm3.5 1.75A.878.878 0 0 1 11.375 7c0-.481.394-.875.875-.875s.875.394.875.875a.878.878 0 0 1-.875.875Z"
                  />
                </svg>
              </i>
              <span>Retirement Vehicle</span>
            </Link>
          </Button>
        </Menu.Item>
        {/* {
                    menuItems.map((items,i)=>
                        (items.type.includes(type) && items.roles.includes(gblUserRole) && 
                        <Menu.Item key={items.key} className={items.className} disabled={items.disabled}>
                            <Button className={'menuItem'} style={{textAlign:'left'}} block onClick={()=> items.reset ? localStorage.setItem('currentPractice',null): {}}>
                            <Link to={items.path[type]}>
                            <div className="menu-item-bullet">
                                <div className="menu-item-bullet-inner"/>
                            </div>
                            <Icon type={items.icon}/>
                            <span>{items.label}</span>
                            </Link>
                            </Button>
                        </Menu.Item>)
                    )
                } */}
      </Menu>
      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} selectedKeys={selectedKeys} theme="light">
        <Menu.Item key="label">
          <div className="menu-label-wrapper">
            <span>OPTIONS</span>
          </div>
        </Menu.Item>
        <Menu.Item className={`${location.pathname.includes("settings/account") ? "ant-menu-item-selected" : ""}`}>
          <Button>
            <Link to="/settings/account" onClick={() => profileHandler()}>
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 15">
                  <path
                    fill="currentColor"
                    d="M7.586 7.893a11.266 11.266 0 0 0-6.37 1.575C.446 9.921 0 10.78 0 11.68v1.444c0 .481.394.875.875.875h7.227a6.092 6.092 0 0 1-.516-6.107ZM7 7a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM16.406 10.5c0-.193-.026-.367-.052-.551l.735-.639a.44.44 0 0 0 .087-.551l-.516-.893a.427.427 0 0 0-.516-.192l-.928.315a3.19 3.19 0 0 0-.945-.552l-.192-.953a.44.44 0 0 0-.429-.35h-1.033a.44.44 0 0 0-.428.35l-.193.953a3.19 3.19 0 0 0-.945.552l-.927-.315a.434.434 0 0 0-.516.192l-.517.893a.44.44 0 0 0 .088.551l.735.639c-.026.184-.053.358-.053.551 0 .193.027.367.053.551l-.735.639a.44.44 0 0 0-.088.551l.517.893a.427.427 0 0 0 .516.192l.927-.315c.28.237.595.42.945.552l.193.953a.44.44 0 0 0 .428.35h1.033a.44.44 0 0 0 .429-.35l.192-.954a3.19 3.19 0 0 0 .945-.55l.928.314a.434.434 0 0 0 .516-.192l.516-.893a.44.44 0 0 0-.087-.551l-.735-.639c.026-.184.052-.358.052-.551Zm-3.281 1.75c-.963 0-1.75-.787-1.75-1.75s.787-1.75 1.75-1.75 1.75.787 1.75 1.75-.787 1.75-1.75 1.75Z"
                  />
                </svg>
              </i>
              <span>Profile</span>
            </Link>
          </Button>
        </Menu.Item>
        {/* <Menu.Item className={`${location.pathname.includes("settings/general") ? "ant-menu-item-selected" : ""}`}>
          <Button>
            <Link to="/settings/general">
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                  <path
                    fill="currentColor"
                    d="M15.07 8.75c0-.201-.008-.394-.026-.595l1.628-1.234a.88.88 0 0 0 .227-1.137l-1.636-2.826a.864.864 0 0 0-1.094-.368l-1.881.796a6.639 6.639 0 0 0-1.024-.595L11.01.77a.878.878 0 0 0-.866-.77H6.88a.88.88 0 0 0-.875.77l-.253 2.021c-.36.167-.7.368-1.024.595L2.847 2.59a.863.863 0 0 0-1.094.368L.117 5.793A.882.882 0 0 0 .344 6.93l1.628 1.234a6.425 6.425 0 0 0 0 1.181L.344 10.579a.88.88 0 0 0-.227 1.137l1.636 2.827a.863.863 0 0 0 1.094.367l1.88-.796c.325.227.666.428 1.025.595l.253 2.021c.053.438.43.77.867.77h3.263a.878.878 0 0 0 .867-.77l.253-2.021c.36-.166.7-.368 1.024-.595l1.881.796a.863.863 0 0 0 1.094-.367l1.636-2.827a.882.882 0 0 0-.227-1.137l-1.628-1.234c.027-.201.035-.394.035-.595Zm-6.527 3.063A3.066 3.066 0 0 1 5.48 8.75a3.066 3.066 0 0 1 3.063-3.063 3.066 3.066 0 0 1 3.062 3.063 3.066 3.066 0 0 1-3.062 3.063Z"
                  />
                </svg>
              </i>
              <span>Settings</span>
            </Link>
          </Button>
        </Menu.Item> */}
        <Menu.Item className={`${urlPathName().length > 0 && urlPathName()[1] == "faq" ? "ant-menu-item-selected" : ""}`}>
          <Button>
            <Link to="/faq" onClick={() => routeHadnler()}>
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                  <path
                    fill="currentColor"
                    d="M8.75 0C3.92 0 0 3.92 0 8.75s3.92 8.75 8.75 8.75 8.75-3.92 8.75-8.75S13.58 0 8.75 0Zm0 13.125a.878.878 0 0 1-.875-.875v-3.5c0-.481.394-.875.875-.875s.875.394.875.875v3.5a.878.878 0 0 1-.875.875Zm.875-7h-1.75v-1.75h1.75v1.75Z"
                  />
                </svg>
              </i>
              <span>FAQ</span>
            </Link>
          </Button>
        </Menu.Item>
        <Menu.Item className={`${urlPathName().length > 0 && (urlPathName()[1] == "learning-center" || urlPathName()[1] == "personality-behavior") ? "ant-menu-item-selected" : ""}`}>
          <Button>
            <Link to="/learning-center" onClick={() => routeHadnler()}>
              <i>
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    d="M18.375 0.875H2.625C2.16087 0.875 1.71575 1.05937 1.38756 1.38756C1.05937 1.71575 0.875 2.16087 0.875 2.625V5.25H2.625V2.625H18.375V14.875H12.25V16.625H18.375C18.8391 16.625 19.2842 16.4406 19.6124 16.1124C19.9406 15.7842 20.125 15.3391 20.125 14.875V2.625C20.125 2.16087 19.9406 1.71575 19.6124 1.38756C19.2842 1.05937 18.8391 0.875 18.375 0.875ZM0.875 14V16.625H3.5C3.5 15.9288 3.22344 15.2611 2.73116 14.7688C2.23887 14.2766 1.57119 14 0.875 14ZM0.875 10.5V12.25C1.44953 12.25 2.01844 12.3632 2.54924 12.583C3.08004 12.8029 3.56234 13.1252 3.96859 13.5314C4.37485 13.9377 4.69711 14.42 4.91697 14.9508C5.13684 15.4816 5.25 16.0505 5.25 16.625H7C7 15.0005 6.35469 13.4426 5.20603 12.294C4.05737 11.1453 2.49945 10.5 0.875 10.5ZM0.875 7V8.75C1.90916 8.75 2.93319 8.95369 3.88863 9.34945C4.84407 9.7452 5.7122 10.3253 6.44347 11.0565C7.17473 11.7878 7.7548 12.6559 8.15055 13.6114C8.54631 14.5668 8.75 15.5908 8.75 16.625H10.5C10.5 11.305 6.18625 7 0.875 7ZM9.625 7.95375V9.70375L12.6875 11.375L15.75 9.70375V7.95375L12.6875 9.625L9.625 7.95375ZM12.6875 3.5L7.875 6.125L12.6875 8.75L17.5 6.125L12.6875 3.5Z"
                  />
                </svg>
              </i>
              <span>Learning Center</span>
            </Link>
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button onClick={() => handleUserLogout()}>
            <Link to="#">
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                  <path fill="currentColor" d="M1.75 1.75H7a.878.878 0 0 0 .875-.875A.878.878 0 0 0 7 0H1.75C.787 0 0 .787 0 1.75V14c0 .963.787 1.75 1.75 1.75H7a.878.878 0 0 0 .875-.875A.878.878 0 0 0 7 14H1.75V1.75Z" />
                  <path fill="#181818" d="m15.444 7.569-2.442-2.441a.438.438 0 0 0-.752.306V7H6.125a.878.878 0 0 0-.875.875c0 .481.394.875.875.875h6.125v1.566c0 .394.473.586.744.306l2.441-2.44a.43.43 0 0 0 .009-.613Z" />
                </svg>
              </i>
              <span>Logout</span>
            </Link>
          </Button>
        </Menu.Item>
        {/* {
                    optionsItem.map((items,i)=>
                        (items.type.includes(type) && items.roles.includes(gblUserRole) && 
                        <Menu.Item key={items.key} className={items.className} disabled={items.disabled}>
                            <Button className={'menuItem'} style={{textAlign:'left'}} block onClick={()=> items.reset ? localStorage.setItem('currentPractice',null): {}}>
                                <Link to={items.path[type]}>
                                    <div className="menu-item-bullet">
                                        <div className="menu-item-bullet-inner"/>
                                    </div>
                                    <Icon type={items.icon}/>
                                    <span>{items.label}</span>
                                </Link>
                            </Button>
                        </Menu.Item>)
                    )
                } */}
      </Menu>

      <div className="contact-footer-bar-wrapper">
        <span>Contact</span>
        <div className="info-link-block">
          <p>
            Email:{" "}
            <Link to="/faq#bottom" onClick={() => routeHadnler()}>
              support@unitifi.com
            </Link>
          </p>
          <p>
            Phone: <a href="tel:+4029797185">(402) 979-7185</a>
          </p>
        </div>
      </div>
      <Modal show={menuModal} onHide={() => setMenuModal(false)} centered className="unitifi-modal-wrapper request-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <h4>Thank you for signing up!</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please be patient while you wait for your organization to accept your request. Feel free to checkout the tools you will receive by joining the Unitifi platform!</p>
          <div className="modal-btn-wrapper">
            <button className="exploreBtn" type="primary" onClick={() => window.open("https://unitifi.com", "_blank")}>
              Explore Our Tools
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </MenuWrapper>
  );
};

export default MenuComp;
