import React, { useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { AutoComplete, Badge, Form, Icon, Input, Select as Selectd, Spin, Row, Col, Button } from "antd";
import { AddButtons, EditButtons, FormWrapper } from "../../../components/form";
import { useQuery, graphql } from "react-apollo";
import _, { flowRight as compose } from "lodash";
import Select from "react-select";
import { AccountContext } from "../../../context/AccountContext";
import PromptModal from "../../../components/promptModal";
import { phoneMsg, phoneNumberCheck } from "../../../helpers/formatters";

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Selectd;

function ClientForm(props) {
  const accountContext = useContext(AccountContext);
  const { account } = accountContext;
  const {
    cityListQuery,
    stateListQuery,

    data: { getCountrylist },
  } = props;

  const { getFieldError, getFieldProps, getFieldDecorator, setFields } = props.form;
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("+1");
  const [isPromptModalClose, setPromptModalClose] = useState(false);
  const { currentPractice, me, currentPortalId } = props;

  const { loading, error, data, refetch } = useQuery(
    gql`
      query($practiceId: String, $portalId: Int, $roleCode: String, $accountType: Int) {
        getAllAdvisorsPerPracticesForForm(practiceId: $practiceId, portalId: $portalId, roleCode: $roleCode, accountType: $accountType) {
          advisors {
            id
            firstName
            lastName
            practiceId
          }
        }
      }
    `,
    {
      variables: {
        practiceId: currentPractice,
        portalId: currentPortalId,
        roleCode: me && me.rolecode,
        accountType: me && me.accountType,
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  const { error: countryError, data: countryData } = useQuery(
    gql`
      query {
        getCountry {
          countries {
            id
            name
            dial_code
          }
        }
      }
    `
  );

  useEffect(() => {
    if (accountContext && _.get(accountContext, "account.portalid[0]")) {
      recall();
    }
  }, [accountContext.account]);

  useEffect(() => {
    if (!getCountrylist) return;
    if (!getCountrylist.countrylist) return;
    const country = getCountrylist.countrylist.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
    setCountryList(country);
  }, [getCountrylist]);

  useEffect(() => {
    if (!countryData) return;
    let result = countryData.getCountry.countries;

    let countArr = [];
    for (let res of result) {
      if (res.name != "Canada") {
        let obj = {
          label: res.dial_code,
          value: res.dial_code,
        };
        countArr.push(obj);
      }
    }
    setCountries(countArr);
  }, [countryData]);

  useEffect(() => {
    if (countryList.length == 0) return;

    if (props.client) {
      let cntry = countryList.filter((data) => data.label == props.client.country);

      if (cntry.length > 0) {
        cntry = cntry[0];
        setTimeout(function() {
          setFields({
            country: {
              value: { label: cntry.label, value: cntry.value },
            },
          });
        }, 2000);

        stateHandler({ value: cntry.value });
      }
    }
  }, [countryList]);

  useEffect(() => {
    if (cityList.length == 0) return;

    if (props.client) {
      let ctry = cityList.filter((data) => data.label == props.client.city);
      if (ctry.length > 0) {
        ctry = ctry[0];
        setFields({
          city: {
            value: { label: ctry.label, value: ctry.value },
          },
        });
      }
    }
  }, [cityList]);

  useEffect(() => {
    if (Countries.length == 0) return;
    if (props.client && props.client.phone_code) setCountryCode(props.client.phone_code);
    if (props.client) {
      let ctry = Countries.filter((data) => data.label == props.client.phone_code);
      if (ctry.length > 0) {
        ctry = ctry[0];
        setFields({
          phoneNumber: {
            value: { label: ctry.label, value: ctry.value },
          },
        });
      }
    }
  }, [Countries]);

  useEffect(() => {
    if (stateList.length == 0) return;

    if (props.client) {
      let ctry = stateList.filter((data) => data.label == props.client.state);
      if (ctry.length > 0) {
        ctry = ctry[0];
        setFields({
          state: {
            value: { label: ctry.label, value: ctry.value },
          },
        });
        cityHandler({ value: ctry.value });
      }
    }
  }, [stateList]);

  useEffect(() => {
    if (isPromptModalClose) setPromptModalClose(false);
  }, [isPromptModalClose]);

  const cityHandler = (input) => {
    cityListQuery
      .refetch({ state_id: input.value + "" })
      .then(({ data }) => {
        let state = data.getCities.cities;
        const cityData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        setCityList(cityData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const stateHandler = (input) => {
    setFields({
      state: {
        value: "",
      },
    });
    setFields({
      city: {
        value: "",
      },
    });
    stateListQuery.refetch({ country_id: input.value + "" }).then(({ data }) => {
      let state = data.getStates.states;
      const stateData = state.map((s) => {
        return {
          label: s.name,
          value: s.id,
        };
      });
      setStateList(stateData);
    });
  };

  const phondeCodeHandler = (val) => {
    setCountryCode(val.value);
  };

  function recall() {
    refetch({
      practiceId: currentPractice,
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    props.form.validateFields((err, values) => {
      if (values.phoneNumber && typeof values.phoneNumber != "object") {
        if (values.p1 == undefined || values.p1.length !== 10) {
          props.form.setFields({
            phoneNumber: {
              value: values.phoneNumber,
              errors: [new Error("Please enter valid phone number.")],
            },
          });
          return;
        }

        let ph = values.p1;
        // if (phoneNumberCheck(ph)) {
        //   props.form.setFields({
        //     phoneNumber: {
        //       value: values.phoneNumber,
        //       errors: [new Error(phoneMsg)],
        //     },
        //   });
        //   return;
        // }
      }
      if (!err) {
        values.countryCode = countryCode;
        if (values.p1) values.phoneNumber = values.p1;
        props.onSubmit(values);
      } else setPromptModalClose(true);
    });
  }

  if (loading) {
    return (
      <div className="loading-wrapper">
        <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="loading-wrapper">
        <p>Whoopsie - client didn&apos;t load.</p>
      </div>
    );
  }

  let advisors = _.get(data.getAllAdvisorsPerPracticesForForm, "advisors");

  const autoFocusPhone = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");

    if (e.key == "Shift" || e.key == "Tab") {
      document.getElementById(c).focus();
      return;
    }
    if (length == maxLength) {
      if (n != "") {
        document.getElementById(n).focus();
      }
    }
  };

  return (
    <FormWrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        {
          /*props.gblUserIsGlobalAdmin || props.gblUserIsPortalAdmin || props.gblUserIsPracticeAdmin ? (*/
          <Row gutter={16}>
            <Col span={22}>
              <FormItem label="Advisor">
                {getFieldDecorator("advisorId", {
                  initialValue: props.client ? (props.client.isAssign ? undefined : props.client.advisorId) : undefined,
                  rules: [
                    {
                      message: "Advisor is required.",
                      required: true,
                    },
                  ],
                })(
                  <Selectd optionFilterProp="children" placeholder="Advisor" showSearch>
                    {_.map(advisors, (advisor, x) => (
                      <Option key={x} value={advisor.id}>
                        {advisor.firstName} {advisor.lastName}
                      </Option>
                    ))}
                  </Selectd>
                )}
              </FormItem>
            </Col>
          </Row>
          /*) : null*/
        }

        <Row gutter={16}>
          <Col span={12}>
            <FormItem className="" label="Client First Name">
              {getFieldDecorator("firstName", {
                initialValue: props.client ? props.client.firstName : null,
                rules: [
                  {
                    message: "Client first name is required.",
                    required: true,
                  },
                ],
              })(<Input placeholder="First Name" />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem className="" label="Client Last Name">
              {getFieldDecorator("lastName", {
                initialValue: props.client ? props.client.lastName : null,
                rules: [
                  {
                    message: "Client last name is required.",
                    required: true,
                  },
                ],
              })(<Input placeholder="Last Name" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Client Email">
              {getFieldDecorator("email", {
                initialValue: props.client ? props.client.email : null,
                rules: [
                  {
                    message: "Client email is required.",
                    required: true,
                  },
                  {
                    type: "email",
                    message: "Client email is not valid",
                  },
                ],
              })(<Input placeholder="Client Email" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem className="input-phone__wrapper" label="Phone Number">
              <InputGroup compact className="unitifi-detail-pages-number-inputbox-wrapper UnitifiCustomerNumberInputbox">
                <Select
                  options={Countries}
                  defaultValue={props.client && props.client.phone_code ? { label: props.client.phone_code, value: props.client.phone_code } : { label: "+1", value: "+1" }}
                  onKeyDown={(event) => {
                    if (
                      event.key == "Backspace" ||
                      event.key == "Delete" ||
                      event.key == "ArrowLeft" ||
                      event.key == "ArrowRight" ||
                      event.key == "Meta" ||
                      event.key == "ArrowUp" ||
                      event.key == "ArrowDown" ||
                      event.key == "Enter" ||
                      event.key == "Tab"
                    ) {
                    } else if (!/[0-9+]/.test(event.key)) event.preventDefault();
                  }}
                  onChange={(val) => phondeCodeHandler(val)}
                  className="unitifi-select-container phoneInputBox"
                  classNamePrefix="unitifi-react-select"
                />
                {getFieldDecorator("phoneNumber", {
                  initialValue: props.client && props.client.phone_code ? { label: props.client.phone_code, value: props.client.phone_code } : { label: "+1", value: "+1" },
                  rules: [
                    {
                      required: false,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <div className="input-phone__input">
                    {getFieldDecorator("p1", {
                      initialValue: props.client && props.client.phoneNumber && !props.client.phoneNumber.includes("null") ? props.client.phoneNumber : null,
                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        maxLength={10}
                        placeholder=""
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        // onKeyUp={(event) => {
                        //   autoFocusPhone(event, "", "p1", "p2");
                        // }}
                      />
                    )}

                    {/* {getFieldDecorator("p2", {
                      initialValue: props.client && props.client.phoneNumber && !props.client.phoneNumber.includes("null") ? props.client.phoneNumber.substring(3, 6) : null,
                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                      ],
                    })(
                      <Input
                        size={"large"}
                        maxLength={3}
                        placeholder="000"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onKeyUp={(event) => {
                          autoFocusPhone(event, "p1", "p2", "p3");
                        }}
                      />
                    )} */}

                    {/* {getFieldDecorator("p3", {
                      initialValue: props.client && props.client.phoneNumber && !props.client.phoneNumber.includes("null") ? props.client.phoneNumber.substring(6, 10) : null,
                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                      ],
                    })(
                      <Input
                        size={"large"}
                        maxLength={4}
                        placeholder="0000"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onKeyUp={(event) => {
                          autoFocusPhone(event, "p2", "p3", "p3");
                        }}
                      />
                    )} */}
                  </div>
                )}
              </InputGroup>
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem className="" label="Address">
              {getFieldDecorator("address", {
                initialValue: props.client ? props.client.address : null,
                rules: [
                  {
                    message: "Address is required.",
                    required: false,
                  },
                ],
              })(<Input placeholder="Address" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Country">
              {getFieldDecorator("country", {
                rules: [
                  {
                    message: "Country is required.",
                    required: false,
                  },
                ],
              })(
                <Select
                  options={countryList}
                  onChange={(e) => stateHandler(e)}
                  className="unitifi-select-container"
                  classNamePrefix="unitifi-react-select"
                  filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem className="" label="Postal Code/ZIP Code">
              {getFieldDecorator("zipCode", {
                initialValue: props.client ? props.client.zipCode : null,
                rules: [
                  {
                    message: "Postal Code/ZIP Code is required.",
                    required: false,
                  },
                ],
              })(<Input placeholder="Postal Code/ZIP Code" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="State/Province/Region">
              {getFieldDecorator("state", {
                rules: [
                  {
                    message: "State/Province/Region is required.",
                    required: false,
                  },
                ],
              })(
                <Select
                  options={stateList}
                  onChange={(e) => {
                    cityHandler(e);
                    setFields({
                      city: {
                        value: "",
                      },
                    });
                  }}
                  className="unitifi-select-container"
                  classNamePrefix="unitifi-react-select"
                  filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="City/Town">
              {getFieldDecorator("city", {
                rules: [
                  {
                    message: "City/Town is required.",
                    required: false,
                  },
                ],
              })(<Select options={cityList} className="unitifi-select-container" classNamePrefix="unitifi-react-select" filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0} />)}
            </FormItem>
          </Col>
        </Row>

        {/* <Row gutter={16}>
          <Col span={13}>
            <FormItem className="" label="Add Client to the Team">
              {getFieldDecorator("addClientToTeam", {
                initialValue: props.client ? props.client.addClientToTeam : true,
              })(
                <Selectd placeholder="Add to team">
                  <Option value={true}>
                    <Badge status="success" text="Yes" />
                  </Option>
                  <Option value={false}>
                    <Badge status="error" text="No" />
                  </Option>
                </Selectd>
              )}
            </FormItem>
          </Col>
        </Row> */}
        <div className="form-toolbar">
          {props.client && account && account.permissions.includes("editclients") ? (
            <EditButtons deleteRights={"deleteClients"} cancelUrl={props.cancelUrl} loading={props.loading} onDelete={props.onDelete} onSubmit={handleSubmit} rights={["superAdmin", "editclients"]} />
          ) : (
            account &&
            account.permissions.includes("createclients") && (
              <AddButtons cancelUrl={props.cancelUrl} createAnother={props.createAnother} loading={props.loading} onCreateAnother={props.onCreateAnother} onSubmit={handleSubmit} rights={["superAdmin", "createclients"]} />
            )
          )}
          {props.isEdit && account.permissions.includes("deleteclients") && (
            <Button type="danger" onClick={() => props.onDelete()}>
              Delete
            </Button>
          )}
        </div>
      </Form>
      {!props.loading && (
        <PromptModal
          isBlocked={props.form.isFieldsTouched()}
          title="Leave Page?"
          content="You have unsaved changes. Are you sure you want to leave this page?"
          {...props}
          submitAction={handleSubmit}
          loading={props.loading}
          isPromptModalClose={isPromptModalClose}
        />
      )}
    </FormWrapper>
  );
}

const countryQuery = gql`
  query {
    getCountrylist {
      countrylist {
        id
        name
        iso2
        iso3
      }
    }
  }
`;

const stateQuety = gql`
  query($country_id: String!) {
    getStates(country_id: $country_id) {
      states {
        id
        name
        state_code
      }
    }
  }
`;

const cityQuery = gql`
  query($state_id: String!) {
    getCities(state_id: $state_id) {
      cities {
        id
        name
      }
    }
  }
`;

export default compose(
  graphql(countryQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
    }),
  }),
  graphql(stateQuety, {
    name: "stateListQuery",
    options: (props) => ({
      variables: {
        country_id: props.country_id,
      },
    }),
  }),
  graphql(cityQuery, {
    name: "cityListQuery",
    options: (props) => ({
      variables: {
        state_id: props.state_id,
      },
    }),
  })
)(Form.create()(ClientForm));
//
// ClientForm.propTypes = {
//     createAnother: PropTypes.bool,
//     loading: PropTypes.bool.isRequired,
//     onCreateAnother: PropTypes.func,
//     onDelete: PropTypes.func,
//     onSubmit: PropTypes.func.isRequired,
// };
//
// ClientForm.defaultProps = {
//     createAnother: false,
//     onCreateAnother: null,
//     onDelete: null,
// };
//
