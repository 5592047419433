import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import decode from "jwt-decode";
import App from "./layouts/app";
import { UsersAccountConfirm, UsersLogin, UsersPasswordForgot, UsersPasswordReset, UsersSignup, UsersSignupNew, UsersSignupAccountType, CollectPayment, Where, PaymentMethod, AssessmentLink } from "./containers/users";
import Subscriptions from "./containers/stripe/subscriptions";
import SuccessPage from "./containers/stripe/successPage";
import collect_payment from "./containers/users/collect_payment";
import SearchOrganization from "./containers/users/searchOrganization";
import SearchClient from "./containers/clients/searchClient";
import Landing from "./containers/landing";
import SendAssessment from "./containers/salesforce/sendAssessment";
import AssessmentPdf from "./containers/salesforce/assessmentPdf";
import DownloadAssessment from "./containers/salesforce/downloadAssessment";
import UpdateEmail from "./containers/users/updateEmail";
import PageNotFound from "./containers/404";
import Signup2fa from "./containers/users/signup/signup2fa";
import Login2fa from "./containers/users/login/login2fa";

/* check for authenticated user based on token & refresh token status */
let currentUser = null;

const isAuthenticated = (Component, props) => {
  const token = localStorage.getItem("token");

  const refreshToken = localStorage.getItem("refreshToken");
  /* check for token */
  if (token) {
    try {
      /* catch for no user practices - send to login */
      const {
        user: { practices, paid, id, customerStripeId },
      } = decode(token);
      const { exp } = decode(refreshToken);

      if (Date.now() / 1000 > exp) {
        return <Redirect to={{ pathname: "/login" }} />;
      }

      // if(!paid){
      //   return <Redirect to={{ pathname: `/subscription`}} />
      // }

      // comment
      // if (!practices) {
      //   return <Redirect to={{ pathname: "/login" }} />;
      // }

      /* catch for expired token - send to login */
    } catch (err) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    currentUser = decode(localStorage.getItem("token")).user;

    return <Component {...props} user={currentUser} />;
  }
  let redirectUrl = { pathname: `/login` };

  if (props.location.pathname.includes("/wealthbox-authorization") || props.location.pathname.includes("/salesforce-authorization")) {
    let wealthboxUrl = `?goto=${props.location.pathname}`;
    if (props.location.search) wealthboxUrl += props.location.search;

    redirectUrl.search = wealthboxUrl;
  }

  if (props.location.pathname.includes("/clients/view") && props.location.search.includes("?goto=email")) {
    let url = `?goto=${props.location.pathname}`;
    redirectUrl.search = url;
  }

  return <Redirect to={redirectUrl} />;
};

/* private route template, props current user from confirmed token */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => isAuthenticated(Component, props)
      // ? (<Component {...props} user={currentUser} />)
      // : (<Redirect to={{ pathname: '/login' }} />)
    }
  />
);

/* react router setup for routing before user is authenticated */
const PublicRoutes = (props) => {
  let tokenUser = localStorage.getItem("token");
  if (tokenUser && tokenUser != "null" && tokenUser.trim().length > 2) {
    tokenUser = decode(tokenUser);
    if (tokenUser) {
      tokenUser = tokenUser.user;
      if (tokenUser && tokenUser.status == "Pending" && window.location.pathname != "/signup-2fa") {
        window.location = "signup-2fa";
        return;
      }
      if (tokenUser && tokenUser.status != "Pending" && tokenUser.accountType == 1 && tokenUser.customerStripeId == null && window.location.pathname != "/collect-payment") {
        window.location = "collect-payment";
        return;
      }
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route component={PageNotFound} exact path="/404" />
        <Route exact path="/._darcs" component={PageNotFound} />
        <Route exact path="/.bzr" component={PageNotFound} />
        <Route exact path="/.hg" component={PageNotFound} />
        <Route exact path="/BitKeeper" component={PageNotFound} />
        <Route exact path="/latest/meta-data/" component={PageNotFound} />
        <Route component={UsersLogin} path="/login" />
        {/* <Route component={AssessmentLink} exact path="/survey/:practiceId/:userId" {...props} /> */}
        <Route component={AssessmentLink} exact path="/assessment/:companyName/:practiceIds/:userIds" {...props} />
        <Route component={UpdateEmail} exact path="/update-email/:token" {...props} />
        <Route component={SendAssessment} exact path="/salesforce-assessment/:token" {...props} />
        <Route component={AssessmentPdf} exact path="/salesforce-assessment-pdf/:token?" {...props} />
        <Route component={DownloadAssessment} exact path="/salesforce-download-assessment/:token/:type" {...props} />

        <Route component={UsersSignup} exact path="/signup" />
        <Route component={Signup2fa} exact path="/signup-2fa" />
        <Route component={Login2fa} exact path="/login-2fa" />
        <Route component={UsersSignupNew} exact path="/signup_new" />
        <Route component={UsersSignupAccountType} exact path="/signup_account_type" />
        <PrivateRoute component={collect_payment} exact path="/collect-payment" />
        <PrivateRoute component={SearchOrganization} exact path="/search-organization" />
        <PrivateRoute component={SearchClient} exact path="/search-client" />
        <PrivateRoute component={SearchClient} exact path="/search-advisor" />
        <PrivateRoute component={SearchClient} exact path="/search-practice" />
        <Route component={PaymentMethod} exact path="/paymentmethod" />
        <Route component={Subscriptions} exact path="/subscription" />
        <Route component={SuccessPage} exact path="/payment/success" />
        {/* DASHBOARD */}

        <Route component={Where} exact path="/where" />
        <Route component={UsersAccountConfirm} exact path="/account/confirm/:token" />
        <Route component={UsersPasswordForgot} exact path="/password/forgot" />
        <Route component={UsersPasswordReset} exact path="/password/reset/:token" />
        <PrivateRoute component={App} path="/" />
        {/* DASHBOARD */}
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default PublicRoutes;
