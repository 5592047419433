import React, { useContext, useLayoutEffect, useEffect, useState } from "react";
import { Titlebar } from "../../components";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import { Row, Col, Progress, Skeleton, Table } from "antd";
import { RadialChart } from "react-vis";
import Chart from "react-apexcharts";
import gql from "graphql-tag";
import _ from "lodash";
import { FormCheck } from "react-bootstrap";
import { SizeMe } from "react-sizeme";
import { AccountContext } from "../../context/AccountContext";
import { useLazyQuery, useQuery } from "react-apollo";
import { practiceConfig } from "../../config";
import { Link, Redirect } from "react-router-dom";
import TitleBar from "../../components/titlebar/titlebar";

const RetirementVehicle = (props) => {
  const { match, currentPractice, currentPortalId, location } = props;
  const [state, setState] = useState({
    limit: 100,
    loading: false,
    order: "ASC",
    orderColumn: "",
    page: 1,
    search: "",
    totalCount: null,
    sortDirection: "",
    practiceId: props.currentPractice,
    filter: "401(k)_at_work",
    isNo: false,
  });
  const accountContext = useContext(AccountContext);
  const [chartData, setChartData] = useState(null);
  const [series, setSeries] = useState([]);
  const account = _.get(accountContext, "account", null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [filterVehicleHandler, { loading, data }] = useLazyQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $practiceId: Int, $search: String, $filter: String, $isNo: Boolean, $portalId: Int, $accountType: Int, $roleCode: String) {
        getFilteredVehicles(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, practiceId: $practiceId, search: $search, filter: $filter, isNo: $isNo, roleCode: $roleCode, portalId: $portalId, accountType: $accountType) {
          filteredvehicles {
            id
            vehicle
            advisorId
            clientId
            practiceId
            firstName
            lastName
            email
          }
          pagination {
            offset
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: state.search,
        practiceId: state.practiceId,
        filter: state.filter,
        isNo: state.isNo,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  const [getAllRetirementVehicle, { data: allData }] = useLazyQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $practiceId: Int, $search: String, $filter: String, $portalId: Int, $accountType: Int, $roleCode: String) {
        getAllRetirementVehicle(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, practiceId: $practiceId, search: $search, filter: $filter, roleCode: $roleCode, portalId: $portalId, accountType: $accountType) {
          percentage
          breakdown
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        let breakDownData = _.get(data, "getAllRetirementVehicle.breakdown", []);

        breakDownData = breakDownData[0];
        let labelArr = [];
        let colorArr = [];
        let seriesArr = [];

        Object.entries(breakDownData).map(([key, value]) => {
          labelArr.push(key);
          colorArr.push(key.includes("only_one") ? "#006FBA" : key.includes("No") ? "#C2CFE3" : "#004F86");
          // if (key == "401(k)_at_work") value = 3;
          // if (key == "No 401(k)_at_work") value = 97;
          seriesArr.push(Number(value));

          // dataArr.push({ angle: Number(value), color: key.includes("No") ? "#C2CFE3" : "#004F86", name: key.includes("no") ? "None" : key, label: value });
        });
        if (seriesArr.length > 0 && seriesArr[0] == 0 && seriesArr[1] == 0) {
          seriesArr = [100];
          colorArr = ["#C2CFE3"];
          labelArr = ["none"];
        }

        let options = {
          chart: {
            width: "100%",
            type: "pie",
          },
          labels: labelArr,
          colors: colorArr,
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          dataLabels: {
            formatter(val, opts) {
              return opts.w.config.series[opts.seriesIndex] + "%";
            },
          },
          legend: {
            show: false,
          },
          stroke: {
            width: 3,
          },
          states: {
            hover: {
              filter: {
                type: "none",
                value: 0,
              },
            },
          },
          tooltip: {
            enabled: seriesArr.length > 0 && seriesArr[0] == 0 && seriesArr[1] == 0 ? false : true,
            y: {
              formatter: function(val) {
                return val + "%";
              },
              title: {
                formatter: function(seriesName) {
                  let name = "Only One";
                  if (seriesName.includes("401(k)_at_work")) {
                    name = "401(K)";
                    if (seriesName.includes("No")) name = "No 401(K)";
                  }
                  if (seriesName.includes("IRA")) {
                    name = "IRA";
                    if (seriesName.includes("No")) name = "No IRA";
                  }
                  if (seriesName.includes("IRA_and_401(k)")) {
                    name = "IRA & 401(K)";
                    if (seriesName.includes("No")) name = "Neither";
                  }
                  if (seriesName.includes("none")) {
                    name = "NONE";
                    if (seriesName.includes("No")) name = "At least one";
                  }

                  return name + " : ";
                },
              },
            },
          },
        };

        // if (dataArr.length == 0) {
        //   dataArr.push({ angle: "100.00", color: "#C2CFE3", name: "None", label: "100" });
        // }

        setSeries(seriesArr);
        setChartData(options);
      },
    }
  );

  useEffect(() => {
    // filterVehicleHandler();
    setState({ ...state, filter: match.params.type, isNo: false });
  }, [match.params.type]);

  useEffect(() => {
    if (!state.filter || !account) return;
    filterVehicleHandler({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: state.search,
        practiceId: account.practiceid,
        filter: state.filter,
        accountType: account.accountType,
        roleCode: account.rolecode,
        portalId: props.currentPortalId,
        accountType: account.accountType,
        roleCode: account.rolecode,
        portalId: props.currentPortalId,
      },
    });
    getAllRetirementVehicle({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: state.search,
        practiceId: account.practiceid,
        filter: state.filter,
        accountType: account.accountType,
        roleCode: account.rolecode,
        portalId: props.currentPortalId,
      },
    });
  }, [state.filter, account]);

  useEffect(() => {
    if (!account) return;
    filterVehicleHandler({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: state.search,
        practiceId: account.practiceid,
        filter: state.filter,
        accountType: account.accountType,
        roleCode: account.rolecode,
        portalId: props.currentPortalId,
      },
    });
  }, [state.isNo, account]);

  // const useWindowSize = () => {
  //   const [size, setSize] = useState([0, 0]);
  //   useLayoutEffect(() => {
  //     function updateSize() {
  //       setSize([window.innerWidth, window.innerHeight]);
  //     }
  //     window.addEventListener("resize", updateSize);
  //     updateSize();
  //     return () => window.removeEventListener("resize", updateSize);
  //   }, []);
  //   return size;
  // };

  function handleSearch(searchParams) {
    let search = _.clone(searchParams);

    setState({ ...state, search: search });
  }

  const switchHandler = (e) => {
    setState({ ...state, isNo: !state.isNo });
  };

  function reCall() {
    // refetch({
    //   page: state.page,
    //   offset: state.offset,
    //   limit: state.limit,
    //   order: state.order,
    //   orderColumn: state.orderColumn,
    //   search: state.search,
    //   filter: state.filter,
    // });
  }

  function handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? `DESC` : `ASC`;

    let x = (pagination.current - 1) * state.limit;
    setState({
      ...state,
      offset: x,
      limit: pagination.pageSize,
      page: pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
      [sorter.columnKey]: sorter.order,
    });
    reCall();
  }
  const columns = [
    {
      title: "Name",
      sorter: false,
      sortOrder: false,
      fixed: true,
      render: (text, row) => {
        return text.firstName + " " + text.lastName;
      },
    },
    {
      dataIndex: "email",
      title: "Email",
      fixed: true,
    },
    {
      dataIndex: "vehicle",
      title: "Retirement Vehicle",
      fixed: true,
    },
    {
      className: "action-column",
      title: "View",
      align: "left",
      fixed: true,
      render: (practice) => {
        return (
          <Link to={`/${currentPractice}/clients/view/${practice.id}`}>
            <i>
              <svg id="img" className="show-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15">
                <path fill="currentColor" fill-rule="evenodd" d="M0 7.5C0 5.25 3.75 0 10.5 0S21 5.25 21 7.5 17.25 15 10.5 15 0 9.75 0 7.5Zm10.5 4.75a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Zm2.7-4.75a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z" />
              </svg>
            </i>
          </Link>
        );
      },
    },
  ];

  let vehicles = _.get(data, "getFilteredVehicles.filteredvehicles", []);
  let progressData = _.get(allData, "getAllRetirementVehicle.percentage", []);

  let labelName = "401(K)";
  let NolabelName = "No 401(K)";
  let otherlabel = "";

  if (location.pathname == "/retirement-vehicle/401(k)_at_work") {
    labelName = "401(K)";
    NolabelName = "No 401(K)";
  }
  if (location.pathname == "/retirement-vehicle/IRA") {
    labelName = "IRA ";
    NolabelName = "No IRA ";
  }
  if (location.pathname == "/retirement-vehicle/IRA_and_401(k)") {
    labelName = "IRA & 401(K) ";
    NolabelName = "Neither";
    otherlabel = "Only One";
  }
  if (location.pathname == "/retirement-vehicle/none") {
    labelName = "NONE ";
    NolabelName = "At least one";
  }

  // let tableCount = _.get(data, "getAllRetirementVehicle.pagination.totalCount", 0);
  // const [width, height] = useWindowSize();
  if (!currentPortalId && !currentPractice) return <Redirect to="/settings/account" strict />;

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <TitleBar title="Retirement Vehicle" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />

        {/* <Titlebar currentPractice={props.currentPractice} displayText="Retirement Vehicle" /> */}
      </div>

      <div className="unitify-vehicle-tab-wrapper">
        <div className="unitify-vehicle-link-data">
          <Link className={location.pathname == "/retirement-vehicle/401(k)_at_work" ? "active" : ""} to={`/retirement-vehicle/401(k)_at_work`}>
            401(K)
          </Link>
        </div>
        <div className="unitify-vehicle-link-data">
          <Link className={location.pathname == "/retirement-vehicle/IRA" ? "active" : ""} to={`/retirement-vehicle/IRA`}>
            IRA
          </Link>
        </div>
        <div className="unitify-vehicle-link-data">
          <Link className={location.pathname == "/retirement-vehicle/IRA_and_401(k)" ? "active" : ""} to={`/retirement-vehicle/IRA_and_401(k)`}>
            IRA & 401(K)
          </Link>
        </div>
        <div className="unitify-vehicle-link-data">
          <Link className={location.pathname == "/retirement-vehicle/none" ? "active" : ""} to={`/retirement-vehicle/none`}>
            NONE
          </Link>
        </div>
      </div>

      <div className="unitify-content-box-wrapper">
        <Row gutter={24}>
          <Col lg={16} className="gutter-row">
            <LayoutContent>
            <div className="unitify-vehicle-main-wrapper">
              <div className="unitify-vehicle-data-wrapper">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h4>Retirement Vehicle</h4>
                  {location.pathname != "/retirement-vehicle/none" && <FormCheck type="switch" id="custom-switch" label={`No ${labelName}`} onChange={switchHandler} value="0" checked={state.isNo} />}
                </div>
                <Skeleton loading={loading} active avatar>
                  {loading ? null : <Table columns={columns} loading={loading} onChange={handleTableChange} dataSource={vehicles} rowKey={(record) => record.id} scroll={{ x: columns.length * 150 }} pagination={false} bordered />}
                </Skeleton>
              </div>
            </div>
            </LayoutContent>
          </Col>
          <Col lg={8} className="gutter-row">
            <LayoutContent>
              <div className="unitify-vehicle-chart-wrapper">
                <h6>{labelName} PERCENT BREAKDOWN</h6>
                {chartData && <Chart options={chartData} series={series} width="100%" height={230} type="pie" />}

                {/* <SizeMe>
                  {({ size }) => (
                    <RadialChart
                      getLabel={(d) => `${d.label}%`}
                      data={chartData}
                      labelsStyle={{ fontSize: 12, fill: "#fff", dominantBaseline: "middle", textAnchor: "middle", fontWeight: "bold" }}
                      labelsRadiusMultiplier={0.9}
                      padAngle={6}
                      width={size.width}
                      height={200}
                      // className="mx-auto"
                      colorType="literal"
                      showLabels={true}
                    />
                  )}
                </SizeMe> */}
                {/* <RadialChart getLabel={(d) => d.label} data={chartData} labelsRadiusMultiplier={1.6} labelsStyle={{ fontSize: 16, fill: "#222" }} width={200} height={200} className="mx-auto" /> */}
                <div className="d-flex justify-content-around pb-3">
                  <div className="d-flex align-items-center">
                    <div className="unitify-cmn-circle me-2"></div>
                    <span>{labelName}</span>
                  </div>
                  {otherlabel !== "" && (
                    <div className="d-flex align-items-center">
                      <div className="unitify-cmn-circle me-2 status-circle"></div>
                      <span>{otherlabel}</span>
                    </div>
                  )}

                  <div className="d-flex align-items-center">
                    <div className="unitify-cmn-circle status-bg me-2"></div>
                    <span>{NolabelName}</span>
                  </div>
                </div>
              </div>
            </LayoutContent>
            <LayoutContent>
              <div className="unitify-vehicle-status-wrapper">
                <Titlebar displayText="Retirement Vehicle" />
                <div className="unitify-vehicle-content-wrapper">
                  {progressData &&
                    Object.entries(progressData).map(([key, value]) => {
                      return (
                        (key == "401(k)_at_work" || key == "IRA" || key == "none" || key == "IRA_and_401(k)" || key == "Other_Retirement_Account") && (
                          <div className="d-flex mb-3">
                            <span className="unitify-cmn-title">
                              {key == "401(k)_at_work" && "401(k)"}
                              {key == "IRA" && "IRA"}
                              {key == "none" && "None"}
                              {key == "IRA_and_401(k)" && "IRA & 401(K)"}
                              {key == "Other_Retirement_Account" && "Other Retirement Vehicle"}
                            </span>
                            <Progress percent={value} status="active" />
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </LayoutContent>
          </Col>
        </Row>
      </div>

      {/* <LayoutContentWrapper> */}
      {/* <div className="display-bar">{display}</div> */}
      {/* <LayoutContent>
          <RadialChart getLabel={(d) => d.label} data={chartData} labelsRadiusMultiplier={1.6} labelsStyle={{ fontSize: 16, fill: "#222" }} width={200} height={200} />
        </LayoutContent> */}
      {/* <LayoutContent>
          <Titlebar displayText="Retirement Vehicle" />
          {progressData &&
            Object.entries(progressData).map(([key, value]) => {
              return (
                <>
                  {key == "401(k)_at_work" && "401(k)"}
                  {key == "IRA" && "IRA"}
                  {key == "none" && "None"}
                  {key == "IRA_and_401(k)" && "IRA & 401(K)"}
                  <Progress percent={value} />
                </>
              );
            })}
        </LayoutContent> */}
      {/* </LayoutContentWrapper> */}
    </div>
  );
};

export default RetirementVehicle;
