import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { Badge, Icon, Spin } from "antd";
import { ButtonBar, Titlebar } from "../../../components";
import GenerateBadge from "../../../helpers/generateBadge";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import UserViewWrapper from "./index.style";
import moment from "moment";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";

class UserView extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      me,
      data: { loading, error, getUser },
    } = this.props;

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - user didn&apos;t load.</p>
        </div>
      );
    }

    /* set-up dynamic button bar & routes */
    const menuKeys = ["advisors", "clients", "practices", "users"];
    const path = this.props.location.pathname.split("/");
    const topLevelMenu = menuKeys.includes(this.props.currentPractice);
    const pathRoute = topLevelMenu ? path[1] : path[2];
    const pathname = topLevelMenu ? `/${pathRoute}/edit/${getUser.id}` : `/${this.props.currentPractice}/${pathRoute}/edit/${getUser.id}`;
    const redirect = topLevelMenu ? `/${pathRoute}/view/${getUser.id}` : `/${this.props.currentPractice}/${pathRoute}/view/${getUser.id}`;

    const userContext = this.context;
    const { account } = userContext;

    return (
      <UserViewWrapper>
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            {/* <Titlebar currentPractice={this.props.currentPractice} displayText={`${getUser.firstName} ${getUser.lastName}`} path={path} viewMode /> */}
            <TitleBar title={`${getUser.firstName} ${getUser.lastName}`} subTitle={account && me && (me.rolecode == "CMPYO" || me.rolecode == "PRCTO" ? `${account.portalname} | ${getUser.practicename}` : `${account.portalname}`)} />
            {me && (me.permissions.includes("editadviser") || me.id == getUser.id) ? (
              <div className="button-bar-wrapper">
                <ButtonBar
                  icon="edit"
                  link={{
                    pathname,
                    state: { redirect },
                  }}
                  text={`Edit ${pathRoute.replace(/s\s*$/, "")}`}
                />
              </div>
            ) : null}
          </div>
          <LayoutContentWrapper>
            <LayoutContent>
              {pathRoute === "users" ? (
                <div className="row-wrapper">
                  <div className="row-label">Role</div>
                  <div>{getUser.role}</div>
                </div>
              ) : null}
              <div className="row-wrapper">
                <div className="row-label">Email</div>
                <div>{getUser.email ? getUser.email : null}</div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Phone Number</div>
                <div>{getUser.phoneNumber ? getUser.phoneNumber : null}</div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Address</div>
                <div>
                  <div>{getUser.address ? getUser.address : null}</div>
                  <div>{getUser.city && getUser.state && getUser.zipCode ? `${getUser.city}, ${getUser.state} ${getUser.zipCode}` : null}</div>
                </div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Status</div>
                <div>
                  <Badge status={GenerateBadge(getUser.status)} text={getUser.status} />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Receive Assessment Notification</div>
                <div>
                  <Badge status={GenerateBadge(getUser.assessmentNotification ? "ACTIVE" : "")} text={getUser.assessmentNotification ? "YES" : "NO"} />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Last Login</div>
                <div>{getUser.lastLogin ? moment(getUser.lastLogin).format("MMM D YYYY h:mm a") : null}</div>
              </div>
            </LayoutContent>
          </LayoutContentWrapper>
        </div>
      </UserViewWrapper>
    );
  }
}

const getUserQuery = gql`
  query($id: Int!) {
    getUser(id: $id) {
      id
      address
      city
      email
      firstName
      lastLogin
      lastName
      phoneNumber
      practiceId
      practicename
      role
      state
      status
      zipCode
      assessmentNotification
    }
  }
`;

export default graphql(getUserQuery, {
  options: (props) => ({
    fetchPolicy: "network-only",
    variables: {
      id: Number(props.match.params.id),
    },
  }),
})(UserView);
