import React, { useEffect, useState } from "react";
import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, message, Icon, InputNumber, notification } from "antd";
import { Modal } from "react-bootstrap";
import { graphql, useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import Select from "react-select";
import { FormWrapper } from "../form";
import { Link } from "react-router-dom";
import whitelogo from "../../assets/img/unitifi.svg";
import { phoneMsg, phoneNumberCheck } from "../../helpers/formatters";
const InputGroup = Input.Group;

const ContactForm = (props) => {
  const { planData, form, mutate, history, location, user, openContactModel, setOpenContactModel } = props;
  const { getFieldProps, getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [Countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("+1");

  const { loading: countryLoading, error: countryError, data: countryData } = useQuery(
    gql`
      query {
        getCountry {
          countries {
            id
            name
            dial_code
          }
        }
      }
    `
  );
  const [contactSave, { data: contactData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation($email: String!, $firstName: String, $lastName: String, $teamSize: Int, $phoneNumber: String, $phoneCode: String, $planType: String) {
      bulkContactPlan(email: $email, firstName: $firstName, lastName: $lastName, teamSize: $teamSize, phoneNumber: $phoneNumber, phoneCode: $phoneCode, planType: $planType) {
        ok
        message
        errors {
          message
          path
        }
      }
    }
  `);

  useEffect(() => {
    if (!contactData) return;
    if (contactData.bulkContactPlan.errors) {
      contactData.bulkContactPlan.errors.forEach((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
    }
  }, [errorSubmit, contactData]);

  useEffect(() => {
    if (!countryData) return;
    let result = countryData.getCountry.countries;

    let countArr = [];
    for (let res of result) {
      if (res.name != "Canada") {
        let obj = {
          label: res.dial_code,
          id: res.dial_code,
        };
        countArr.push(obj);
      }
    }
    setCountries(countArr);
  }, [countryData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (values.p1 == undefined) {
        form.setFields({
          phoneNumber: {
            value: values.phoneNumber,
            errors: [new Error("Phone is required")],
          },
        });
        return;
      }
      if (values.p1.length != 10) {
        form.setFields({
          phoneNumber: {
            value: values.phoneNumber,
            errors: [new Error("Please enter valid phone number.")],
          },
        });
        return;
      }
      let phoneNumber = values.p1 + values.p2 + values.p3;

      if (phoneNumberCheck(phoneNumber)) {
        form.setFields({
          phoneNumber: {
            value: values.phoneNumber,
            errors: [new Error(phoneMsg)],
          },
        });
        return;
      }
      if (!err) {
        setLoading(true);

        // if (!values.phoneCode) {
        //   values.phoneCode = "+1";
        // } else {
        //   values.phoneCode = values.phoneCode.id;
        // }
        values.phoneCode = countryCode;
        values.teamSize = parseInt(values.teamSize);

        values.phoneNumber = values.p1;
        // delete values.phoneNumber;
        delete values.p1;
        delete values.p2;
        delete values.p3;

        values.planType = planData.plan_type == "2" ? "1" : "2";
        contactSave({ variables: values });
      }
      setLoading(false);
    });
  };

  const anyValidation = (rule, value, callback) => {
    if (value == "") {
      callback();
    } else if (value <= 0) {
      callback("Please provide a valid Team Size.");
    } else {
      callback();
    }
  };

  const phondeCodeHandler = (val) => {
    setCountryCode(val.id);
  };

  const autoFocusPhone = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");

    if (e.key == "Shift" || e.key == "Tab") {
      document.getElementById(c).focus();
      return;
    }
    if (length == maxLength) {
      if (n != "") {
        document.getElementById(n).focus();
      }
    }
  };

  return (
    <Modal show={openContactModel} onHide={() => setOpenContactModel(false)} centered className="unitifi-modal-wrapper contactForm" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(!contactData || contactData.bulkContactPlan.ok == false) && user ? (
          <>
            <div className="unitifi-modal-header-wrapper">
              <Link to="/" className="unitifi-logo">
                <img src={whitelogo} />
              </Link>
              <h2 className="unitifi-modal-title-text">Please fill out contact form</h2>
            </div>
            <FormWrapper className="public-form">
              <Form layout="vertical" onSubmit={handleSubmit}>
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      hasFeedback
                      label={"First Name"}
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        marginBottom: "10px",
                      }}>
                      {getFieldDecorator("firstName", {
                        initialValue: user.firstName,
                        rules: [
                          {
                            required: true,
                            message: "First Name is required",
                          },
                        ],
                      })(<Input placeholder="First Name" type={"text"} />)}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item hasFeedback label={"Last Name"} style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                      {getFieldDecorator("lastName", {
                        initialValue: user.lastName,
                        rules: [
                          {
                            required: true,
                            message: "Last Name is required",
                          },
                        ],
                      })(<Input placeholder="Last Name" type={"text"} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      hasFeedback
                      label={"Email"}
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        marginBottom: "10px",
                      }}>
                      {getFieldDecorator("email", {
                        initialValue: user.email,
                        rules: [
                          {
                            required: true,
                            message: "Email is required!",
                          },
                        ],
                      })(<Input placeholder="Email" type={"email"} />)}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item className="input-phone__wrapper" label="Phone Number">
                      <InputGroup compact className="unitifi-number-inputbox-wrapper unitifi-phone-input-box-wrapper d-flex align-items-center">
                        <Select
                          options={Countries}
                          defaultValue={{ label: "+1", value: "+1" }}
                          onKeyDown={(event) => {
                            if (
                              event.key == "Backspace" ||
                              event.key == "Delete" ||
                              event.key == "ArrowLeft" ||
                              event.key == "ArrowRight" ||
                              event.key == "Meta" ||
                              event.key == "ArrowUp" ||
                              event.key == "ArrowDown" ||
                              event.key == "Enter" ||
                              event.key == "Tab"
                            ) {
                            } else if (!/[0-9+]/.test(event.key)) event.preventDefault();
                          }}
                          onChange={(val) => phondeCodeHandler(val)}
                          className="unitifi-select-container phoneInputBox me-3"
                          classNamePrefix="unitifi-react-select"
                        />
                        {getFieldDecorator("phoneNumber", {
                          rules: [
                            {
                              required: true,
                              message: "Phone is required",
                            },
                          ],
                        })(
                          <div className="number-inputbox phoneInputData m-0 w-100">
                            <Row gutter={0}>
                              <Col className="gutter-row" span={24}>
                                {getFieldDecorator("p1", {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Phone is required",
                                    },
                                  ],
                                })(
                                  <Input
                                    size={"large"}
                                    maxLength={10}
                                    placeholder=""
                                    className=""
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    // onKeyUp={(event) => {
                                    //   autoFocusPhone(event, "", "p1", "p2");
                                    // }}
                                  />
                                )}
                              </Col>
                              {/* <Col className="gutter-row" span={8}>
                                {getFieldDecorator("p2", {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Phone is required",
                                    },
                                  ],
                                })(
                                  <Input
                                    size={"large"}
                                    maxLength={3}
                                    placeholder="000"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onKeyUp={(event) => {
                                      autoFocusPhone(event, "p1", "p2", "p3");
                                    }}
                                  />
                                )}
                              </Col> */}
                              {/* <Col className="gutter-row" span={8}>
                                {getFieldDecorator("p3", {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Phone is required",
                                    },
                                  ],
                                })(
                                  <Input
                                    size={"large"}
                                    maxLength={4}
                                    placeholder="0000"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onKeyUp={(event) => {
                                      autoFocusPhone(event, "p2", "p3", "p3");
                                    }}
                                  />
                                )}
                              </Col> */}
                            </Row>
                          </div>
                        )}
                      </InputGroup>
                    </Form.Item>
                  </Col>

                  {/* <Col className="gutter-row" span={12}>
                    <InputGroup compact className="phone-number-wrapper">
                      <Form.Item hasFeedback label={"Phone Number"} style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                        {getFieldDecorator("phoneNumber", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: "Phone Number is required!",
                            },
                          ],
                        })(
                          <div className="mobile-number-input-wrapper">
                            {getFieldDecorator("phoneCode", {})(<Select options={Countries} defaultValue={{ label: "+1", value: "+1" }} className="unitifi-select-container" classNamePrefix="unitifi-react-select" />)}
                            <Input placeholder="Phone Number" type={"text"} />
                          </div>
                        )}
                      </Form.Item>
                    </InputGroup>
                  </Col> */}
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      hasFeedback
                      label={"Estimated Team Size"}
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        marginBottom: "10px",
                      }}>
                      {getFieldDecorator("teamSize", {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "Estimated Team Size is required!",
                          },
                          {
                            validator: anyValidation,
                          },
                        ],
                      })(<Input placeholder="Estimated Team Size" type="number" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <div className="modal-footer-bnts-wrapper">
                  <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn" disabled={loadingSubmit}>
                    {loadingSubmit ? <Icon type="loading" /> : "Submit"}
                  </Button>
                </div>
              </Form>
            </FormWrapper>
          </>
        ) : (
          <>
            {contactData && contactData.bulkContactPlan.ok && (
              <>
                <div className="thank-you-for-modal-wrapper">
                  <div className="unitifi-modal-header-wrapper">
                    <Link to="/" className="unitifi-logo">
                      <img src={whitelogo} />
                    </Link>
                    <h2 className="unitifi-modal-title-text"> {contactData.bulkContactPlan.ok ? contactData.bulkContactPlan.message : contactData.bulkContactPlan.error}</h2>
                  </div>
                  <Link to="/" className="goto-link-home">
                    Go To Unitifi Home Page
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Form.create()(ContactForm);
