import React from "react";
import { Redirect } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Collapse, Col, Row } from "antd";
import { PassionLogicMap, SelfReportedMap, Titlebar } from "../../../components";
import logo from "../../../assets/img/unitifi.svg";
import areteLogo from "../../../assets/img/arete-logo.png";
import bernsteinLogo from "../../../assets/img/bernstein-logo.png";
import ClientPrintWrapper from "./index.style";
import ClientViewWrapper from "../compareView/index.style";
import moment from "moment";
import PassionLogicMapMultiple from "../../../components/passionLogicMap/PassionLogicMapMultiple";
import SelfReportedMapMultiple from "../../../components/selfReportedMap/selfReportedMapMultiple";
import { personalityTypeImg } from "../../../helpers/formatters";
import emailIcon from "../../../assets/img/icon-envelope.svg";
import phoneIcon from "../../../assets/img/icon-telephone.svg";
import emailIcon1 from "../../../assets/img/envelope-icon-yellow.svg";
import phoneIcon1 from "../../../assets/img/telephone-icon-yellow.svg";

const GenerateReportType = (type) => {
  if (type === "snapshot") {
    return "Snapshot";
  }

  if (type === "401k") {
    return "401(k)";
  }

  return null;
};

const { Panel } = Collapse;

const customPanelStyle = {
  border: 0,
  borderRadius: 4,
  overflow: "hidden",
};

const customHeaderStyle = {
  color: "rgba(0,0,0,.65)",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "20px",
};

class ClientComparePrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      data: { loading, error, viewClientCompare = null },
    } = this.props;

    if (loading || error) {
      return null;
    }

    if (!viewClientCompare) {
      return <Redirect to="/" />;
    }

    const { clients, extra } = viewClientCompare;
    const { firstName, insights, lastName, practiceName, dateCollected, sitelogo, email, phonenumber } = clients[0];
    const headerLogo = (practice) => {
      if (!practice) {
        return logo;
      }

      if (practice === "Areté Partners LLC") {
        return areteLogo;
      }

      if (practice === "Bernstein Financial Advisory") {
        return bernsteinLogo;
      }

      return logo;
    };

    const logoStyle = practiceName === "Bernstein Financial Advisory" ? { height: "64px" } : { width: "100px" };

    if (!insights) {
      return (
        <ClientPrintWrapper>
          <div>
            <div
              style={{
                alignItems: "center",
                borderBottom: "1px solid rgba(238,242,244,1)",
                display: "flex",
                justifyContent: "space-between",
                padding: "16px 24px",
              }}>
              <div
                style={{
                  color: "rgba(0,0,0,.85)",
                  fontSize: "20px",
                }}>
                Unitifi Consumer Insight Tool - UCIT
              </div>
              <div>
                <img alt="logo" src={sitelogo ? sitelogo : logo} style={logoStyle} />
              </div>
            </div>
          </div>
        </ClientPrintWrapper>
      );
    }

    return (
      <ClientPrintWrapper>
        <div>
          <div
            style={{
              alignItems: "center",
              borderBottom: "1px solid rgba(238,242,244,1)",
              display: "flex",
              justifyContent: "space-between",
              padding: "16px 24px",
            }}>
            <div
              style={{
                color: "rgba(0,0,0,.85)",
                fontSize: "20px",
              }}>
              Unitifi Consumer Insight Tool - UCIT
              <div
                style={{
                  color: "rgba(0,0,0,.65)",
                  fontSize: "12px",
                  lineHeight: "12px",
                }}>
                {this.props.match.params && this.props.match.params.type ? <i>{GenerateReportType(this.props.match.params.type)}</i> : null}
              </div>
            </div>
            <div>
              <img alt="logo" src={sitelogo ? sitelogo : logo} style={logoStyle} />
            </div>
          </div>

          <h6
            style={{
              margin: "20px 24px 0",
              fontWeight: "600",
            }}>
            Client Comparison
          </h6>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                margin: "20px 24px 0",
                fontWeight: "600",
                display: "flex",
                flexDirection: "column",
                maxWidth: "650px",
                width: "100%",
              }}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <h6 style={{ color: "#006fba", fontWeight: "600", marginBottom: "0" }} className="clientTitle">
                  {firstName} {lastName}
                </h6>
                <div style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                  <h6 style={{ marginBottom: "0", marginRight: "10px" }}>
                    <a href={`mailto:${email}`} style={{ fontSize: "14px", color: "#006fba", textDecoration: "none" }}>
                      <img src={emailIcon} className="me-2" />
                      {email}
                    </a>
                  </h6>
                  <span className="cmn-title-text">
                    <a href={`${phonenumber ? `tel:${phonenumber}` : "#"}`} style={{ fontSize: "14px", color: "#006fba", textDecoration: "none" }}>
                      <img src={phoneIcon} className="me-2" />
                      {phonenumber ? phonenumber : "N/A"}
                    </a>
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ color: "#cb9120", fontWeight: "600", marginBottom: "0" }} className="clientTitle secondClient">
                  {clients[1].firstName} {clients[1].lastName}
                </h6>

                <div style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                  <h6 style={{ marginBottom: "0", marginRight: "10px" }}>
                    <a href={`mailto:${email}`} style={{ fontSize: "14px", color: "#cb9120", textDecoration: "none" }}>
                      <img src={emailIcon1} className="me-2" />
                      {clients[1].email}
                    </a>
                  </h6>

                  <span className="cmn-title-text">
                    <a href={`${clients[1].phonenumber ? `tel:${clients[1].phonenumber}` : "#"}`} style={{ fontSize: "14px", color: "#cb9120", textDecoration: "none" }}>
                      <img src={phoneIcon1} className="me-2" />
                      {clients[1].phonenumber ? clients[1].phonenumber : "N/A"}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {/* comment */}
            {/* <div style={{ textAlign: "right" }}>
              {practiceName}
              <div>{moment(dateCollected).format("M/D/YYYY")}</div>
            </div> */}
          </div>
          {/* <Titlebar currentPractice={this.props.currentPractice} displayText={`${firstName} ${lastName}`} viewClient={viewClient} forPrint /> */}

          <ClientViewWrapper style={{ padding: "24px 0 0" }}>
            <div className="main-chart-wrapper">
              {/* left bar / 3 divs */}
              <div className="left-col">
                <div className="item">
                  <div className="heading">Scores</div>
                  <div className="details-wrapper d-flex align-items-center justify-content-around">
                    <div className="score-item-wrapper">
                      <div style={{ marginLeft: "7px", flexDirection: "column" }} className="score-item">
                        <div style={{ fontSize: "20px", textAlign: "center" }} className="risk-behavior-score">
                          {insights.score.riskBehaviorScore}
                        </div>
                        <div style={{ fontSize: "15px" }} className="scoreLabel">
                          Science Reported
                        </div>
                      </div>
                      <div style={{ width: "130px" }} className="item-divider"></div>
                      <div className="score-item" style={{ flexDirection: "column" }}>
                        <div style={{ fontSize: "20px", textAlign: "center" }} className="selfReportedScore">
                          {insights.score.selfReportedScore}
                        </div>
                        <div style={{ fontSize: "15px" }} className="scoreLabel">
                          Self-Reported
                        </div>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div className="score-item-wrapper" style={{ flexDirection: "column" }}>
                      <div style={{ marginLeft: "7px", flexDirection: "column" }} className="score-item">
                        <div style={{ fontSize: "20px", textAlign: "center" }} className="risk-behavior-score compareScore">
                          {clients[1].insights.score.riskBehaviorScore}
                        </div>
                        <div style={{ fontSize: "15px" }} className="scoreLabel">
                          Science Reported
                        </div>
                      </div>
                      <div style={{ width: "130px" }} className="item-divider"></div>
                      <div className="score-item" style={{ flexDirection: "column" }}>
                        <div style={{ fontSize: "20px", textAlign: "center" }} className="selfReportedScore compareReport">
                          {clients[1].insights.score.selfReportedScore}
                        </div>
                        <div style={{ fontSize: "15px" }} className="scoreLabel">
                          Self-Reported
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="heading">Financial Personality</div>
                  <div className="d-flex align-items-center justify-content-around">
                    <div className="details-wrapper" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                      {/* <div className="label">{insights.personality.behavior}</div> */}
                      <div style={{ fontSize: "20px" }} className="calloutLbl">
                        {insights.personality.type}
                        {/* <i className="ms-1">
                          <img src={personalityTypeImg[insights.personality.type]}  />
                        </i> */}
                      </div>
                    </div>
                    <div className="details-wrapper" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                      {/* <div className="label">{clients[1].insights.personality.behavior}</div> */}
                      <div style={{ fontSize: "20px" }} className="calloutLbl compareLbl">
                        {clients[1].insights.personality.type}
                        {/* <i className="ms-1">
                            <img src={personalityTypeImg[clients[1].insights.personality.type]}  />
                          </i> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="heading">Decision Making Style</div>
                  <div className="details-wrapper">
                    <PassionLogicMapMultiple data={[insights, clients[1].insights]} firstName={firstName} forPrint />
                  </div>
                </div>
              </div>

              {/* chart */}
              <div className="right-col">
                <div className="chart-legend">
                  <div style={{ alignItems: "center", display: "flex", marginRight: "24px" }}>
                    <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(0,111,186,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                    <div>Science Reported</div>
                  </div>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(255,255,255,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                    <div>Self-Reported</div>
                  </div>
                </div>

                <div className="chart-wrapper" style={{ marginTop: "62px", height: "calc(100% - 112px)" }}>
                  <SelfReportedMapMultiple data={[insights, clients[1].insights]} forPrint />
                </div>
              </div>
            </div>

            <div className="main-wrapper content-block">
              <div className="wrapper">
                <div className="heading">Communication Center</div>
                <div className="item-wrapper-flex">
                  <div className="item-flex justify-content-center">
                    <div style={{ fontSize: "20px" }} className="item-flex-heading clientTitle">
                      {firstName} {lastName}
                    </div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Expectation</div>
                    <div>{insights.profile.communicationExpectation}</div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Age</div>
                    <div>{new Date().getFullYear() - insights.retirement.birthYear}</div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Retirement Year</div>
                    <div>{insights.retirement.retirementYear}</div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Retirement Vehicle</div>
                    <div>{insights.profile.currentRetirementVehicle}</div>
                  </div>
                </div>
                <div className="item-wrapper-flex">
                  <div className="item-flex justify-content-center">
                    <div style={{ fontSize: "20px" }} className="item-flex-heading secondClient">
                      {clients[1].firstName} {clients[1].lastName}
                    </div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Expectation</div>
                    <div>{clients[1].insights.profile.communicationExpectation}</div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Age</div>
                    <div>{new Date().getFullYear() - clients[1].insights.retirement.birthYear}</div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Retirement Year</div>
                    <div>{clients[1].insights.retirement.retirementYear}</div>
                  </div>
                  <div className="item-flex">
                    <div className="item-flex-heading">Retirement Vehicle</div>
                    <div>{clients[1].insights.profile.currentRetirementVehicle}</div>
                  </div>
                </div>
              </div>
            </div>

            {/* do not show any extras on 401k */}
            {this.props.match.params && this.props.match.params.type === "401k" && (
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "12px 24px 12px",
                }}>
                Notes
              </div>
            )}

            {/* do not show Behavior Intelligence Rating on snapshot or 401k  */}
            {this.props.match.params && this.props.match.params.type !== "snapshot" && !["401k", "snapshot"].includes(this.props.match.params.type) && (
              <div className="main-wrapper content-block">
                <div className="wrapper">
                  <div className="heading">Financial Personality Defined:</div>
                  <div className="d-flex align-items-start justify-content-start flex-wrap flex-md-nowrap">
                    <div className="item-wrapper">
                      <h6 className="clientTitle">
                        {firstName} {lastName} - {insights.personality.type}
                      </h6>
                      <div className="item has-below">
                        <div className="item-heading">Behaviors of this Investor</div>
                        <div>
                          <ul>
                            {insights.personality.attributes.investorBehaviors.map((i) => (
                              <li key={i}>{i}</li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {this.props.match.params && this.props.match.params.type !== "ucitc" && (
                        <div className="item">
                          <div className="item-heading">Tips for the Financial Professional</div>
                          <div>
                            <ul>
                              {insights.personality.attributes.advisorTips.map((i) => (
                                <li key={i}>{i}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        border: "1px solid #f0f2f5",
                      }}></div>

                    <div className="item-wrapper">
                      <h6 className="clientTitle secondClient">
                        {clients[1].firstName} {clients[1].lastName} - {clients[1].insights.personality.type}
                      </h6>
                      <div className="item has-below">
                        <div className="item-heading">Behaviors of this Investor</div>
                        <div>
                          <ul>
                            {clients[1].insights.personality.attributes.investorBehaviors.map((i) => (
                              <li key={i}>{i}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="item-heading">Tips for the Financial Professional</div>
                        <div>
                          <ul>
                            {clients[1].insights.personality.attributes.advisorTips.map((i) => (
                              <li key={i}>{i}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.props.match.params && this.props.match.params.type !== "401k" && (
              <div className="main-wrapper content-block">
                <div>
                  <div className="heading">Behavior Attributes Recognized</div>

                  <div className="item-wrapper">
                    <div className="attribute-text">
                      The following tiles identify shared behavior attributes recognized for clients selected. <div className="circle"></div> indicates strongly identified attribute, while <div className="circle min"></div> indicates an identified
                      attribute.
                    </div>

                    {this.props.match.params && this.props.match.params.type === "snapshot" ? (
                      <div style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}>
                        {extra.length > 0 &&
                          extra.map((t) => (
                            <div className="snapshot-behavior" key={t.name}>
                              <div
                                style={
                                  t.type === "Strongly Identified"
                                    ? {
                                        background: "rgba(0,111,186,1)",
                                        borderRadius: "100%",
                                        height: "16px",
                                        marginRight: "8px",
                                        width: "16px",
                                      }
                                    : {
                                        background: "#6fb6e0",
                                        borderRadius: "100%",
                                        height: "16px",
                                        marginRight: "8px",
                                        width: "16px",
                                      }
                                }></div>
                              <div
                                style={{
                                  color: "rgba(0, 0, 0, 0.65)",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}>
                                {t.name}
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <Collapse activeKey={extra.length > 0 && extra.map((t) => t.name)} bordered={false}>
                        {/*  defaultActiveKey={insights.tendencies.slice(0, 1).map(t => t.name)} */}
                        {extra.length > 0 &&
                          extra.map((t) => (
                            <Panel
                              className="content-block"
                              header={
                                <div style={{ alignItems: "center", display: "flex", paddingBottom: "4px" }}>
                                  <div
                                    style={
                                      t.type === "Strongly Identified"
                                        ? {
                                            background: "rgba(0,111,186,1)",
                                            borderRadius: "100%",
                                            height: "16px",
                                            marginRight: "8px",
                                            width: "16px",
                                          }
                                        : {
                                            background: "#6fb6e0",
                                            borderRadius: "100%",
                                            height: "16px",
                                            marginRight: "8px",
                                            width: "16px",
                                          }
                                    }></div>
                                  <div style={customHeaderStyle}>{t.name}</div>
                                </div>
                              }
                              key={t.name}
                              showArrow={false}
                              style={customPanelStyle}>
                              <div style={{ marginLeft: "28px" }}>
                                <div style={{ marginBottom: "12px" }}>
                                  <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Characteristics</div>
                                  <ul>
                                    {t.characteristics.map((c) => (
                                      <li key={c}>{c}</li>
                                    ))}
                                  </ul>
                                </div>

                                <div style={this.props.match.params && this.props.match.params.type !== "ucitc" ? { marginBottom: "24px" } : null}>
                                  <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Results</div>
                                  <ul>
                                    {t.results.map((c) => (
                                      <li key={c}>{c}</li>
                                    ))}
                                  </ul>
                                </div>

                                {this.props.match.params && this.props.match.params.type !== "ucitc" && (
                                  <div>
                                    <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Recommendations</div>
                                    <ul>
                                      {t.recommendations.map((c) => (
                                        <li key={c}>{c}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </Panel>
                          ))}
                      </Collapse>
                    )}
                  </div>
                </div>
              </div>
            )}

            <footer style={{ left: "0", right: "0", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 24px" }}>
              <div>{`${moment().format("MM/DD/YYYY")}`}</div>
              <div>{`${practiceName}`}</div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ marginRight: "8px" }}>Powered by</div>
                <div>
                  <img alt="logo" src={logo} style={{ height: "20px" }} />
                </div>
              </div>
            </footer>
          </ClientViewWrapper>
        </div>
      </ClientPrintWrapper>
    );
  }
}

const viewClientQuery = gql`
  query($id: Int!, $practiceId: Int, $clientId: Int) {
    viewClientCompare(id: $id, practiceId: $practiceId, clientId: $clientId) {
      clients
      extra
    }
  }
`;

export default graphql(viewClientQuery, {
  options: (props) => ({
    fetchPolicy: "network-only",
    variables: {
      id: Number(props.match.params.id),
      clientId: Number(props.match.params.clientId),
      practiceId: Number(props.match.params.practiceId),
    },
  }),
})(ClientComparePrint);
