import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Badge, Icon, Modal, notification, Spin } from "antd";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import GenerateBadge from "../../../helpers/generateBadge";
import PracticeViewWrapper from "./index.style";
import CustomButton from "../../../components/commons/CustomButton";
import { AccountContext } from "../../../context/AccountContext";
import TitlebarWrapper from "../../../components/titlebar/index.style";
import TitleBar from "../../../components/titlebar/titlebar";

class PracticesView extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirectTo: null,
    };
    this.toggleLoading = this.toggleLoading.bind(this);
    this.userContext = null;
  }

  toggleLoading(boolean) {
    this.setState({
      loading: boolean,
    });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  componentDidUpdate() {
    this.userContext = this.context;
  }

  render() {
    const {
      data: { loading, error, getPractice },
    } = this.props;

    const userContext = this.context;
    const { account } = userContext;

    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice="" displayText="" />
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice="" displayText="" />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - practice didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <PracticeViewWrapper>
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            {/* <Titlebar currentPractice={"Ankur"} displayText="View Practice" /> */}
            <TitleBar title={getPractice.name} subTitle={account && (account.accountType == 1 ? account.portalname : `${getPractice && getPractice.name} | ${account.portalname}`)} />
            {/* <TitlebarWrapper>
              <div className="title-bar-wrapper">
                <div className="title-wrapper">
                  <div className="title-text">View Practice</div>
                  <div className="sub-title-text">{getPractice.name}</div>
                </div>
              </div>
            </TitlebarWrapper> */}
            {this.userContext && this.userContext.account && this.userContext.account.permissions.includes("editpractice") && (
              <CustomButton
                rights={["superAdmin", "editpractice"]}
                icon="edit"
                href={`/practices/edit/${this.props.currentPortalId || this.props.match.params.poid}/${this.props.match.params.id}`}
                text="Edit Practice"
                type={"primary"}
                // disabled={props.location.pathname === '/practices'}
              >
                Edit Practice
              </CustomButton>
            )}
          </div>

          <LayoutContentWrapper>
            <LayoutContent>
              <div className="row-wrapper">
                <div className="row-label">Practice Owner/Admin</div>
                <div>{getPractice.ownername}</div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Email</div>
                <div>{getPractice.primaryContactEmail ? getPractice.primaryContactEmail : null}</div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Address</div>
                <div>
                  <div>{getPractice.primaryContactAddress ? getPractice.primaryContactAddress : null}</div>
                  <div>
                    {getPractice.primaryContactCity && getPractice.primaryContactState && getPractice.primaryContactZipCode ? `${getPractice.primaryContactCity}, ${getPractice.primaryContactState} ${getPractice.primaryContactZipCode}` : null}
                  </div>
                </div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Status</div>
                <div>
                  <Badge status={GenerateBadge(getPractice.status)} text={getPractice.status} />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Receive Assessment Notification</div>
                <div>
                  <Badge status={GenerateBadge(getPractice.assessmentNotification ? "ACTIVE" : "")} text={getPractice.assessmentNotification ? "Active" : "Inactive"} />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="row-label">Last Login</div>
                <div>{getPractice.lastLogin}</div>
              </div>
            </LayoutContent>
          </LayoutContentWrapper>
        </div>
      </PracticeViewWrapper>
    );
  }
}

PracticesView.propTypes = {
  viewPractice: PropTypes.func.isRequired,
};

const getPracticeQuery = gql`
  query($id: Int!) {
    getPractice(id: $id) {
      id
      name
      notes
      practiceId
      siteLogo
      siteLogoS3Token
      status
      email
      primaryContactAddress
      primaryContactZipCode
      primaryContactCity
      primaryContactCountry
      primaryContactState
      primaryContactSuite
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      assessmentNotification
      lastLogin
      ownername
    }
  }
`;

const viewPracticeMutation = gql`
  mutation viewPractice(
    $id: Int!
    $name: String
    $notes: String
    $practiceId: String
    $portalId: Int!
    $siteLogo: String
    $siteLogoS3Token: String
    $status: String!
    $primaryContactAddress: String
    $primaryContactZipCode: String
    $primaryContactCity: String
    $primaryContactCountry: String
    $primaryContactState: String
    $primaryContactSuite: String
    $primaryContactEmail: String
    $primaryContactFirstName: String
    $primaryContactLastName: String
    $email: String
  ) {
    viewPractice(
      id: $id
      name: $name
      notes: $notes
      practiceId: $practiceId
      siteLogo: $siteLogo
      siteLogoS3Token: $siteLogoS3Token
      status: $status
      email: $email
      primaryContactAddress: $primaryContactAddress
      primaryContactZipCode: $primaryContactZipCode
      primaryContactCity: $primaryContactCity
      primaryContactCountry: $primaryContactCountry
      primaryContactState: $primaryContactState
      primaryContactSuite: $primaryContactSuite
      primaryContactEmail: $primaryContactEmail
      primaryContactFirstName: $primaryContactFirstName
      primaryContactLastName: $primaryContactLastName
      portalId: $portalId
    ) {
      practice {
        name
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getPracticeQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  }),
  graphql(viewPracticeMutation, { name: "viewPractice" })
)(PracticesView);
