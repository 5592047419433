import React, { useRef, useState, useContext, useEffect, useCallback } from "react";
import { Layout } from "antd";
import { Modal, OverlayTrigger, Popover, Form, FormControl } from "react-bootstrap";
import { Logo, UserMenu } from "../../components";
import infoIcon from "../../assets/img/info-blue.svg";
import desktopLogo from "../../assets/img/unitifi-logo.png";
import HeaderBarWrapper from "./index.style";
import Search from "../../assets/img/header-search-icon.svg";
import InvitePractice from "../inviteForm/invitePractice";
import InviteAssessment from "../inviteForm/inviteAssessment";
import InviteClient from "../inviteForm/inviteClient";
import InviteCompanyMember from "../inviteForm/inviteCompanyMember";
import InviteAdvisor from "../inviteForm/inviteAdvisor";
import { Select } from "antd";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import axios from "axios";
import { apiUrl } from "../../config";
import { SelectMainWrapper, SelectWrapper } from "../../containers/users/signup/select.style";
import { AccountContext } from "../../context/AccountContext";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { validStringExp } from "../../helpers/formatters";
const { Header } = Layout;
const { Option } = Select;

const HeaderBar = (props) => {
  const {
    history,
    currentPractice,
    currentPortalId,
    user,
    data: { me },
  } = props;

  //const [isPracticeInviteModal, setPracticeInviteModal] = useState(false);
  const { isPracticeInviteModal, setPracticeInviteModal, setAdvisorModal, isAdvisorModal, logoUpdate, setOwnerPractice, setLogoUpdate, setInviteModal, isInviteModal, setAssessmentChange } = useContext(AccountContext);
  const [isAssessmentModal, setAssessmentModal] = useState(false);
  const [isClientModal, setClientModal] = useState(false);
  const [isCompanyModal, setCompanyModal] = useState(false);
  //const [isAdvisorModal, setAdvisorModal] = useState(false);
  const [totalAssessment, setTotalAssessment] = useState(null);
  const [searchText, setSearchText] = useState("");
  //const [searchPage, setSearchPage] = useState("");
  const assessmentRef = useRef();
  const searchRef = useRef();
  const [menuModal, setMenuModal] = useState(false);
  const [select, setSelect] = useState();
  const [exceedalert, setExceedalert] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const { loading: logoLoading, data: logoData, refetch: portalRefetch } = useQuery(getPortalDetailsQuery, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { id: currentPortalId },
  });
  const { loading: logoDetailLoading, data: logoDetail, refetch: logoRefetch } = useQuery(getLogoDetailsQuery, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { portalId: currentPortalId, practiceId: me.practiceid, roleCode: me.rolecode },
  });

  // useEffect(() => {
  //   if (logoUpdate) {
  //     portalRefetch();
  //     logoRefetch();
  //   }
  // }, [logoUpdate]);

  useEffect(() => {
    if (!logoData) return;
    if (logoData.getPortal) {
      setOwnerPractice({ ownerPracticeName: logoData.getPortal.ownerPracticeName, ownerPracticeId: logoData.getPortal.ownerPracticeId, ownerUserId: logoData.getPortal.id });
    }
  }, [logoData]);

  useEffect(() => {
    if (!logoDetail) return;
    if (!logoDetail.getLogoByRole) {
      setLogoUpdate(desktopLogo);
      return;
    }

    let logo = logoDetail.getLogoByRole.result;
    logo = logo.siteLogo ? logo.siteLogo : desktopLogo;
    setLogoUpdate(logo);
  }, [logoDetail]);

  function onChange(value) {
    setSelect(value);
    if (!currentPractice && !currentPortalId) {
      setMenuModal(true);
      return;
    }
    if (value == "practice") setPracticeInviteModal(true);
    if (value == "assessment") setAssessmentModal(true);
    if (value == "client") setClientModal(true);
    if (value == "advisor") setAdvisorModal(true);
    if (value == "company") setCompanyModal(true);
    setSelect(undefined);
  }
  const quickLinkHandler = (e) => {
    // console.log("e", e.target.value);
    let val = e.target.value;
    if (val == "practice") setPracticeInviteModal(true);
    if (val == "assessment") setAssessmentModal(true);
    if (val == "client") setClientModal(true);
    if (val == "advisor") setAdvisorModal(true);
    if (val == "company") setCompanyModal(true);
  };
  const assessmentHandler = () => {
    setAssessmentModal(false);
    setTotalAssessment(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      search();
    }
  };

  const searchHandler = () => {
    search();
  };
  const asyncsearchHandler = (e) => {
    const selectedOption = e.value; // <--- you can get value from object directly
    setSelectedOption(selectedOption);
    if (props.location.pathname.includes("/clients/view/") || props.location.pathname.includes("/clients/view-compare/")) {
      history.push(`/${e.practiceId}/clients/view/${e.value}`);
      return;
    }

    history.push(`${props.location.pathname}?search=${e.label}`);
  };

  const searchClearandler = () => {
    setSearchText("");
  };
  const search = () => {
    if (!currentPractice && !currentPortalId) {
      setMenuModal(true);
      return;
    }
    const isValid = validStringExp(searchText);
    if (isValid.status) return;
    // if (!searchText) return;
    if (props.location.pathname.includes("/practices")) {
      history.push(`/practices?search=${searchText}`);
    } else if (props.location.pathname.includes("/advisors")) {
      history.push(`/advisors?search=${searchText}`);
    } else if (props.location.pathname.includes("/clients")) {
      history.push(`${props.location.pathname}?search=${searchText}`);
    } else {
      if (!searchText) return;
      history.push(`/search?search=${searchText}`);
    }
  };

  let searchPlaceHolder = "Search for Practice, Advisor, or Client";
  if (props.location.pathname.includes("/practices")) {
    //setSearchPage("practices");
    searchPlaceHolder = "Search for Practice";
  } else if (props.location.pathname.includes("/advisors")) {
    //setSearchPage("advisors");
    searchPlaceHolder = "Search for Advisor";
  } else if (props.location.pathname.includes("/clients")) {
    //setSearchPage("clients");
    searchPlaceHolder = "Search for Client";
  }
  const clientHandler = useCallback(
    debounce((inputText, callback) => {
      clientApiHandler(inputText).then((options) => callback(options));
    }, 1000),
    [props.location]
  );

  const clientApiHandler = async (input) => {
    let result = "";
    if (input.trim().length <= 1) return;
    if (props.location.pathname.includes("/clients")) {
      result = await axios.get(`${apiUrl}/search-client-header/${input}/${currentPortalId}/${me.practiceid}/${me.rolecode}/${me.id}/${me.accountType}`);
    } else if (props.location.pathname.includes("/advisors")) {
      result = await axios.get(`${apiUrl}/search-advisor/${input}/${currentPortalId}/${me.rolecode}/${me.practiceid}/${me.accountType}`);
    } else if (props.location.pathname.includes("/practices")) {
      result = await axios.get(`${apiUrl}/search-practice/${input}/${currentPortalId}/${me.rolecode}/${me.practiceid}`);
    }
    result = result.data;
    if (result.length == 0) return;

    return result.map((data) => ({
      label: data.displayName,
      value: data.id,
      practiceId: data.practiceId ? data.practiceId : "",
    }));
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Team Discounts</Popover.Header>
      <Popover.Body>
        <ul>
          <li>
            <span>25%</span> off for <span>11-50</span> team members
          </li>
          <li>
            <span>30%</span> off for <span>51-100</span> team members
          </li>
          <li>
            <span>40%</span> off for <span>101-250</span> team members
          </li>
          <li>
            <span>60%</span> off for <span>250+</span> team members
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const Control = ({ children, ...props }) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <SelectMainWrapper>{children}</SelectMainWrapper>
      </components.Option>
    );
  };
  return (
    <HeaderBarWrapper>
      <Header>
        <div className="unitifi-header-logo">{logoDetailLoading == false && <Logo practiceId={props.currentPractice} currentPortalId={props.currentPortalId} logoData={logoData} logoDetail={logoDetail} />}</div>
        <div className="user-menu-item">
          <div className="search-and-select-wrapper">
            {props.location.pathname.includes("/clients") || props.location.pathname.includes("/advisors") || props.location.pathname.includes("/practices") ? (
              <>
                <AsyncSelect
                  components={{ Option: Control }}
                  onKeyDown={(e) => setSearchText(e.target.value)}
                  placeholder={searchPlaceHolder}
                  ref={searchRef}
                  loadOptions={clientHandler}
                  className="unitifi-select-container unitifi-searchfor-client-input-wrapper"
                  classNamePrefix="unitifi-react-select"
                  value={selectedOption}
                  onChange={(e) => asyncsearchHandler(e)}

                  //isDisabled={queryParam.practice_id ? true : false}
                />
                <div className="searchAction" onClick={() => searchHandler()}>
                  <img src={Search} />
                </div>
              </>
            ) : (
              !props.location.pathname.includes("learning-center") &&
              !props.location.pathname.includes("personality-behavior") && (
                <Form className="">
                  <div className="search-input-wrapper input-group">
                    <i onClick={() => searchHandler()}>
                      <img src={Search} />
                    </i>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`clear-icon  ${searchText == "" ? "d-none" : ""}`} width="17" height="17" viewBox="0 0 384 512" fill="none" onClick={() => searchClearandler()}>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z"
                        fill="#ffffff"
                      />
                    </svg>
                    <Form.Control type="search" placeholder={searchPlaceHolder} ref={searchRef} aria-label="Search" onBlur={() => (searchRef.current.value = "")} onChange={(e) => setSearchText(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
                    <div class="input-group-append" onClick={() => searchHandler()}>
                      <span class="input-group-text"></span>
                    </div>
                  </div>
                </Form>
              )
            )}

            {/*<Form.Select aria-label="Default select example" onChange={(e) => quickLinkHandler(e)}>
              <option value="" disabled selected hidden>Quick Links</option>
              <option value="assessment">Send Assessment</option>
              {user && user.accountType == 1 && (
                <>
                  <option value="practice">Invite Practice Member</option>
                  <option value="advisor">Invite Advisor</option>{" "}
                </>
              )}
              <option value="client">Invite Client</option>
              </Form.Select>*/}
            <Select style={{ width: 300 }} placeholder="Quick Links" onChange={onChange} value={select}>
              <Option value="assessment" key={3}>
                Send Assessment Reminder
              </Option>
              {me && me.accountType == 1 && me.isPortalAdmin && (
                <Option value="company" key={5}>
                  Invite Company Member(s)
                </Option>
              )}
              {me && ((me.accountType == 1 && me.rolecode != "ADVSO") || (me.accountType == 2 && (me.isPracticeAdmin || me.isPortalAdmin))) && (
                <Option value="practice" key={1}>
                  Invite Practice Member(s)
                </Option>
              )}
              {me && (me.accountType == 1 || (me.accountType == 2 && (me.isPracticeAdmin || me.isPortalAdmin || me.isAdvisorAdmin))) && (
                <Option value="advisor" key={2}>
                  Invite Advisor(s)
                </Option>
              )}
              <Option value="client" key={4}>
                Send Assessment
              </Option>
            </Select>
          </div>
          <UserMenu currentPractice={props.currentPractice} {...props} />
        </div>
      </Header>
      <Modal
        show={isPracticeInviteModal}
        onExited={() => {
          setPracticeInviteModal(false);
          setSelect(undefined);
          setExceedalert(false);
          setInviteModal(null);
        }}
        onHide={() => {
          setPracticeInviteModal(false);
          setSelect(undefined);
          setExceedalert(false);
          setInviteModal(null);
        }}
        centered
        className="unitifi-modal-wrapper notifyBox">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Invite Practice Member(s)
            {exceedalert && (
              <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                <i className="ms-2">
                  <img src={infoIcon} />
                </i>
              </OverlayTrigger>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvitePractice
            setPracticeInviteModal={setPracticeInviteModal}
            isInviteModal={isInviteModal}
            setInviteModal={setInviteModal}
            exceedalert={exceedalert}
            setExceedalert={setExceedalert}
            currentPortalId={props.currentPortalId}
            gblUserId={props.gblUserId}
            {...props}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={isAssessmentModal}
        onHide={() => {
          assessmentHandler();
          setSelect(undefined);
        }}
        centered
        size="lg"
        className="unitifi-modal-wrapper modalAssessment">
        <Modal.Header className="p-0">
          {/* <Modal.Title id="example-modal-sizes-title-sm"> Assessment Reminder</Modal.Title> */}
          {totalAssessment && (
            <p className="selectAssessment mb-0" onClick={() => assessmentRef.current.selectAll({ value: 0, label: `Send assessment to all selected (${totalAssessment})` })}>
              Select All({totalAssessment})
            </p>
          )}
        </Modal.Header>
        {/* <Modal.Body> */}
        <InviteAssessment ref={assessmentRef} setAssessmentModal={setAssessmentModal} setTotalAssessment={setTotalAssessment} currentPortalId={props.currentPortalId} gblUserId={props.gblUserId} {...props} me={me} />
        {/* </Modal.Body> */}
      </Modal>

      <Modal
        show={isClientModal}
        onHide={() => {
          setClientModal(false);
          setSelect(undefined);
        }}
        centered
        className="unitifi-modal-wrapper client-modal">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Send Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteClient setClientModal={setClientModal} currentPortalId={props.currentPortalId} gblUserId={props.gblUserId} setAssessmentChange={setAssessmentChange} {...props} me={me} />
        </Modal.Body>
      </Modal>

      <Modal
        show={isCompanyModal}
        onExited={() => {
          setCompanyModal(false);
          setSelect(undefined);
          setExceedalert(false);
        }}
        onHide={() => {
          setCompanyModal(false);
          setSelect(undefined);
          setExceedalert(false);
        }}
        centered
        className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Invite Company Member(s)
            {exceedalert && (
              <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                <i className="ms-2">
                  <img src={infoIcon} />
                </i>
              </OverlayTrigger>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteCompanyMember setCompanyModal={setCompanyModal} exceedalert={exceedalert} setExceedalert={setExceedalert} currentPortalId={props.currentPortalId} gblUserId={props.gblUserId} {...props} me={me} />
        </Modal.Body>
      </Modal>

      <Modal
        show={isAdvisorModal}
        onExited={() => {
          setAdvisorModal(false);
          setSelect(undefined);
          setExceedalert(false);
          setInviteModal(null);
        }}
        onHide={() => {
          setAdvisorModal(false);
          setSelect(undefined);
          setExceedalert(false);
          setInviteModal(null);
        }}
        centered
        className="unitifi-modal-wrapper notifyBox">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Invite Advisor(s)
            {exceedalert && (
              <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                <i className="ms-2">
                  <img src={infoIcon} />
                </i>
              </OverlayTrigger>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteAdvisor
            isInviteModal={isInviteModal}
            setInviteModal={setInviteModal}
            setAdvisorModal={setAdvisorModal}
            exceedalert={exceedalert}
            setExceedalert={setExceedalert}
            currentPortalId={props.currentPortalId}
            me={me}
            gblUserId={props.gblUserId}
            {...props}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={menuModal}
        onHide={() => {
          setMenuModal(false);
          setSelect(undefined);
        }}
        centered
        className="unitifi-modal-wrapper request-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <h4>Thank you for signing up!</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please be patient while you wait for your organization to accept your request. Feel free to checkout the tools you will receive by joining the Unitifi platform!</p>
          <div className="modal-btn-wrapper">
            <button className="exploreBtn" type="primary" onClick={() => window.open("https://unitifi.com", "_blank")}>
              Explore Our Tools
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </HeaderBarWrapper>
  );
};
export default HeaderBar;

const getPortalDetailsQuery = gql`
  query($id: Int) {
    getPortal(id: $id) {
      id
      name
      siteLogo
      ownerPracticeName
      ownerPracticeId
    }
  }
`;
const getLogoDetailsQuery = gql`
  query($roleCode: String, $practiceId: Int, $portalId: Int) {
    getLogoByRole(roleCode: $roleCode, practiceId: $practiceId, portalId: $portalId) {
      result {
        siteLogo
        siteLogoS3Token
      }
    }
  }
`;
