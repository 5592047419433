import moment from "moment";
import Energizer from "../assets/img/Energizer.svg";
import Liberator from "../assets/img/Liberator.svg";
import Observer from "../assets/img/Observer.svg";
import Protector from "../assets/img/Protector.svg";

/* takes an object array like [{id, name}] and returns sorted alphabetically by name */
/* assits in building human friendly select dropdowns */
export const BuildOptions = (arr) => {
  /* remove empties & nulls */
  const optionsArr = arr.filter((a) => a && a.id !== null);

  const sorted = optionsArr.reduce((acc, cur) => {
    if (!acc.some((obj) => obj.id === cur.id)) {
      acc.push(cur);
    }
    return acc.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }, []);

  return sorted;
};

/* returns formatted phone number */
export const FormatPhoneNumber = (phoneNumber) => {
  /* remove everything but digits */
  const split = ("" + phoneNumber).replace(/\D/g, "");
  /* group the digits into buckets of 3, 3, 4 */
  const group = split.match(/^(\d{3})(\d{3})(\d{4})$/);
  /* return the buckets like 111-222-3333 */
  return !group ? null : group[1] + "-" + group[2] + "-" + group[3];
};

/* returns only digits */
export const FormatClickToCallPhoneNumber = (phoneNumber) => ("" + phoneNumber).replace(/\D/g, "");

/* date/time formatters */
export const FormatDate = (momentObj) => moment(momentObj, "ddd MMM DD YYYY HH:mm:ss").format("M/D/YYYY");
export const FormatTime = (momentObj) => moment(momentObj, "ddd MMM DD YYYY HH:mm:ss").format("h:mm a");
export const FormatDateTime = (momentObj) => moment(momentObj, "ddd MMM DD YYYY HH:mm:ss").format("M/D/YYYY h:mm a");

/* combine seperate date & time into single moment object */
export const DateTimeCombine = (momentObj1, momentObj2) =>
  moment(momentObj1.format("MM/DD/YYYY") + " " + momentObj2.format("HH:mm"), "MM/DD/YYYY HH:mm")
    .utc()
    .format();

/* get the current time rounded to the nearest 15 minute interval */
export const GetCurrentTimeToFifteen = moment()
  .minute(Math.round(moment().minute() / 15) * 15)
  .second(0);

/* get 12:00 AM */
export const GetTwelveAM = moment("00:00:00", "HH:mm:ss");

/* DatePicker & TimePicker formats */
export const FormatDateForDatePicker = (momentObj) => moment(momentObj, "ddd MMM DD YYYY");
export const FormatTimeForTimePicker = (momentObj) => moment(momentObj, "ddd MMM DD YYYY HH:mm:ss");

export const FormatPercent = (number) => (number * 100).toFixed(2) + "%";

export const UpperCasePassCheck = RegExp("^(?=.*[A-Z].*).+$");
export const LowerCasePassCheck = RegExp("^(?=.*[a-z].*).+$");
export const SpacialCharPassCheck = RegExp("^(?=.*[@$!%*#?&].*).+$");
export const NumberPassCheck = RegExp("^(?=.*[0-9].*).+$");

export const urlPathName = () => {
  const route = window.location.pathname.split("/");
  return route;
};

export const truncateText = (input, textLength = 50, show = false) => (input.trim().length > textLength ? (show ? input : `${input.trim().substring(0, textLength)}...`) : input);
export const checkTruncateText = (input) => input.trim().length > 50;

export const phoneNumberCheck = (number) => !/(?!(.)\1{2}\1{3}\1{4})\d{3}\d{3}\d{4}/.test(number);
export const phoneMsg = "Invalid phone number.";

export const convertCurrency = (amount) => {
  let formattedFraction = Number(amount).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return formattedFraction.endsWith(".00") ? formattedFraction.slice(0, -3) : formattedFraction;
};

export const validStringErr = "Ivalid input string";
// export const validStringExp = /^[0-9a-zA-Z\_@.\+\'\-\!]+$/;

export const validStringExp = (string) => {
  let regexPattern = /^[0-9a-zA-Z\_@.\+\'\-\s\!'",]+$/;
  let validString = regexPattern.test(string);
  if (validString) return { status: false, error: "InvalidString" };
  else return { status: true, error: "InvalidString" };
};
export const personalityTypeImg = {
  Energizer,
  Liberator,
  Observer,
  Protector,
};
