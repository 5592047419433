import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, message, Slider, InputNumber, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import logo from "../../../assets/img/unitifi.svg";
import PublicContent from "../../../layouts/public";
import { FormWrapper } from "../../../components/form";
import { Link } from "react-router-dom";
// images
import whitelogo from "../../../assets/img/unitifi-white.png";
import infoicon from "../../../assets/img/info-blue.svg";
import { Tab, Tabs, OverlayTrigger, Popover, Modal } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { graphql, useQuery, useLazyQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import CollectPaymentWrapper from "./index.style";
import PaymentMethod from "../../../components/paymentMethod";
import moment from "moment";
import ContactForm from "../../../components/contactForm";
import { AccountContext } from "../../../context/AccountContext";
import { convertCurrency } from "../../../helpers/formatters";

const FormItem = Form.Item;
const { Title, Text, Paragraph } = Typography;
const { Header, Content } = Layout;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Collect_payment = (props) => {
  const { form, mutate, history, user } = props;
  const { account, setAccount, setProfile, setUserPermission, setLogoUpdate } = useContext(AccountContext);

  const { getFieldProps, getFieldDecorator } = form;
  const [showPaymentForm, setPaymentForm] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openModelTeam, setOpenModelTeam] = useState(false);
  const [openContactModel, setOpenContactModel] = useState(false);
  const [sliderPrice, setSliderPrice] = useState(1);
  const [sliderPriceBox, setSliderPriceBox] = useState(11);
  const [sliderPriceTeam, setSliderPriceTeam] = useState(1);
  const [sliderPriceTeamBox, setSliderPriceTeamBox] = useState(1);
  const [planDetail, setPlanData] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [sliderTotalAmount, setSliderTotalAmount] = useState(null);
  const [sliderTotal, setSliderTotal] = useState(null);
  const [teamErrorMsg, setTeamErrorMsg] = useState(null);
  const [teamErrorMsgBig, setTeamErrorMsgBig] = useState(null);
  // const [estimateError, setEstimateError] = useState(false);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Team Discounts</Popover.Header>
      <Popover.Body>
        <ul>
          <li>
            <span>25%</span> off for <span>11-50</span> team members
          </li>
          <li>
            <span>30%</span> off for <span>51-100</span> team members
          </li>
          <li>
            <span>40%</span> off for <span>101-250</span> team members
          </li>
          <li>
            <span>60%</span> off for <span>250+</span> team members
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const [planDetalHandler, { loading: planLoading, error: planError, data: planData }] = useLazyQuery(
    gql`
      query($plan_type: String!, $payment_mode: String!) {
        getStripeplan(plan_type: $plan_type, payment_mode: $payment_mode) {
          stripeplan {
            id
            plan_name
            plan_type
            payment_mode
            plan_price
            min_member
            max_member
            description
            price
          }
        }
      }
    `,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    planDetalHandler({
      variables: { plan_type: "2", payment_mode: process.env.REACT_APP_PAYMENT_MODE },
    });
  }, []);

  useEffect(() => {
    if (!planData) return;
    let planDatas = planData.getStripeplan.stripeplan[0];
    planDatas.totalAmount = parseInt(planDatas.price) * parseInt(planDatas.min_member) || 0;

    let dt = moment()
      .add(1, planDatas.plan_type == "2" ? "Y" : "M")
      .format("MM/DD/YYYY");

    planDatas.nextPaymentDate = dt;
    planDatas.teamSize = planDatas.min_member;
    setPlanData(planDatas);
  }, [planData]);
  // const paymentFormHandler = (plan) => {
  //   setPaymentForm(true);
  //   setSelectedPlan(plan);
  //   setSliderTotalAmount(null);
  //   setSliderPriceTeam(1);
  // };

  const paymentFormSliderHandler = () => {
    if (!sliderPriceTeam) {
      setPaymentForm(false);
      return;
    }
    setPaymentForm(true);
    setOpenModelTeam(false);
    let planDataDetail = planDetail;
    planDataDetail.teamSize = sliderPriceTeam;
    planDataDetail.totalAmount = parseInt(planDataDetail.price) * parseInt(sliderPriceTeam);
    if (planDataDetail && !planDataDetail.discountAmount) {
      planDataDetail.discountAmount = null;
      planDataDetail.discountPer = null;
      planDataDetail.totalPayAmount = null;
    } else {
      if (planDetail && planDetail.discountPer) {
        let discountAmount = (planDataDetail.totalAmount * planDetail.discountPer) / 100;
        planDataDetail.discountAmount = discountAmount;
        planDataDetail.totalPayAmount = planDataDetail.totalAmount - discountAmount;
      } else if (planDetail && planDetail.discountAmount) {
        if (planDataDetail.totalAmount - planDetail.discountAmount < 0) {
          planDataDetail.discountAmount = null;
          planDataDetail.discountPer = null;
          planDataDetail.totalPayAmount = null;
        } else {
          let amount = planDataDetail.totalAmount - planDetail.discountAmount;
          planDataDetail.totalPayAmount = amount;
        }
      }
    }
    setSelectedPlan(planDataDetail);
  };

  const submitBigSliderHandler = () => {
    if (!sliderPrice) {
      setPaymentForm(false);
      return;
    }

    setPaymentForm(true);
    setOpenModel(false);
    let planList = planData.getStripeplan.stripeplan;
    let planDataDetail = null;
    for (let plan of planList) {
      if (parseInt(plan.min_member) <= sliderPrice && parseInt(plan.max_member) >= sliderPrice) {
        planDataDetail = plan;
        break;
      }
    }
    let dt = moment()
      .add(1, planDataDetail.plan_type == "2" ? "Y" : "M")
      .format("MM/DD/YYYY");

    planDataDetail.nextPaymentDate = dt;
    planDataDetail.teamSize = sliderPrice;
    planDataDetail.totalAmount = parseInt(planDataDetail.price) * parseInt(sliderPrice);
    if (planDetail && !planDetail.discountAmount) {
      planDataDetail.discountAmount = null;
      planDataDetail.discountPer = null;
      planDataDetail.totalPayAmount = null;
    } else {
      if (planDetail && planDetail.discountPer) {
        let discountAmount = (planDataDetail.totalAmount * planDetail.discountPer) / 100;
        planDataDetail.discountAmount = discountAmount;
        planDataDetail.totalPayAmount = planDataDetail.totalAmount - discountAmount;
        planDataDetail.discountPer = planDataDetail.discountPer;
      } else if (planDetail && planDetail.discountAmount) {
        if (planDataDetail.totalAmount - planDetail.discountAmount < 0) {
          planDataDetail.discountAmount = null;
          planDataDetail.discountPer = null;
          planDataDetail.totalPayAmount = null;
        } else {
          let amount = planDataDetail.totalAmount - planDetail.discountAmount;
          planDataDetail.totalPayAmount = amount;
          planDataDetail.discountAmount = planDetail.discountAmount;
        }
      }
    }

    setSelectedPlan(planDataDetail);
  };

  const sliderHandler = (newValue) => {
    setSliderPriceTeam(newValue);
    setSliderPriceTeamBox(newValue);
    let planDataDetail = planDetail;
    let totalAmount = parseInt(planDataDetail.price) * parseInt(newValue);
    setSliderTotal(totalAmount);
  };

  const sliderHandlerBox = (newValue) => {
    if (typeof newValue == "object") {
      setSliderPriceTeamBox("");
      setTeamErrorMsg("Enter Team Size");
      setSliderPriceTeam(null);
      return;
    } else if (typeof newValue == "string" && newValue.length == 0) {
      setSliderPriceTeamBox("");
      setTeamErrorMsg("Enter Team Size");
      setSliderPriceTeam(null);
      return;
    } else if (Number(newValue) <= 0) {
      setSliderPriceTeamBox(newValue);
      setTeamErrorMsg("Invalid Team Size");
      setSliderPriceTeam(null);
      return;
    } else if (!Number.isInteger(newValue)) {
      setSliderPriceTeamBox(newValue);
      setTeamErrorMsg("Invalid Team Size");
      setSliderPriceTeam(null);
      return;
    } else if (Number(newValue) > 10) {
      setSliderPriceTeamBox(newValue);
      setTeamErrorMsg("Invalid Team Size");
      setSliderPriceTeam(null);
      return;
    } else {
      setTeamErrorMsg(null);
      sliderHandler(newValue);
    }
  };

  const sliderBigHandlerBox = (newValue) => {
    if (typeof newValue == "object") {
      setSliderPriceBox("");
      setTeamErrorMsgBig("Enter Team Size");
      setSliderPrice(null);
      return;
    } else if (typeof newValue == "string" && newValue.length == 0) {
      setSliderPriceBox("");
      setTeamErrorMsgBig("Enter Team Size");
      setSliderPrice(null);
      return;
    } else if (Number(newValue) <= 10) {
      setSliderPriceBox(newValue);
      setTeamErrorMsgBig("Invalid Team Size");
      setSliderPrice(null);
      return;
    } else if (!Number.isInteger(newValue)) {
      setSliderPriceBox(newValue);
      setTeamErrorMsgBig("Invalid Team Size");
      setSliderPrice(null);
      return;
    } else if (Number(newValue) > 1000) {
      setSliderPriceBox(newValue);
      setTeamErrorMsgBig("Invalid Team Size");
      setSliderPrice(null);
      return;
    } else {
      setTeamErrorMsgBig(null);
      sliderBigHandler(newValue);
    }
  };

  const sliderBigHandler = (newValue) => {
    if (!newValue || !/^[0-9]*$/.test(newValue) || newValue > 1000 || newValue < 10) {
      // setEstimateError(true);
      return;
    }

    setSliderPrice(newValue);
    setSliderPriceBox(newValue);
    let planList = planData.getStripeplan.stripeplan;
    let planDataDetail = null;
    for (let plan of planList) {
      if (parseInt(plan.min_member) <= newValue && parseInt(plan.max_member) >= newValue) {
        planDataDetail = plan;
        break;
      }
    }

    let totalAmount = parseInt(planDataDetail.price) * parseInt(newValue);

    setSliderTotalAmount(totalAmount);
    // if (estimateError) setEstimateError(false);
  };

  const tabHandler = (e) => {
    setPaymentForm(false);
    setSelectedPlan(null);
    setSliderTotalAmount(null);
    setSliderTotal(null);
    setSliderPriceTeam(1);
    setSliderPriceTeamBox(1);
    setSliderPrice(11);
    setSliderPriceBox(11);
    planDetalHandler({
      variables: { plan_type: e == "annual" ? "2" : "1", payment_mode: process.env.REACT_APP_PAYMENT_MODE },
    });
  };

  const logoutHandler = async () => {
    let result = await deleteUser({ variables: { id: user.id } });

    if (result && result.data && result.data.accountDelete && result.data.accountDelete.ok) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("current_company");
      localStorage.removeItem("current_practice");
      localStorage.removeItem("current_practice_id");
      localStorage.removeItem("current_company_id");
      setAccount(null);
      setProfile(null);
      setUserPermission([]);
      setLogoUpdate(null);
      history.push("/login");
    }
    return true;
  };
  const [deleteUser, {}] = useMutation(gql`
    mutation deleteUser($id: Int!) {
      accountDelete(id: $id) {
        ok
        message
      }
    }
  `);
  const chooseAccountHandler = async () => {
    let result = await deleteUser({ variables: { id: user.id } });

    if (result && result.data && result.data.accountDelete && result.data.accountDelete.ok) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("current_company");
      localStorage.removeItem("current_practice");
      localStorage.removeItem("current_practice_id");
      localStorage.removeItem("current_company_id");
      setAccount(null);
      setProfile(null);
      setUserPermission([]);

      history.push("/signup_account_type");
    }
    return true;
  };

  const teamSizeModalHandler = () => {
    setOpenModelTeam(false);
    if (!sliderPriceTeam) setPaymentForm(false);
  };

  const teamBigHandler = () => {
    setOpenModel(false);
    if (!sliderPrice) setPaymentForm(false);
  };

  const clickSliderHandler = () => {
    sliderBigHandler(11);
    setTimeout(() => {
      setOpenModel(true);
    }, 200);
  };

  return (
    <CollectPaymentWrapper>
      <Modal show={openModel} onHide={() => teamBigHandler()} className="unitifi-modal-wrapper" centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="unitifi-modal-header-wrapper">
            <h2 className="unitifi-modal-title-text subtitle-text">Estimate Your Costs</h2>
            <p className="subtitle-pramary-text">
              ${sliderTotalAmount ? `${convertCurrency(sliderTotalAmount)} billed ${planDetail && planDetail.plan_type == "2" ? "annually" : "monthly"}` : `${planDetail && convertCurrency(planDetail.price)} per person`}
            </p>
          </div>
          <div className="slider-bar-wrapper">
            <Slider marks={{ 11: "11", 1000: "1000" }} min={11} max={1000} onChange={(val) => sliderBigHandler(val)} value={typeof sliderPrice === "number" ? sliderPrice : 11} />
            <InputNumber
              style={{
                margin: "0 16px",
              }}
              value={sliderPriceBox}
              onChange={sliderBigHandlerBox}
            />
            {teamErrorMsgBig && <div className="text-center text-danger mt-2">{teamErrorMsgBig}</div>}
          </div>
          {/* {estimateError && <div className="text-center text-danger mt-0">Please select Team size</div>} */}
          <div className="modal-footer-bnts-wrapper justify-content-center">
            <Button onClick={() => submitBigSliderHandler()} className="unitifi-login-btn proceed-checkout-btn" type="primary">
              Proceed to checkout
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={openModelTeam} onHide={() => teamSizeModalHandler()} className="unitifi-modal-wrapper" centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="unitifi-modal-header-wrapper">
            <h2 className="unitifi-modal-title-text subtitle-text">Select Your Team Size</h2>
            <p className="subtitle-pramary-text subtitle-info">
              {sliderTotal ? `$${convertCurrency(sliderTotal)} billed ${planDetail.plan_type == "2" ? "annually" : "monthly"}` : `Not sure how big your team will be? No worries! Start with one and we’ll scale as you grow.`}
            </p>
          </div>
          <div className="slider-bar-wrapper">
            <Slider marks={{ 1: "1", 10: "10" }} min={1} max={10} onChange={(val) => sliderHandler(val)} value={typeof sliderPriceTeam === "number" ? sliderPriceTeam : 1} />
            <InputNumber
              style={{
                margin: "0 16px",
              }}
              value={sliderPriceTeamBox}
              onChange={sliderHandlerBox}
            />
            {teamErrorMsg && <div className="text-center text-danger mt-2">{teamErrorMsg}</div>}
          </div>
          <div className="modal-footer-bnts-wrapper justify-content-center">
            <Button onClick={() => paymentFormSliderHandler()} className="proceed-checkout-btn" type="primary">
              Proceed to checkout
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <ContactForm setOpenContactModel={setOpenContactModel} openContactModel={openContactModel} planData={planDetail} user={user} />
      <div className={`unitifi-collect-payment-wrapper ${showPaymentForm ? "" : "payment-start-today"}`}>
        <div className="unitifi-annual-monthly-wrapper">
          <div className="unitifi-logo-wrapper">
            <Link to="/" className="unitifi-logo">
              <img src={whitelogo} />
            </Link>
            <h6>FinTech Uniting the FinServ Industry & Clients</h6>
          </div>
          <div className="unitifi-annual-monthly-tab-wrapper">
            <div className="unitifi-max-width-box">
              <Tabs defaultActiveKey="annual" id="uncontrolled-tab-example" className="" onSelect={(e) => tabHandler(e)}>
                <Tab eventKey="annual" title="Annual">
                  <div className="annual-monthly-tab-section">
                    <div className="annual-header-bar">
                      <h5>Starts at ${planData && planData.getStripeplan.stripeplan[0].plan_name}</h5>
                      <p>Up to {planData && planData.getStripeplan.stripeplan[0].max_member} team members</p>
                      {planData && (
                        <Button block type="primary" size="large" onClick={() => setOpenModelTeam(true)} className="unitifi-login-btn">
                          Start Today
                        </Button>
                      )}
                    </div>
                    <div className="annual-monthly-details">
                      <span className="or-text">OR</span>
                      <h4>
                        For teams larger than 10, contact us for discounts
                        <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover}>
                          <Button variant="success">
                            <img src={infoicon} />
                          </Button>
                        </OverlayTrigger>
                      </h4>
                      <div className="contact-us-estimate-block">
                        <Button block type="primary" size="large" onClick={() => setOpenContactModel(true)} className="unitifi-login-btn">
                          Contact Us For Bulk Pricing
                        </Button>
                        <h4>
                          <a onClick={() => clickSliderHandler()} className="unitifi-link-text">
                            Click Here
                          </a>{" "}
                          to estimate pricing
                        </h4>
                      </div>
                      <div className="checkmark-wrapper">
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Know your client completely</span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Unlimited Assessments, Results, and Downloads </span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Increase AUM and client retention rates</span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Save up to 30% in E&O Premiums</span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button block size="large" className="returnBtn" onClick={() => logoutHandler()}>
                          Return to Sign In
                        </Button>
                        <Button block size="large" className="accountType ms-3" onClick={() => chooseAccountHandler()}>
                          Return to Account Type
                        </Button>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="monthly" title="Monthly">
                  <div className="annual-monthly-tab-section">
                    <div className="annual-header-bar">
                      <h5>Starts at ${planData && planData.getStripeplan.stripeplan[0].plan_name}</h5>
                      <p>Up to {planData && planData.getStripeplan.stripeplan[0].max_member} team members</p>
                      {planData && (
                        <Button block type="primary" size="large" onClick={() => setOpenModelTeam(true)} className="unitifi-login-btn">
                          Start Today
                        </Button>
                      )}
                    </div>
                    <div className="annual-monthly-details">
                      <span className="or-text">OR</span>
                      <h4>
                        For teams larger than {planData && planData.getStripeplan.stripeplan[0].max_member}, contact us for discounts
                        <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover}>
                          <Button variant="success">
                            <img src={infoicon} />
                          </Button>
                        </OverlayTrigger>
                      </h4>
                      <div className="contact-us-estimate-block">
                        <Button block type="primary" size="large" onClick={() => setOpenContactModel(true)} className="unitifi-login-btn">
                          Contact Us For Bulk Pricing
                        </Button>
                        <h4>
                          <a onClick={() => clickSliderHandler()} className="unitifi-link-text">
                            Click Here
                          </a>{" "}
                          to estimate pricing
                        </h4>
                      </div>
                      <div className="checkmark-wrapper">
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Know your client completely</span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Unlimited Assessments, Results, and Downloads </span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Increase AUM and client retention rates</span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="checkbox-cmn-label">
                          <span className="checkbox-cmn-title">Save up to 30% in E&O Premiums</span>
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="d-flex">
                        <Button block size="large" className="returnBtn" onClick={() => logoutHandler()}>
                          Return to Sign In
                        </Button>
                        <Button block size="large" className="accountType ms-3" onClick={() => chooseAccountHandler()}>
                          Return to Account Type
                        </Button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="unitifi-payment-method-wrapper">
          <div className="unitifi-payment-method-content">
            {showPaymentForm && (
              <Elements stripe={stripePromise}>
                <PaymentMethod user={user} planData={selectedPlan} history={history} setSelectedPlan={setSelectedPlan} />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </CollectPaymentWrapper>
  );
};
export default Form.create()(Collect_payment);
