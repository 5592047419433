import React from "react";
import { Redirect } from "react-router-dom";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import moment from "moment";
import gql from "graphql-tag";
import { BackTop, Button, Collapse, Dropdown, Icon, Menu, notification, Spin, Col, Row, Typography, PageHeader } from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import { apiUrlExport, assessmentUrl } from "../../../config";
import { PassionLogicMap, SelfReportedMap, Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import ClientViewWrapper from "./index.style";
import CustomButton from "../../../components/commons/CustomButton";
import { Modal } from "react-bootstrap";
import iconPersonality from "../../../assets/img/icon-personality.png";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";
import CompareClient from "../list/compareClient";
import { personalityTypeImg } from "../../../helpers/formatters";
import pencilIcon from "../../../assets/img/icon-pencil.svg";
import emailIcon from "../../../assets/img/icon-envelope.svg";
import phoneIcon from "../../../assets/img/icon-telephone.svg";

const { Panel } = Collapse;
const { Title } = Typography;

const customPanelStyle = {
  border: 0,
  borderRadius: 4,
  overflow: "hidden",
};

const customHeaderStyle = {
  color: "rgba(0,0,0,.65)",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "20px",
};

const GenerateReportType = (type) => {
  if (type === "snapshot") {
    return "Snapshot";
  }

  if (type === "401k") {
    return "Retirement";
  }

  if (type == "ucita") {
    return "Advisor-UCIT";
  }
  return "UCIT";
};

class ClientView extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false,
      loading: false,
      pdfLoading: false,
      behaviorModal: false,
      behaviorModalData: null,
      isAssessmentModal: false,
    };
    this.assessmentRef = React.createRef();
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.downloadAssessment = this.downloadAssessment.bind(this);
    this.sendClientAssessment = this.sendClientAssessment.bind(this);
  }

  copyToClipboard = (e, assUrl) => {
    navigator.clipboard.writeText(assUrl);
    this.setState({ copySuccess: true });
  };

  downloadAssessment(type) {
    this.setState({ pdfLoading: true });

    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const {
      currentPractice,
      me,
      data: {
        viewClient: { id, firstName, lastName, assessmentId, printDateCollected },
      },
    } = this.props;

    // console.log("download url: ", `${apiUrlExport}/${id}&${currentPractice}&${type}&${token}&${refreshToken}&${assessmentId}`);

    axios
      .get(`${apiUrlExport}/${id}&${me.practiceid}&${type}&token&refreshToken&${assessmentId}`, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf", "x-token": token, "x-refresh-token": refreshToken },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        this.setState({ pdfLoading: false });

        saveAs(blob, `${firstName}-${lastName}-${GenerateReportType(type)}-${printDateCollected}`);
      });
  }

  sendClientAssessment(assUrl) {
    this.setState({ loading: true });

    const { id, advisorId, practiceId } = this.props.data.viewClient;

    const variables = {
      assessmentUrl: assUrl,
      advisorId,
      clientId: id,
      practiceId,
    };

    this.props
      .sendClientAssessment({ variables })
      .then(({ data }) => {
        if (data.sendClientAssessment.ok && !data.sendClientAssessment.errors) {
          this.handleSuccess(`The assesment has been successfully sent to ${this.props.data.viewClient.email}.`);
          return;
        }

        this.handleError(data.sendClientAssessment.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description) {
    this.setState({ loading: false });

    notification.success({
      message: "Assessment Sent Successfully",
      //description,
    });

    this.props.data.refetch();
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  behaviorModalHandler(data) {
    this.setState({ behaviorModal: true, behaviorModalData: data });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  assessmentHandler = () => {
    this.setState({ isAssessmentModal: !this.state.isAssessmentModal });
  };

  render() {
    const {
      me,
      data: { loading, error, viewClient = null },
    } = this.props;

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - clients didn&apos;t load.</p>
        </div>
      );
    }

    if (!viewClient) {
      return <Redirect to="/" />;
    }

    const { assessmentToken, dateSent, email, firstName, insights, allInsights, assessmentId, lastName, practiceName, advisorname, phonenumber, id, assessmentLink } = viewClient;
    const userContext = this.context;
    const { account } = userContext;

    if (!insights) {
      const newClient = this.props.location.state && this.props.location.state.newClient;

      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <TitleBar title={`${firstName} ${lastName}`} subTitle={account && me && (me.rolecode == "CMPYO" || me.rolecode == "PRCTO" ? `${account.portalname} | ${practiceName} | ${advisorname}` : `${account.portalname}`)} />
            {/* <Titlebar currentPractice={this.props.currentPractice} displayText={`${firstName} ${lastName}`} viewClient /> */}
          </div>
          <LayoutContentWrapper>
            <LayoutContent>
              <div style={{ marginBottom: "24px" }}>
                <p>{newClient ? `${firstName.trim()}'s assessment is ready.` : `${firstName.trim()} has not completed the assessment.`}</p>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <p style={{ marginBottom: "8px" }}>
                  Send {firstName} an email with the assessment link to {email}.
                </p>
                <Button type="primary" loading={this.state.loading} onClick={() => this.sendClientAssessment(`${assessmentUrl}/${assessmentToken}`)}>
                  {dateSent ? "Resend" : "Send"} Assessment Link
                </Button>
                {dateSent && (
                  <p style={{ fontSize: "12px", marginTop: "8px" }}>
                    <i>
                      *Last sent {moment(dateSent).format("MM/DD/YYYY")} at {moment(dateSent).format("h:mm a")}
                    </i>
                  </p>
                )}
              </div>
              {/* only show copy to clipboard if broswe supports */}
              {document.queryCommandSupported("copy") && (
                <div>
                  <p style={{ marginBottom: "8px" }}>Copy the link for {firstName}&#39;s assessment to share.</p>
                  {/* <Button type="primary" disabled={this.state.copySuccess} onClick={(e) => this.copyToClipboard(e, `${assessmentUrl}/${assessmentToken}`)}> */}
                  <Button type="primary" disabled={this.state.copySuccess} onClick={(e) => this.copyToClipboard(e, `${assessmentLink}`)}>
                    {this.state.copySuccess ? "Copied To Clipboard" : "Copy Assessment Link"}
                  </Button>
                </div>
              )}
            </LayoutContent>
          </LayoutContentWrapper>
        </div>
      );
    }

    const dropdownMenuItems = allInsights.map((insight) => {
      return (
        <Menu.Item key={`'${insight.id}'`}>
          <a rel="noreferrer" href={`/${this.props.currentPractice}/clients/view/${viewClient.id}/${insight.id}`}>
            {moment
              .utc(insight.createdAt)
              .local()
              .format("MM-DD-YYYY")}
            {/*&nbsp;
            {insight.id === assessmentId ? <Icon type="check-circle" /> : null}*/}
          </a>
        </Menu.Item>
      );
    });
    const dropdownMenu = <Menu defaultSelectedKeys={[`'${assessmentId}'`]}>{[...dropdownMenuItems]}</Menu>;
    let headerExtras = [
      <Button className="mb-1 me-2" type="primary" loading={this.state.loading} onClick={() => this.sendClientAssessment(`${assessmentUrl}/${assessmentToken}`)}>
        Send New Assessment Link
      </Button>,
      // <Button type="primary" className="mb-1 me-2" disabled={this.state.copySuccess} onClick={(e) => this.copyToClipboard(e, `${assessmentUrl}/${assessmentToken}`)}>
      <Button type="primary" className="mb-1 me-2" disabled={this.state.copySuccess} onClick={(e) => this.copyToClipboard(e, `${assessmentLink}`)}>
        {this.state.copySuccess ? "Copied To Clipboard" : "Copy Assessment Link"}
      </Button>,
    ];

    headerExtras = [
      ...headerExtras,
      <Dropdown
        overlayClassName="dropdown-download"
        overlay={
          <Menu onClick={(e) => this.downloadAssessment(e.key)}>
            <Menu.Item disabled>For Advisor</Menu.Item>
            <Menu.Item key="ucita">UCIT</Menu.Item>
            <Menu.Divider />
            <Menu.Item disabled>For Client</Menu.Item>
            <Menu.Item key="ucitc">UCIT</Menu.Item>
            <Menu.Item key="snapshot">Results Snapshot</Menu.Item>
            <Menu.Item key="401k">Retirement Snapshot</Menu.Item>
          </Menu>
        }
        trigger={["click"]}>
        <Button type="primary" icon="download" className="me-2" loading={this.state.pdfLoading} size="large">
          Download
        </Button>
      </Dropdown>,
    ];
    //if (allInsights.length > 1) {
    headerExtras = [
      ...headerExtras,
      <Dropdown overlay={dropdownMenu}>
        <Button type="primary" className="ant-dropdown-link me-2 mt-1 mt-sm-0" onClick={(e) => e.preventDefault()}>
          Assessment History <Icon type="down" />
        </Button>
      </Dropdown>,
    ];
    //}
    headerExtras = [
      ...headerExtras,
      <Button className="btnCompare mt-1" onClick={() => this.setState({ isAssessmentModal: true })}>
        Compare Client
      </Button>,
    ];

    let nameTitle = [
      <TitleBar title={`${firstName} ${lastName}`} subTitle={account && me && (me.rolecode == "CMPYO" || me.rolecode == "PRCTO" ? `${account.portalname} | ${practiceName} | ${advisorname}` : `${account.portalname}`)} />,

      <TitleBar title={`${email}`} subTitle={`${phonenumber}`} />,
    ];
    return (
      <div className="unirifi-costomer-layout-wrapper">
        <BackTop />
        <div className="unitifi-subheader-bar-wrapper ">{/* <Titlebar currentPractice={this.props.currentPractice} displayText={`${firstName} ${lastName}`} viewClient /> */}</div>
        <div className="unitifi-header mb-3">
          <Title>UCIT</Title>
          <p>Unitifi Consumer Insight Tool</p>
        </div>

        {/* <PageHeader ghost={false} subTitle={nameTitle} class={{ paddingTop: 0 }} extra={headerExtras} className="unitifi-client-subheader-bar"></PageHeader> */}

        <div className="header-client-menu-wrapper">
          <div className="header-client-left-content-data">
            <div>
              <h4 className="client-title">
                {`${firstName} ${lastName}`}
                <a href={`/${this.props.currentPractice}/clients/edit/${id}`}>
                  <img src={pencilIcon} className="ms-2 mb-1" />
                </a>
              </h4>
              <span className="client-detail">{account && me && (me.rolecode == "CMPYO" || me.rolecode == "PRCTO" ? `${account.portalname} | ${practiceName} | ${advisorname}` : `${account.portalname}`)}</span>
            </div>
            <div className="clientEmailData">
              <h6 class="cmn-title-text">
                <a href={`mailto:${email}`}>
                  <img src={emailIcon} className="me-2" />
                  {email}
                </a>
              </h6>

              <span className="cmn-title-text">
                <a href={`${phonenumber ? `tel:${phonenumber}` : "#"}`}>
                  <img src={phoneIcon} className="me-2" />
                  {phonenumber ? phonenumber : "N/A"}
                </a>
              </span>
            </div>
          </div>
          <div className="header-client-right-btn-data">{headerExtras}</div>
        </div>

        <div className="unitifi-consumer-header-bar p-0">
          <div>
            <div className="button-bar-wrapper has-dropdown download"></div>
          </div>
        </div>

        <ClientViewWrapper>
          <div className="main-chart-wrapper">
            {/* left bar / 3 divs */}
            <div className="left-col">
              <div className="item">
                <div className="heading">Behavior Defined</div>
                <div className="details-wrapper">
                  <div className="score-item-wrapper">
                    <div className="score-item">
                      <div className="risk-behavior-score">{insights.score.riskBehaviorScore}</div>
                      <div className="score-label">Science Reported</div>
                    </div>
                    <div className="slash">/</div>
                    <div className="score-item">
                      <div className="self-reported-score">{insights.score.selfReportedScore}</div>
                      <div className="score-label">Self-Reported</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="heading">Financial Personality</div>
                <div className="details-wrapper" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                  {/* <div className="label">{insights.personality.behavior}</div> */}
                  <div className="callout">
                    {insights.personality.type}
                    <i className="ms-1">
                      <img src={personalityTypeImg[insights.personality.type]} />
                    </i>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="heading">Decision Making Style</div>
                <div className="details-wrapper">
                  <PassionLogicMap data={insights} firstName={firstName} />
                </div>
              </div>
            </div>

            {/* chart */}
            <div className="right-col">
              <div className="chart-legend">
                <div style={{ alignItems: "center", display: "flex", marginRight: "24px" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(0,111,186,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Science Reported</div>
                </div>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(255,255,255,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Self-Reported</div>
                </div>
              </div>

              <div className="chart-wrapper">
                <SelfReportedMap data={insights} />
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="wrapper">
              <div className="heading">Communication Center</div>
              <div className="item-wrapper-flex">
                <div className="item-flex">
                  <div className="item-flex-heading">Expectation</div>
                  <div>{insights.profile.communicationExpectation}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Age</div>
                  <div>{new Date().getFullYear() - insights.retirement.birthYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Year</div>
                  <div>{insights.retirement.retirementYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Vehicle</div>
                  <div>{insights.profile.currentRetirementVehicle}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="wrapper">
              <div className="heading">Financial Personality Defined: {insights.personality.type}</div>

              <div className="item-wrapper">
                <div className="item has-below">
                  <div className="item-heading">Behaviors of this Investor</div>
                  <div>
                    <ul>
                      {insights.personality.attributes.investorBehaviors.map((i) => (
                        <li key={i}>{i}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="item">
                  <div className="item-heading">Tips for the Financial Professional</div>
                  <div>
                    <ul>
                      {insights.personality.attributes.advisorTips.map((i) => (
                        <li key={i}>{i}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div>
              <div className="heading">Behavior Attributes Recognized</div>

              <div className="item-wrapper">
                <div className="attribute-text">
                  The following tiles identify behavior attributes recognized for {firstName}. <div className="circle"></div> indicates a strongly identified attribute, while <div className="circle min"></div> indicates an identified attribute.
                </div>
                <div className="unitifi-behavior-attributes-section">
                  <Row>
                    <Col lg={{ span: 16 }} md={{ span: 24 }}>
                      <Row gutter={27}>
                        {insights.tendencies &&
                          insights.tendencies.length > 0 &&
                          insights.tendencies.map((tendency) => (
                            <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }} key={tendency.id}>
                              <div className={`unitifi-behavior-attributes-box ${tendency.type == "Strongly Identified" ? "" : "blue-box"}`} onClick={() => this.behaviorModalHandler(tendency)}>
                                <div className="icons-and-details">
                                  <i>
                                    {" "}
                                    <img src={tendency.white_icon} />{" "}
                                  </i>
                                  <p>{tendency.name}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                        <Modal show={this.state.behaviorModal} onHide={() => this.setState({ behaviorModal: false, behaviorModalData: null })} centered className="unitifi-modal-wrapper" size="lg">
                          {this.state.behaviorModalData && (
                            <>
                              <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-sm">{this.state.behaviorModalData.name}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="unitifi-behavior-recognized-modal d-flex justify-content-between">
                                  <div className="unitifi-behavior-recognized-detail">
                                    {this.state.behaviorModalData.characteristics.length > 0 && (
                                      <>
                                        <h5>Characteristics</h5>
                                        <ul>
                                          {this.state.behaviorModalData.characteristics.map((c) => (
                                            <li key={c}>{c}</li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                    {this.state.behaviorModalData.results.length > 0 && (
                                      <>
                                        <h5 className="mt-4">Result</h5>
                                        <ul>
                                          {this.state.behaviorModalData.results.map((res) => (
                                            <li key={res}>{res}</li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                    {this.state.behaviorModalData.recommendations.length > 0 && (
                                      <>
                                        <h5 className="mt-4">Tips for the Financial Professional</h5>
                                        <ul>
                                          {this.state.behaviorModalData.recommendations.map((res) => (
                                            <li key={res}>{res}</li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <div className="unitifi-behavior-recognized-icon">
                                      <img src={this.state.behaviorModalData.blue_icon} />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                            </>
                          )}
                        </Modal>
                        {/* <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box">
                            <div className="icons-and-details">
                              <p>Receptive</p>
                              <i>
                                {" "}
                                <img src={Receptive} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box">
                            <div className="icons-and-details">
                              <p>Inspective</p>
                              <i>
                                {" "}
                                <img src={Inspective} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box">
                            <div className="icons-and-details">
                              <p>Kinship</p>
                              <i>
                                {" "}
                                <img src={Kinship} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box">
                            <div className="icons-and-details">
                              <p>Composed</p>
                              <i>
                                {" "}
                                <img src={Composed} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box">
                            <div className="icons-and-details">
                              <p>Loss Avoidance</p>
                              <i>
                                {" "}
                                <img src={LossAvoidance} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box blue-box">
                            <div className="icons-and-details">
                              <p>Prudent</p>
                              <i>
                                {" "}
                                <img src={Prudent} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box blue-box">
                            <div className="icons-and-details">
                              <p>Affixed</p>
                              <i>
                                {" "}
                                <img src={Affixed} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                          <Link to="/" className="unitifi-behavior-attributes-box blue-box">
                            <div className="icons-and-details">
                              <p>Consistency</p>
                              <i>
                                {" "}
                                <img src={Consistency} />{" "}
                              </i>
                            </div>
                          </Link>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* <Collapse bordered={false}>
                  {insights.tendencies.map((t) => (
                    <Panel
                      header={
                        <div style={{ alignItems: "center", display: "flex", paddingBottom: "4px" }}>
                          <div
                            style={
                              t.type === "Strongly Identified"
                                ? {
                                    background: "rgba(0,111,186,1)",
                                    borderRadius: "100%",
                                    height: "16px",
                                    marginRight: "8px",
                                    width: "16px",
                                  }
                                : {
                                    background: "#6fb6e0",
                                    borderRadius: "100%",
                                    height: "16px",
                                    marginRight: "8px",
                                    width: "16px",
                                  }
                            }></div>
                          <div style={customHeaderStyle}>{t.name}</div>
                        </div>
                      }
                      key={t.name}
                      style={customPanelStyle}>
                      <div style={{ marginLeft: "28px" }}>
                        <div style={{ marginBottom: "12px" }}>
                          <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Characteristics</div>
                          <ul>
                            {t.characteristics.map((c) => (
                              <li key={c}>{c}</li>
                            ))}
                          </ul>
                        </div>

                        <div style={{ marginBottom: "24px" }}>
                          <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Results</div>
                          <ul>
                            {t.results.map((c) => (
                              <li key={c}>{c}</li>
                            ))}
                          </ul>
                        </div>

                        <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Recommendations</div>
                        <ul>
                          {t.recommendations.map((c) => (
                            <li key={c}>{c}</li>
                          ))}
                        </ul>
                      </div>
                    </Panel>
                  ))}
                </Collapse> */}
              </div>
            </div>
          </div>
        </ClientViewWrapper>
        <Modal
          show={this.state.isAssessmentModal}
          onHide={() => {
            this.assessmentHandler();
          }}
          size="lg"
          centered
          className="unitifi-modal-wrapper modalAssessment">
          <Modal.Header className="p-0">
            {/* <Modal.Title id="example-modal-sizes-title-sm"> Assessment Reminder</Modal.Title> */}
            {this.state.totalAssessment && (
              <p className="selectAssessment mb-0" onClick={() => this.assessmentRef.current.selectAll({ value: 0, label: `Send assessment to all selected (${this.state.totalAssessment})` })}>
                Select All({this.state.totalAssessment})
              </p>
            )}
          </Modal.Header>
          {/* <Modal.Body> */}
          <CompareClient ref={this.assessmentRef} currentPortalId={this.props.currentPortalId} gblUserId={this.props.gblUserId} {...this.props} me={this.props.me} assessmentHandler={this.assessmentHandler} id={this.props.match.params.id} />
          {/* </Modal.Body> */}
        </Modal>
      </div>
    );
  }
}

const viewClientQuery = gql`
  query($id: Int!, $practiceId: Int, $assessmentId: Int, $portalId: Int, $roleCode: String, $accountType: Int) {
    viewClient(id: $id, practiceId: $practiceId, assessmentId: $assessmentId, portalId: $portalId, roleCode: $roleCode, accountType: $accountType) {
      id
      address
      advisorId
      assessmentToken
      advisorname
      practiceName
      city
      dateSent
      email
      firstName
      insights
      assessmentId
      allInsights
      lastName
      phonenumber
      practiceId
      state
      submittedByClient
      viewedByAdvisor
      zipCode
      printDateCollected
      assessmentLink
    }
  }
`;

const sendClientAssessmentMutation = gql`
  mutation sendClientAssessment($advisorId: Int!, $assessmentUrl: String!, $clientId: Int!, $practiceId: Int!) {
    sendClientAssessment(advisorId: $advisorId, assessmentUrl: $assessmentUrl, clientId: $clientId, practiceId: $practiceId) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(viewClientQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
        practiceId: props.me.practiceid,
        assessmentId: Number(props.match.params.assessmentId),
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
        portalId: props.currentPortalId,
      },
    }),
  }),
  graphql(sendClientAssessmentMutation, { name: "sendClientAssessment" })
)(ClientView);
