import styled from "styled-components";
import { palette } from "styled-theme";

const FormWrapper = styled.div`
  background: ${palette("base", 0)};
  max-width: 950px;
  width: 100%;
  &.unitifi-my-account-layout-wrapper {
    max-width: 100%;
    .row-wrapper {
      @media only screen and (max-width: 767px) {
        flex-direction: unset;
      }
      .row-label {
        @media only screen and (max-width: 767px) {
          width: auto;
          margin-right: 25px;
        }
      }
      .row-text-value{
        color:#969696;
      } 
    }
    .text-grey {
      opacity: 0.5;
    }
    .edit-btns-box {
      width: 30px;
      flex: 1;
      justify-content: flex-end;
      align-items: flex-end;
      display: flex;
    }
  }

  .ant-form-item-label {
    label {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .ant-form-item-control-wrapper {
    .ant-form-item-control {
      span {
        a {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          color: #006fba;
        }
      }
    }
  }

  .ant-input {
    background: #f1f1f1;
    border-color: #f1f1f1;
  }
  .ant-select-search {
    .ant-input {
      background: #f1f1f1 !important;
      border-color: #f1f1f1;
    }
  }
  .ant-select-selection {
    background: #f1f1f1;
    border-color: #f1f1f1;
  }
  .ant-calendar-picker-input {
    display: flex;
  }

  .ant-calendar-range-picker-input {
    width: 65px;
  }

  .ant-calendar-range-picker-separator {
    padding-left: 3px;
    padding-top: 1px;
    width: 12px;
  }

  .caption,
  .caption-error {
    &.ant-upload-list-caption {
      margin-top: 8px;
    }
  }

  .uni-buttons-row {
    display: flex;

    .uni-buttons {
      display: flex;

      &.uni-draggable-table-list {
        margin-bottom: 32px;
        margin-top: 8px;
      }
    }

    .ant-form-item {
      flex: 0 0 100%;
      margin-bottom: 0;

      .ant-form-item-children {
        display: flex;
        flex-direction: column;

        button {
          &:first-of-type {
            margin-right: 8px;
            width: 75%;
          }
          &:last-of-type {
            margin-left: 8px;
            width: 25%;
          }
        }
      }
    }
  }

  .uni-form-pin-input {
    align-items: flex-end;
    display: flex;

    input {
      margin-right: 8px;
      width: 40px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .form-section-heading {
    margin-bottom: 24px;

    &.divider {
      background: ${palette("base", 4)};
      border-bottom: solid 1px ${palette("border", 0)};
      padding: 16px;
    }
  }

  .input-phone__wrapper {
    .input-phone__input {
      display: flex;

      input {
        width: 52px;

        &:last-child {
          width: 65px;
        }
      }

      span {
        line-height: 32px;
        margin: 0 8px;
      }
    }
  }

  .uni-matrix-question {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;

    .ant-form-item-label {
      flex-basis: 40%;
      margin-bottom: 0;
      margin-right: 32px;
      padding-bottom: 0;
      white-space: normal;
    }

    .ant-form-item-control-wrapper {
      flex-basis: 60%;
    }

    .uni-matrix-total {
      color: ${palette("error", 0)};
      font-size: 12px;
      margin-top: 4px;
    }
  }

  .uni-switch {
    align-items: center;
    display: flex;

    &.single {
      padding: 12px 0;
    }

    &.switch-form {
      padding-bottom: 24px;
    }

    .uni-switch-label {
      color: ${palette("base", 1)};
      margin-right: 8px;

      &.after {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }

  textarea {
    &.uni-terms-conditions {
      margin-top: 8px;
    }
  }

  .uni-form-item__color-picker {
    padding-bottom: 0;

    &.last {
      margin-bottom: 0;
    }

    .rc-color-picker-trigger {
      margin-bottom: 8px;
    }
  }

  .uni-inputs-row {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;

    .ant-form-item {
      flex: 1 auto;
      margin-bottom: 0;

      &:first-child {
        margin-right: 24px;
      }

      &:last-child {
        margin-left: 24px;
      }
    }
  }

  .uni-place-selected {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .uni-with-map {
    margin-top: 24px;
  }

  .uni-time-inputs-row {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 75%;

    .ant-form-item {
      flex: 1 auto;
      margin-bottom: 0;

      &:first-child {
        margin-right: 24px;
      }

      &:last-child {
        margin-left: 24px;
      }

      .ant-time-picker {
        width: 100%;
      }
    }
  }

  .input-small {
    width: 75%;

    .ant-calendar-picker,
    .ant-time-picker {
      width: 100%;
    }
  }

  .input-xsmall {
    width: 50%;

    .ant-calendar-picker,
    .ant-time-picker {
      width: 100%;
    }
  }

  .input-xxsmall {
    width: 25%;

    .ant-calendar-picker,
    .ant-time-picker {
      width: 100%;
    }
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;

    &.error {
      .ant-form-item-label {
        label {
          color: ${palette("error", 0)};
        }
      }
    }

    &.warning {
      .ant-form-item-label {
        label {
          color: ${palette("warning", 0)};
        }
      }
    }
  }

  .uni-input-address {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ant-form-vertical {
    .uni-input-checkbox {
      margin-bottom: 0;
    }
    .form-toolbar {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      > div {
        .ant-btn {
          width: 100px;
          font-weight: 600;
        }
      }
    }
    .ant-form-item-control {
      .ant-form-item-children {
        .webLabel-wrapper {
          i {
            img {
              margin-bottom: 4px;
            }
          }
        }
      }
    }

    .ant-form-item {
      .ant-form-item-control-wrapper {
        .ant-form-item-control {
          .ant-form-item-children {
            input {
              &.inputZip {
                min-height: 36px !important;
                height: 36px !important;
              }
            }
            .unitifi-select-container {
              &.cmnBoxWrapper {
                .unitifi-react-select__control {
                  min-height: 36px !important;
                  height: 36px !important;
                  padding: 0 10px;
                  .unitifi-react-select__value-container{
                    font-size:16px !important;
                    .unitifi-react-select__placeholder{
                      font-size:16px !important;
                      color:rgba(0,0,0,0.65) !important;
                      opacity:0.4;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .uni-input-bottom {
    margin-bottom: 0;
  }

  .uni-address-display {
    display: flex;
    margin-bottom: 24px;
    width: 100%;
  }

  .uni-address-box {
    border: solid 1px ${palette("border", 1)};
    border-top: none;
    flex-grow: 1;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    &:first-of-type {
      border-bottom-left-radius: 4px;
      border-right: none;
    }

    &:last-of-type {
      border-bottom-right-radius: 4px;
    }

    .uni-google-logo {
      height: 16px;
      width: 16px;
      margin: 7px 0 4px 0;
    }

    p {
      margin: 4px 0;
    }
  }

  .uni-permanently-closed {
    font-size: 16px;
    margin: 8px 0px 8px 0px;
    color: ${palette("error", 0)};
  }

  label.ant-form-item-required {
    &:before {
      display: none;
    }
  }

  .ant-form-item:not(.uni-tooltip-label) {
    .ant-form-item-label {
      label.ant-form-item-required {
        &:after {
          color: ${palette("error", 0)};
          content: "*";
          display: inline-block;
          font-family: SimSun;
          font-size: 12px;
          line-height: 1;
          margin-left: 4px;
          top: 2px;
          vertical-align: super;
        }
      }
    }
  }

  .ant-form-item {
    &.uni-tooltip-label {
      .ant-form-item-label {
        label.ant-form-item-required {
          i {
            color: ${palette("base", 5)};
          }

          span.uni-tooltip-label__text {
            &:after {
              color: ${palette("error", 0)};
              content: "*";
              display: inline-block;
              font-family: SimSun;
              font-size: 12px;
              line-height: 1;
              margin-left: 4px;
              margin-right: 4px;
              top: 2px;
              vertical-align: super;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .uni-input-bottom {
      margin-bottom: 8px;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;

    .uni-buttons-row {
      .uni-buttons {
        flex-direction: column;
      }

      .ant-form-item {
        .ant-form-item-children {
          flex-direction: column;

          button {
            &:first-of-type {
              margin-bottom: 16px;
              margin-right: 0;
              width: 100%;
            }
            &:last-of-type {
              margin-left: 0;
              width: 100%;
            }
          }
        }
      }
    }

    .uni-matrix-question {
      display: block;
      width: 100%;
      /* background: #fafafa; */

      .ant-form-item-label {
        padding-bottom: 8px;
      }
    }

    .uni-inputs-row {
      flex-direction: column;

      .ant-form-item {
        width: 100%;

        &:first-child {
          margin-bottom: 24px;
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }

    .uni-time-inputs-row {
      flex-direction: column;
      width: 100%;

      .ant-form-item {
        width: 100%;

        &:first-child {
          margin-bottom: 24px;
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }

    .uni-input-small {
      width: 100%;
    }
  }

  .form-title {
    margin-bottom: 0 !important;
  }

  .stripe-card-item > .ant-list-item-meta-content > .ant-list-item-meta-title {
    font-weight: 600;
  }

  .ant-modal-title {
    font-weight: 600 !important;
  }

  .unitifi-detail-pages-number-inputbox-wrapper {
    display: flex !important;
    align-items: center;
    width: 100%;

    &.countryCode {
      .unitifi-select-container {
        .unitifi-react-select__control {
          margin-right: 7px !important;
          width: 83px;
          min-height: 36px;
          height: 36px;
          padding: 0 10px;
          .unitifi-react-select__value-container{
            font-size:16px !important;
          }
          .unitifi-react-select__indicator {
            padding-right: 0 !important;
          }
        }
      }
    }
    .unitifi-select-container {
      margin-right: 7px !important;
      width: 80px;
    }
    .input-phone__input {
      flex: 1 !important;
      input {
        width: 30.32% !important;
        margin: 0 7px;
        height: 36px;
        &.phoneInput {
          @media only screen and (max-width: 767px) {
            width: 50% !important;
            margin: 0 3px;
          }
        }
      }
    }
    &.UnitifiCustomerNumberInputbox{
      background: #f1f1f1;
      border-radius: 4px;
      .unitifi-select-container  {
        border-radius: 0;
        background: transparent;
        margin-right: 0px !important;
        min-width: 80px;
        width: auto !important;
        .unitifi-react-select__control {
          border-radius: 0;
          background: transparent !important;
          padding-right: 0 !important;
          width: unset !important;
          margin-right: 0 !important;
        }
      }
      .input-phone__input{
        input{
          background: transparent;
          border: none !important;
          border-radius: 0 !important;
          box-shadow: none !important;
          outline: none !important;
          width: 100% !important;
          margin: 0;
          &:focus {
            border: none !important;
          }
        }
      }
    }
  }
  .update-bill {
    .has-error .ant-input {
      border-color: #f1f1f1 !important;
    }
  }
`;

export default FormWrapper;
