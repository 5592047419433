import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Input, Radio, Spin, Modal, notification } from "antd";
import Select from "react-select";
import { FormWrapper } from "../form";
import { useApolloClient, useQuery } from "react-apollo";
import gql from "graphql-tag";
import { phoneMsg, phoneNumberCheck } from "../../helpers/formatters";
const FormItem = Form.Item;
const InputGroup = Input.Group;

const changePhoneNumber = (props) => {
  const { form, setChangePhoneShow, currentLogin, refetch } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;
  const [Countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState(currentLogin.phone_code);

  const { loading: countryLoading, error: countryError, data: countryData } = useQuery(
    gql`
      query {
        getCountry {
          countries {
            id
            name
            dial_code
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!countryData) return;
    let result = countryData.getCountry.countries;

    let countArr = [];
    for (let res of result) {
      if (res.name != "Canada") {
        let obj = {
          label: res.dial_code,
          id: res.dial_code,
        };
        countArr.push(obj);
      }
    }
    setCountries(countArr);
  }, [countryData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (values.p1 == undefined || values.p1.length != 10) {
        form.setFields({
          phoneNumber: {
            value: values.phoneNumber,
            errors: [new Error("Please enter valid phone number.")],
          },
        });
        return;
      }
      // if (values.p1.length !== 3 || values.p2.length !== 3 || values.p3.length !== 4) {
      //   form.setFields({
      //     phoneNumber: {
      //       value: values.phoneNumber,
      //       errors: [new Error("Please enter all digits of the phone number.")],
      //     },
      //   });
      //   return;
      // }
      let ph = values.p1;

      // if (phoneNumberCheck(ph)) {
      //   form.setFields({
      //     phoneNumber: {
      //       value: values.phoneNumber,
      //       errors: [new Error(phoneMsg)],
      //     },
      //   });
      //   return;
      // }

      if (!err) {
        setLoading(true);

        let variables = { phoneCode: countryCode, phoneNumber: ph, id: currentLogin.id };

        let result = await client.mutate({
          mutation: gql`
            mutation editUser($id: Int!, $phoneNumber: String, $phoneCode: String) {
              editUser(id: $id, phoneNumber: $phoneNumber, phone_code: $phoneCode) {
                ok
                errors {
                  message
                }
              }
            }
          `,
          variables: variables,
        });

        if (result && result.data.editUser.ok) {
          refetch();
          notification.success({ message: "Phone number update successfully" });
        } else notification.error({ message: "Phone number not update" });

        setChangePhoneShow(false);
        setLoading(false);
      }
    });
  };

  const compareEmail = (rule, value, callback) => {
    if (value && value == currentLogin.email) {
      callback("The email address you entered is already in use.");
    } else {
      callback();
    }
  };
  const phondeCodeHandler = (val) => {
    setCountryCode(val.id);
  };

  const autoFocusPhone = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");

    if (e.key == "Shift" || e.key == "Tab") {
      document.getElementById(c).focus();
      return;
    }
    if (length == maxLength) {
      if (n != "") {
        document.getElementById(n).focus();
      }
    }
  };

  return (
    <div className="public-form-wrapper">
      <FormWrapper className="public-form">
        <div className="unitifi-login-header-bar">
          <h5>Update Phone Number</h5>
        </div>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <FormItem className="uni-password-label" label="Current Number" labelCol={{ span: 12 }}>
            <p>{currentLogin && currentLogin.phoneNumber && (currentLogin.phone_code ? currentLogin.phone_code : "") + currentLogin.phoneNumber}</p>
          </FormItem>
          <FormItem className="input-phone__wrapper" label="Phone Number">
            <InputGroup compact className="unitifi-number-inputbox-wrapper">
              <Select
                options={Countries}
                defaultValue={currentLogin.phone_code && currentLogin.phone_code ? { label: currentLogin.phone_code, value: currentLogin.phone_code } : { label: "+1", value: "+1" }}
                // defaultValue={{ label: "+1", value: "+1" }}
                onKeyDown={(event) => {
                  if (
                    event.key == "Backspace" ||
                    event.key == "Delete" ||
                    event.key == "ArrowLeft" ||
                    event.key == "ArrowRight" ||
                    event.key == "Meta" ||
                    event.key == "ArrowUp" ||
                    event.key == "ArrowDown" ||
                    event.key == "Enter" ||
                    event.key == "Tab"
                  ) {
                  } else if (!/[0-9+]/.test(event.key)) event.preventDefault();
                }}
                onChange={(val) => phondeCodeHandler(val)}
                className="unitifi-select-container phoneInputBox"
                classNamePrefix="unitifi-react-select"
              />
              {getFieldDecorator("phoneNumber", {
                rules: [
                  {
                    required: true,
                    message: "Phone is required",
                  },
                ],
              })(
                <div className="input-phone__input">
                  {getFieldDecorator("p1", {
                    rules: [
                      {
                        required: true,
                        message: "Phone is required",
                      },
                    ],
                  })(
                    <Input
                      size={"large"}
                      maxLength={10}
                      placeholder=""
                      className="phoneInputbox"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      // onKeyUp={(event) => {
                      //   autoFocusPhone(event, "", "p1", "p2");
                      // }}
                    />
                  )}

                  {/* {getFieldDecorator("p2", {
                    rules: [
                      {
                        required: true,
                        message: "Phone is required",
                      },
                    ],
                  })(
                    <Input
                      size={"large"}
                      maxLength={3}
                      placeholder="000"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyUp={(event) => {
                        autoFocusPhone(event, "p1", "p2", "p3");
                      }}
                    />
                  )} */}

                  {/* {getFieldDecorator("p3", {
                    rules: [
                      {
                        required: true,
                        message: "Phone is required",
                      },
                    ],
                  })(
                    <Input
                      size={"large"}
                      maxLength={4}
                      placeholder="0000"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyUp={(event) => {
                        autoFocusPhone(event, "p2", "p3", "p3");
                      }}
                    />
                  )} */}
                </div>
              )}
            </InputGroup>
          </FormItem>
          <div className="modal-footer-bnts-wrapper">
            <Button type="primary" size="large" htmlType="submit" loading={loading} disabled={loading} className="unitifi-login-btn">
              Save
            </Button>
            <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setChangePhoneShow(false)}>
              Cancel
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </div>
  );
};

export default Form.create()(changePhoneNumber);
