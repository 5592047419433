import { Button, notification } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import Upload from "../../assets/img/icon-upload.svg";
import { useApolloClient } from "react-apollo";
import * as XLSX from "xlsx";
import TagsInput from "../TagsInput";

const InviteClient = (props) => {
  const { setClientModal, currentPortalId, gblUserId, me, setAssessmentChange } = props;
  const client = useApolloClient();
  const [tags, selectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const inviteHandler = async () => {
    if (!currentPortalId) {
      return;
    }
    if (tags.length == 0 && csvData.length == 0) {
      return;
    }
    let emailArr = [...tags, ...csvData];
    emailArr = [...new Set(emailArr)];

    setLoading(true);
    let variables = {
      toEmail: emailArr.join(","),
      portalId: currentPortalId,
      userId: gblUserId,
      practiceId: me.practiceid,
      advisorId: gblUserId,
    };
    let result = await client.mutate({
      mutation: gql`
        mutation($userId: Int!, $portalId: Int!, $toEmail: String, $advisorId: Int!, $practiceId: Int!) {
          inviteClient(userId: $userId, portalId: $portalId, toEmail: $toEmail, practiceId: $practiceId, advisorId: $advisorId) {
            ok
            message
            errors {
              path
              message
            }
          }
        }
      `,
      variables: variables,
    });
    setLoading(false);

    if (result.data.inviteClient.ok) {
      notification.success({ message: result.data.inviteClient.message });
      setAssessmentChange(result.data.inviteClient.message);
      setClientModal(false);
    } else notification.error({ message: result.data.inviteClient.errors[0].message });
  };

  const onFileChangeHandler = (e) => {
    var files = e.target.files,
      f = files[0];
    let fname = f.name;

    let validExt = ["xlsx", "xls"].includes(fname.split(".").reverse()[0]);
    if (!validExt) {
      // notification.error({ message: "" });
      document.getElementById("fileName").innerHTML = "";
      document.getElementById("csvFile").value = "";
      document.getElementById("errorFile").innerHTML = "Invalid File: Follow Excel Instructions";
      setCsvData([]);
      return;
    }
    setCsvLoading(true);
    document.getElementById("fileName").innerHTML = fname;
    document.getElementById("errorFile").innerHTML = "";
    var reader = new FileReader();
    reader.onload = function(e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const columnA = [];

      for (let z in ws) {
        if (z.toString()[0] === "A" && !isNaN(parseInt(z.toString()[1]))) {
          let regAx = /^([A-Z0-9._%+-])+@([A-Z0-9.-])+\.(?!con)([A-Z]{2,})$/i;
          if (regAx.test(ws[z].v)) columnA.push(ws[z].v);
        }
      }

      // const dataParse = XLSX.utils.sheet_to_json(columnA, { header: 1, defval: "", blankrows: false });
      const dataParse = columnA;
      // console.log("dataParse", dataParse);
      if (dataParse.length == 0) {
        document.getElementById("errorFile").innerHTML = "Invalid File: Follow Excel Instructions";
        document.getElementById("csvFile").value = "";
        document.getElementById("fileName").innerHTML = "";
        setCsvLoading(false);
        setCsvData([]);
        return;
      }
      // console.log("dataParse", dataParse);
      // let excelData = dataParse.flat(1);
      // let excelData = dataParse;
      // excelData = excelData.filter((data) => regAx.test(data));

      setCsvLoading(false);
      if (dataParse.length == 0) {
        setCsvData([]);
        document.getElementById("errorFile").innerHTML = "Invalid File: Follow Excel Instructions";
        document.getElementById("csvFile").value = "";
        document.getElementById("fileName").innerHTML = "";
        return;
      }

      setCsvData(dataParse);
    };
    reader.readAsBinaryString(f);
    return;
  };

  return (
    <div>
      <div className="unitifi-invite-practice-modal-wrapper unitify-invite-client-modal">
        <TagsInput selectedTags={selectedTags} tags={tags} placeholder="Input email" />
      </div>
      <div className="unitifi-upload-content-wrapper">
        <p>OR</p>
        <p className="unitifi-upload-text">
          Have a large number of invites to send?{" "}
          <a href="#" onClick={() => document.getElementById("csvFile").click()}>
            Click here
          </a>{" "}
          to upload your list.
        </p>
        <p className="unitifi-upload-text">File type must be Excel with 1 email address per line and only in Column A.</p>
        <p className="unitifi-upload-text text-center fw-bold">
          If the email you've entered matches an existing client's, we will send them a new assessment. If the email does not yet exist as a client, they will be receiving a new client invite and assessment.
        </p>
        <div className="d-flex align-items-center mb-5">
          {/*<i className="me-2">
            <img src={Upload} />
          </i>*/}
          <input type="file" accept=".xls,.xlsx" style={{ display: "none" }} id="csvFile" onChange={onFileChangeHandler} />
          <div className="unitifi-upload-link">
            {/*<a href="#" onClick={() => document.getElementById("csvFile").click()}>
              Upload File
            </a>*/}
            <span className="" id="fileName"></span>
            <span className="text-danger d-block" id="errorFile"></span>
          </div>
        </div>
      </div>
      <div className="modal-footer-bnts-wrapper">
        <Button type="primary" htmlType="button" className="unitifi-invite-btn" onClick={() => inviteHandler()} disabled={loading || csvLoading} loading={loading}>
          Invite
        </Button>
        <Button type="primary" htmlType="button" className="unitifi-invite-btn unitifi-cancel-btn" disabled={loading || csvLoading} onClick={() => setClientModal(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default InviteClient;
